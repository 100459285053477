/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as ContactTypes from '../contacts/types'
import * as RequestBodyTypes from '../request-bodies/types'
import * as RequestBodiesComplianceTypes from '../request-bodies-compliance/types'
import * as ResponseTypes from '../responses/types'
import * as ResponsesComplianceTypes from '../responses-compliance/types'
import * as ResponsesRoleTypes from '../responses-roles/types'
import * as SchemaTypes from '../schema/types'
import * as SchemasEventTypes from '../schemas-events/types'
import * as SchemasRoleTypes from '../schemas-roles/types'
import * as UtilityTypes from '../utilities/types'

export type PeachyKey = ['peachy', unknown]

export type PeachyResponse = ResponseTypes.Peachy

export type PeachyVariables = {
  body?: void
  query?: void
}

export const peachy = makeData<PeachyResponse, PeachyKey, PeachyVariables>({
  method: 'get',
  name: 'peachy',
  summary: 'Is it peachy?',
  path: '/peachy',
  queryKey: ({ query }) => ['peachy', query],
})

export type PeachyReadyKey = ['peachy-ready', unknown]

export type PeachyReadyResponse = ResponseTypes.PeachyReady

export type PeachyReadyVariables = {
  body?: void
  query?: void
}

export const peachyReady = makeData<PeachyReadyResponse, PeachyReadyKey, PeachyReadyVariables>({
  method: 'get',
  name: 'peachyReady',
  summary: 'Is it peachy?',
  path: '/peachy-ready',
  queryKey: ({ query }) => ['peachy-ready', query],
})

export type PitKey = ['peachy', 'pit', unknown]

export type PitResponse = ResponseTypes.Peachy

export type PitVariables = {
  body?: void
  query?: void
}

export const pit = makeData<PitResponse, PitKey, PitVariables>({
  method: 'get',
  name: 'pit',
  summary: 'Check the pit',
  path: '/peachy/pit',
  queryKey: ({ query }) => ['peachy', 'pit', query],
})

export type InternalGetEventsKey = ['companies', string | undefined, 'events', unknown]

export type InternalGetEventsResponse = ResponseTypes.Events

export type InternalGetEventsVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    offset?: number
    startingAfter?: string
    endingBefore?: string
    sortBy?: Array<string>
    personId?: SchemaTypes.AnyId
    periodId?: string
    loanId?: SchemaTypes.AnyId
    caseId?: SchemaTypes.AnyId
    transactionId?: SchemaTypes.AnyId
    apiKeyId?: string
    createdAtFrom?: string
    createdAtTo?: string
    effectiveAtFrom?: string
    effectiveAtTo?: string
    accrualDate?: string
    /**
     * @default
     *     true
     */
    includeDeleted?: boolean
    events?: string
    excludeEvents?: string
    webhookId?: string
    supercaseId?: string
    creatingMessageIds?: Array<string>
    omitCompanyId?: boolean
  }
}

export const internalGetEvents = makeData<InternalGetEventsResponse, InternalGetEventsKey, InternalGetEventsVariables>({
  method: 'get',
  name: 'internalGetEvents',
  summary: 'Retrieve events',
  path: '/companies/{companyId}/events',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'events', query],
})

export type InternalCreateEventKey = ['companies', string | undefined, 'events', unknown]

export type InternalCreateEventResponse = ResponseTypes.Event

export type InternalCreateEventVariables = {
  companyId: string | undefined
  body?: RequestBodyTypes.EventPost
  query?: {
    firingMode?: 'full' | 'database' | 'queue'
    priority?:
      | 'low'
      | 'normal'
      | 'high'
      | 'paymentProcessing'
      | 'createPurchaseSync'
      | 'datcon'
      | 'autopay'
      | 'loan'
      | 'DLM'
      | 'monitor'
      | 'scra'
      | 'loanSnapshot'
      | 'elasticsearch'
      | 'long'
  }
}

export const internalCreateEvent = makeData<
  InternalCreateEventResponse,
  InternalCreateEventKey,
  InternalCreateEventVariables
>({
  method: 'post',
  name: 'internalCreateEvent',
  summary: 'Create event',
  path: '/companies/{companyId}/events',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'events', query],
})

export type InternalCreateEventsBulkKey = ['companies', string | undefined, 'events', 'bulk', unknown]

export type InternalCreateEventsBulkResponse = ResponseTypes.Events

export type InternalCreateEventsBulkVariables = {
  companyId: string | undefined
  body?: Array<SchemasEventTypes.Event>
  query?: {
    firingMode?: 'full' | 'database' | 'queue'
    priority?:
      | 'low'
      | 'normal'
      | 'high'
      | 'paymentProcessing'
      | 'createPurchaseSync'
      | 'datcon'
      | 'autopay'
      | 'loan'
      | 'DLM'
      | 'monitor'
      | 'scra'
      | 'loanSnapshot'
      | 'elasticsearch'
      | 'long'
  }
}

export const internalCreateEventsBulk = makeData<
  InternalCreateEventsBulkResponse,
  InternalCreateEventsBulkKey,
  InternalCreateEventsBulkVariables
>({
  method: 'post',
  name: 'internalCreateEventsBulk',
  summary: 'Create many events',
  path: '/companies/{companyId}/events/bulk',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'events', 'bulk', query],
})

export type InternalUpdateEventKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'events',
  string | undefined,
  unknown,
]

export type InternalUpdateEventResponse = ResponseTypes.Events

export type InternalUpdateEventVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  eventId: string | undefined
  body?: {
    endedAt?: string
    deletedAt?: string
    transactionId?: string
    accruedInterestAmount?: number
    accruedDiscountAmount?: number
    accruedInterestAmountDecimal?: string
    accruedDiscountAmountDecimal?: string
    ledgerEntries?: {
      accountsReceivableDownpaymentDueDecimal?: string
      accountsReceivableDrawFeeDueDecimal?: string
      accountsReceivableInterestDueDecimal?: string
      accountsReceivableLateFeeDueDecimal?: string
      accountsReceivableModificationFeeDueDecimal?: string
      accountsReceivableOriginationFeeDueDecimal?: string
      accountsReceivablePrincipalDueDecimal?: string
      accountsReceivableDownpaymentOverdueDecimal?: string
      accountsReceivableDrawFeeOverdueDecimal?: string
      accountsReceivableInterestOverdueDecimal?: string
      accountsReceivableLateFeeOverdueDecimal?: string
      accountsReceivableModificationFeeOverdueDecimal?: string
      accountsReceivableOriginationFeeOverdueDecimal?: string
      accountsReceivablePrincipalOverdueDecimal?: string
      forgoneInterestRoundingDecimal?: string
    }
  }
  query?: void
}

export const internalUpdateEvent = makeData<
  InternalUpdateEventResponse,
  InternalUpdateEventKey,
  InternalUpdateEventVariables
>({
  method: 'put',
  name: 'internalUpdateEvent',
  summary: 'Update event',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/events/{eventId}',
  queryKey: ({ query, companyId, personId, loanId, eventId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'events',
    eventId,
    query,
  ],
})

export type InternalLoansGetByCompanyKey = ['companies', string | undefined, 'loans', unknown]

export type InternalLoansGetByCompanyResponse = SchemaTypes.LoansPaging | SchemaTypes.LoansIdsOnlyPaging

export type InternalLoansGetByCompanyVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     all
     */
    fields?: 'all' | 'idsOnly'
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    statuses?: Array<
      | 'all'
      | 'active'
      | 'pending'
      | 'originated'
      | 'declined'
      | 'frozen'
      | 'accelerated'
      | 'chargedOff'
      | 'paidOff'
      | 'canceled'
    >
    managedByPeach?: boolean | null
    includeAdditionalBorrowers?: boolean
    loanTypeId?: Array<SchemaTypes.AnyId>
    labels?: Array<string>
    createdAtBefore?: string
    createdAtAfter?: string
    updatedAtBefore?: string
    updatedAtAfter?: string
    originatedAtAfter?: string
    originatedAtBefore?: string
    activatedAtBefore?: string
    activatedAtAfter?: string
    sortBy?: Array<
      | 'createdAt'
      | '+createdAt'
      | '-createdAt'
      | 'updatedAt'
      | '+updatedAt'
      | '-updatedAt'
      | 'originatedAt'
      | '+originatedAt'
      | '-originatedAt'
      | 'activatedAt'
      | '+activatedAt'
      | '-activatedAt'
    >
  }
}

export const internalLoansGetByCompany = makeData<
  InternalLoansGetByCompanyResponse,
  InternalLoansGetByCompanyKey,
  InternalLoansGetByCompanyVariables
>({
  method: 'get',
  name: 'internalLoansGetByCompany',
  summary: 'Get loans',
  path: '/companies/{companyId}/loans',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'loans', query],
})

export type InternalLoansGetCountKey = ['companies', string | undefined, 'loan-count', unknown]

export type InternalLoansGetCountResponse = {
  data?: {
    /** The total number of loans matching the query. */
    total?: number
  }
}

export type InternalLoansGetCountVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    statuses?: Array<
      | 'all'
      | 'active'
      | 'pending'
      | 'originated'
      | 'declined'
      | 'frozen'
      | 'accelerated'
      | 'chargedOff'
      | 'paidOff'
      | 'canceled'
    >
    managedByPeach?: boolean | null
    loanTypeId?: Array<SchemaTypes.AnyId>
    labels?: Array<string>
    createdAtBefore?: string
    createdAtAfter?: string
    updatedAtBefore?: string
    updatedAtAfter?: string
    originatedAtAfter?: string
    originatedAtBefore?: string
    activatedAtBefore?: string
    activatedAtAfter?: string
  }
}

export const internalLoansGetCount = makeData<
  InternalLoansGetCountResponse,
  InternalLoansGetCountKey,
  InternalLoansGetCountVariables
>({
  method: 'get',
  name: 'internalLoansGetCount',
  summary: 'Get loan count',
  path: '/companies/{companyId}/loan-count',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'loan-count', query],
})

export type CompanyLoanConfigurationsGetAllKey = ['companies', string | undefined, 'loan-types', unknown]

export type CompanyLoanConfigurationsGetAllResponse = ResponseTypes.LoanConfigsPaging

export type CompanyLoanConfigurationsGetAllVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    startingAfter?: string
    endingBefore?: string
    reportingToAgency?: 'experian' | 'equifax' | 'transUnion' | 'innovis'
    sortBy?: Array<string>
    offset?: number
    /**
     * @default
     *     25
     */
    limit?: number
  }
}

export const companyLoanConfigurationsGetAll = makeData<
  CompanyLoanConfigurationsGetAllResponse,
  CompanyLoanConfigurationsGetAllKey,
  CompanyLoanConfigurationsGetAllVariables
>({
  method: 'get',
  name: 'companyLoanConfigurationsGetAll',
  summary: 'Get loan types',
  path: '/companies/{companyId}/loan-types',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'loan-types', query],
})

export type CompanyLoanConfigurationsAddKey = ['companies', string | undefined, 'loan-types', unknown]

export type CompanyLoanConfigurationsAddResponse = ResponseTypes.LoanType

export type CompanyLoanConfigurationsAddVariables = {
  companyId: string | undefined
  body?: RequestBodyTypes.LoanTypePost
  query?: void
}

export const companyLoanConfigurationsAdd = makeData<
  CompanyLoanConfigurationsAddResponse,
  CompanyLoanConfigurationsAddKey,
  CompanyLoanConfigurationsAddVariables
>({
  method: 'post',
  name: 'companyLoanConfigurationsAdd',
  summary: 'Create loan type',
  path: '/companies/{companyId}/loan-types',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'loan-types', query],
})

export type CompanyLoanConfigurationsGetKey = [
  'companies',
  string | undefined,
  'loan-types',
  string | undefined,
  unknown,
]

export type CompanyLoanConfigurationsGetResponse = ResponseTypes.LoanType

export type CompanyLoanConfigurationsGetVariables = {
  companyId: string | undefined
  loanTypeId: string | undefined
  body?: void
  query?: void
}

export const companyLoanConfigurationsGet = makeData<
  CompanyLoanConfigurationsGetResponse,
  CompanyLoanConfigurationsGetKey,
  CompanyLoanConfigurationsGetVariables
>({
  method: 'get',
  name: 'companyLoanConfigurationsGet',
  summary: 'Get loan type by ID',
  path: '/companies/{companyId}/loan-types/{loanTypeId}',
  queryKey: ({ query, companyId, loanTypeId }) => ['companies', companyId, 'loan-types', loanTypeId, query],
})

export type CompanyLoanConfigurationsUpdateKey = [
  'companies',
  string | undefined,
  'loan-types',
  string | undefined,
  unknown,
]

export type CompanyLoanConfigurationsUpdateResponse = ResponseTypes.LoanType

export type CompanyLoanConfigurationsUpdateVariables = {
  companyId: string | undefined
  loanTypeId: string | undefined
  body?: RequestBodyTypes.LoanTypeUpdate
  query?: void
}

export const companyLoanConfigurationsUpdate = makeData<
  CompanyLoanConfigurationsUpdateResponse,
  CompanyLoanConfigurationsUpdateKey,
  CompanyLoanConfigurationsUpdateVariables
>({
  method: 'put',
  name: 'companyLoanConfigurationsUpdate',
  summary: 'Update loan type by ID',
  path: '/companies/{companyId}/loan-types/{loanTypeId}',
  queryKey: ({ query, companyId, loanTypeId }) => ['companies', companyId, 'loan-types', loanTypeId, query],
})

export type CompanyLoanConfigurationsDeleteKey = [
  'companies',
  string | undefined,
  'loan-types',
  string | undefined,
  unknown,
]

export type CompanyLoanConfigurationsDeleteResponse = ResponseTypes.LoanType

export type CompanyLoanConfigurationsDeleteVariables = {
  companyId: string | undefined
  loanTypeId: string | undefined
  body?: void
  query?: {
    forceCancel?: 'nonTerminal' | 'all'
  }
}

export const companyLoanConfigurationsDelete = makeData<
  CompanyLoanConfigurationsDeleteResponse,
  CompanyLoanConfigurationsDeleteKey,
  CompanyLoanConfigurationsDeleteVariables
>({
  method: 'delete',
  name: 'companyLoanConfigurationsDelete',
  summary: 'Delete loan type by ID',
  path: '/companies/{companyId}/loan-types/{loanTypeId}',
  queryKey: ({ query, companyId, loanTypeId }) => ['companies', companyId, 'loan-types', loanTypeId, query],
})

export type LoansGetByLoanTypeKey = [
  'companies',
  string | undefined,
  'loan-types',
  string | undefined,
  'loans',
  unknown,
]

export type LoansGetByLoanTypeResponse = SchemaTypes.LoansPaging

export type LoansGetByLoanTypeVariables = {
  companyId: string | undefined
  loanTypeId: string | undefined
  body?: void
  query?: {
    statuses?: Array<
      | 'all'
      | 'active'
      | 'pending'
      | 'originated'
      | 'declined'
      | 'frozen'
      | 'accelerated'
      | 'chargedOff'
      | 'paidOff'
      | 'canceled'
    >
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    includeAdditionalBorrowers?: boolean
  }
}

export const loansGetByLoanType = makeData<
  LoansGetByLoanTypeResponse,
  LoansGetByLoanTypeKey,
  LoansGetByLoanTypeVariables
>({
  method: 'get',
  name: 'loansGetByLoanType',
  summary: 'Get all loans by loan type',
  path: '/companies/{companyId}/loan-types/{loanTypeId}/loans',
  queryKey: ({ query, companyId, loanTypeId }) => ['companies', companyId, 'loan-types', loanTypeId, 'loans', query],
})

export type InternalBorrowerPaymentInstrumentGetAllKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'payment-instruments',
  unknown,
]

export type InternalBorrowerPaymentInstrumentGetAllResponse = UtilityTypes.Paging & {
  count: number
  data?: Array<SchemaTypes.PaymentInstrumentGet>
}

export type InternalBorrowerPaymentInstrumentGetAllVariables = {
  companyId: string | undefined
  personId: string | undefined
  body?: void
  query?: {
    isExternal?: boolean
    showFullAccountNumber?: boolean
  }
}

export const internalBorrowerPaymentInstrumentGetAll = makeData<
  InternalBorrowerPaymentInstrumentGetAllResponse,
  InternalBorrowerPaymentInstrumentGetAllKey,
  InternalBorrowerPaymentInstrumentGetAllVariables
>({
  method: 'get',
  name: 'internalBorrowerPaymentInstrumentGetAll',
  summary: 'Get payment instruments',
  path: '/companies/{companyId}/people/{personId}/payment-instruments',
  queryKey: ({ query, companyId, personId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'payment-instruments',
    query,
  ],
})

export type InternalBorrowerPaymentInstrumentCreateKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'payment-instruments',
  unknown,
]

export type InternalBorrowerPaymentInstrumentCreateResponse = UtilityTypes.Paging & {
  count: number
  data?: Array<SchemaTypes.PaymentInstrumentGet>
}

export type InternalBorrowerPaymentInstrumentCreateVariables = {
  companyId: string | undefined
  personId: string | undefined
  body?: SchemaTypes.PaymentInstrumentPost
  query?: {
    force?: boolean
    sync?: boolean
    allowDuplicates?: boolean
  }
}

export const internalBorrowerPaymentInstrumentCreate = makeData<
  InternalBorrowerPaymentInstrumentCreateResponse,
  InternalBorrowerPaymentInstrumentCreateKey,
  InternalBorrowerPaymentInstrumentCreateVariables
>({
  method: 'post',
  name: 'internalBorrowerPaymentInstrumentCreate',
  summary: 'Create payment instrument',
  path: '/companies/{companyId}/people/{personId}/payment-instruments',
  queryKey: ({ query, companyId, personId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'payment-instruments',
    query,
  ],
})

export type InternalBorrowerPaymentInstrumentGetKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'payment-instruments',
  string | undefined,
  unknown,
]

export type InternalBorrowerPaymentInstrumentGetResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.PaymentInstrument
}

export type InternalBorrowerPaymentInstrumentGetVariables = {
  companyId: string | undefined
  personId: string | undefined
  paymentInstrumentId: string | undefined
  body?: void
  query?: {
    showFullAccountNumber?: boolean
  }
}

export const internalBorrowerPaymentInstrumentGet = makeData<
  InternalBorrowerPaymentInstrumentGetResponse,
  InternalBorrowerPaymentInstrumentGetKey,
  InternalBorrowerPaymentInstrumentGetVariables
>({
  method: 'get',
  name: 'internalBorrowerPaymentInstrumentGet',
  summary: 'Get payment instrument by ID',
  path: '/companies/{companyId}/people/{personId}/payment-instruments/{paymentInstrumentId}',
  queryKey: ({ query, companyId, personId, paymentInstrumentId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    query,
  ],
})

export type InternalBorrowerPaymentInstrumentUpdateKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'payment-instruments',
  string | undefined,
  unknown,
]

export type InternalBorrowerPaymentInstrumentUpdateResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.PaymentInstrument
}

export type InternalBorrowerPaymentInstrumentUpdateVariables = {
  companyId: string | undefined
  personId: string | undefined
  paymentInstrumentId: string | undefined
  body?: SchemaTypes.PaymentInstrumentMutable
  query?: {
    sync?: boolean
  }
}

export const internalBorrowerPaymentInstrumentUpdate = makeData<
  InternalBorrowerPaymentInstrumentUpdateResponse,
  InternalBorrowerPaymentInstrumentUpdateKey,
  InternalBorrowerPaymentInstrumentUpdateVariables
>({
  method: 'put',
  name: 'internalBorrowerPaymentInstrumentUpdate',
  summary: 'Update payment instrument',
  path: '/companies/{companyId}/people/{personId}/payment-instruments/{paymentInstrumentId}',
  queryKey: ({ query, companyId, personId, paymentInstrumentId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    query,
  ],
})

export type InternalBorrowerPaymentInstrumentDeleteKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'payment-instruments',
  string | undefined,
  unknown,
]

export type InternalBorrowerPaymentInstrumentDeleteResponse = void

export type InternalBorrowerPaymentInstrumentDeleteVariables = {
  companyId: string | undefined
  personId: string | undefined
  paymentInstrumentId: string | undefined
  body?: void
  query?: void
}

export const internalBorrowerPaymentInstrumentDelete = makeData<
  InternalBorrowerPaymentInstrumentDeleteResponse,
  InternalBorrowerPaymentInstrumentDeleteKey,
  InternalBorrowerPaymentInstrumentDeleteVariables
>({
  method: 'delete',
  name: 'internalBorrowerPaymentInstrumentDelete',
  summary: 'Delete payment instrument',
  path: '/companies/{companyId}/people/{personId}/payment-instruments/{paymentInstrumentId}',
  queryKey: ({ query, companyId, personId, paymentInstrumentId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    query,
  ],
})

export type InternalAccountLinkPostKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'payment-instruments',
  string | undefined,
  'account-link',
  unknown,
]

export type InternalAccountLinkPostResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.AccountLink
}

export type InternalAccountLinkPostVariables = {
  companyId: string | undefined
  personId: string | undefined
  paymentInstrumentId: string | undefined
  body?: SchemaTypes.AccountLink
  query?: void
}

export const internalAccountLinkPost = makeData<
  InternalAccountLinkPostResponse,
  InternalAccountLinkPostKey,
  InternalAccountLinkPostVariables
>({
  method: 'post',
  name: 'internalAccountLinkPost',
  summary: 'Create account link',
  path: '/companies/{companyId}/people/{personId}/payment-instruments/{paymentInstrumentId}/account-link',
  queryKey: ({ query, companyId, personId, paymentInstrumentId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    'account-link',
    query,
  ],
})

export type InternalAccountLinkDeleteKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'payment-instruments',
  string | undefined,
  'account-link',
  unknown,
]

export type InternalAccountLinkDeleteResponse = void

export type InternalAccountLinkDeleteVariables = {
  companyId: string | undefined
  personId: string | undefined
  paymentInstrumentId: string | undefined
  body?: void
  query?: void
}

export const internalAccountLinkDelete = makeData<
  InternalAccountLinkDeleteResponse,
  InternalAccountLinkDeleteKey,
  InternalAccountLinkDeleteVariables
>({
  method: 'delete',
  name: 'internalAccountLinkDelete',
  summary: 'Delete account link',
  path: '/companies/{companyId}/people/{personId}/payment-instruments/{paymentInstrumentId}/account-link',
  queryKey: ({ query, companyId, personId, paymentInstrumentId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    'account-link',
    query,
  ],
})

export type InternalBalancesGetKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'payment-instruments',
  string | undefined,
  'balance',
  unknown,
]

export type InternalBalancesGetResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.AccountLinkBalanceEntry
}

export type InternalBalancesGetVariables = {
  companyId: string | undefined
  personId: string | undefined
  paymentInstrumentId: string | undefined
  body?: void
  query?: void
}

export const internalBalancesGet = makeData<
  InternalBalancesGetResponse,
  InternalBalancesGetKey,
  InternalBalancesGetVariables
>({
  method: 'get',
  name: 'internalBalancesGet',
  summary: 'Get balance data',
  path: '/companies/{companyId}/people/{personId}/payment-instruments/{paymentInstrumentId}/balance',
  queryKey: ({ query, companyId, personId, paymentInstrumentId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    'balance',
    query,
  ],
})

export type InternalBalancesPostKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'payment-instruments',
  string | undefined,
  'balance',
  unknown,
]

export type InternalBalancesPostResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.AccountLinkBalanceEntry
}

export type InternalBalancesPostVariables = {
  companyId: string | undefined
  personId: string | undefined
  paymentInstrumentId: string | undefined
  body?: void
  query?: void
}

export const internalBalancesPost = makeData<
  InternalBalancesPostResponse,
  InternalBalancesPostKey,
  InternalBalancesPostVariables
>({
  method: 'post',
  name: 'internalBalancesPost',
  summary: 'Refresh balance data',
  path: '/companies/{companyId}/people/{personId}/payment-instruments/{paymentInstrumentId}/balance',
  queryKey: ({ query, companyId, personId, paymentInstrumentId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    'balance',
    query,
  ],
})

export type InternalLoansGetKey = ['companies', string | undefined, 'people', string | undefined, 'loans', unknown]

export type InternalLoansGetResponse = SchemaTypes.LoansPaging

export type InternalLoansGetVariables = {
  companyId: string | undefined
  personId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    status?: Array<
      | 'all'
      | 'active'
      | 'pending'
      | 'originated'
      | 'declined'
      | 'frozen'
      | 'accelerated'
      | 'chargedOff'
      | 'paidOff'
      | 'canceled'
    >
    loanTypeId?: Array<SchemaTypes.AnyId>
  }
}

export const internalLoansGet = makeData<InternalLoansGetResponse, InternalLoansGetKey, InternalLoansGetVariables>({
  method: 'get',
  name: 'internalLoansGet',
  summary: 'Get loans',
  path: '/companies/{companyId}/people/{personId}/loans',
  queryKey: ({ query, companyId, personId }) => ['companies', companyId, 'people', personId, 'loans', query],
})

export type InternalLoansAddKey = ['companies', string | undefined, 'people', string | undefined, 'loans', unknown]

export type InternalLoansAddResponse = ResponseTypes.Loan

export type InternalLoansAddVariables = {
  companyId: string | undefined
  personId: string | undefined
  body?: RequestBodyTypes.LoanPost
  query?: {
    /**
     * @default
     *     true
     */
    validate?: boolean
  }
}

export const internalLoansAdd = makeData<InternalLoansAddResponse, InternalLoansAddKey, InternalLoansAddVariables>({
  method: 'post',
  name: 'internalLoansAdd',
  summary: 'Create loan',
  path: '/companies/{companyId}/people/{personId}/loans',
  queryKey: ({ query, companyId, personId }) => ['companies', companyId, 'people', personId, 'loans', query],
})

export type InternalLoanGetKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  unknown,
]

export type InternalLoanGetResponse = ResponseTypes.Loan

export type InternalLoanGetVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const internalLoanGet = makeData<InternalLoanGetResponse, InternalLoanGetKey, InternalLoanGetVariables>({
  method: 'get',
  name: 'internalLoanGet',
  summary: 'Get loan by ID',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    query,
  ],
})

export type InternalLoanUpdateKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  unknown,
]

export type InternalLoanUpdateResponse = ResponseTypes.Loan

export type InternalLoanUpdateVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanUpdate
  query?: {
    /**
     * @default
     *     true
     */
    validate?: boolean
    force?: boolean
  }
}

export const internalLoanUpdate = makeData<
  InternalLoanUpdateResponse,
  InternalLoanUpdateKey,
  InternalLoanUpdateVariables
>({
  method: 'put',
  name: 'internalLoanUpdate',
  summary: 'Update loan',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    query,
  ],
})

export type InternalSyncPayoffStatusKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'sync-payoff-status',
  unknown,
]

export type InternalSyncPayoffStatusResponse = ResponseTypes.Loan

export type InternalSyncPayoffStatusVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const internalSyncPayoffStatus = makeData<
  InternalSyncPayoffStatusResponse,
  InternalSyncPayoffStatusKey,
  InternalSyncPayoffStatusVariables
>({
  method: 'post',
  name: 'internalSyncPayoffStatus',
  summary: 'Update loan',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/sync-payoff-status',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'sync-payoff-status',
    query,
  ],
})

export type LoggingDebuggerKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'logging-debugger',
  unknown,
]

export type LoggingDebuggerResponse = void

export type LoggingDebuggerVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanLoggingDebugger
  query?: void
}

export const loggingDebugger = makeData<LoggingDebuggerResponse, LoggingDebuggerKey, LoggingDebuggerVariables>({
  method: 'post',
  name: 'loggingDebugger',
  summary: 'Debug loan',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/logging-debugger',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'logging-debugger',
    query,
  ],
})

export type LedgerDebuggerKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'ledger-debugger',
  unknown,
]

export type LedgerDebuggerResponse = void

export type LedgerDebuggerVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    ledgerType?: 'servicing' | 'accounting'
    outputFormat?: 'json' | 'csv'
  }
}

export const ledgerDebugger = makeData<LedgerDebuggerResponse, LedgerDebuggerKey, LedgerDebuggerVariables>({
  method: 'get',
  name: 'ledgerDebugger',
  summary: "Debug a loan's ledger",
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/ledger-debugger',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'ledger-debugger',
    query,
  ],
})

export type InternalLoanRatesDeleteKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'rates',
  string | undefined,
  unknown,
]

export type InternalLoanRatesDeleteResponse = void

export type InternalLoanRatesDeleteVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  rateId: string | undefined
  body?: void
  query?: {
    caseId?: SchemaTypes.AnyId
  }
}

export const internalLoanRatesDelete = makeData<
  InternalLoanRatesDeleteResponse,
  InternalLoanRatesDeleteKey,
  InternalLoanRatesDeleteVariables
>({
  method: 'delete',
  name: 'internalLoanRatesDelete',
  summary: 'Remove loan rate',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/rates/{rateId}',
  queryKey: ({ query, companyId, personId, loanId, rateId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'rates',
    rateId,
    query,
  ],
})

export type InternalForecastInterestKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'forecast-interest',
  unknown,
]

export type InternalForecastInterestResponse = {
  data?: {
    forecastedInterest?: number
    forecastedDiscount?: number
    /**
     * If this value does not equal the difference between `forecastedInterest` and `forecastedDiscount`, that means there is an interest cap
     * in effect.
     */
    netForecastedInterest?: number
    forecastedDeferredInterest?: number
    forecastedDeferredDiscount?: number
    netForecastedDeferredInterest?: number
    forecastedInterestStr?: string
    forecastedDiscountStr?: string
    /**
     * If this value does not equal the difference between `forecastedInterest` and `forecastedDiscount`, that means there is an interest cap
     * in effect.
     */
    netForecastedInterestStr?: string
    forecastedDeferredInterestStr?: string
    forecastedDeferredDiscountStr?: string
    netForecastedDeferredInterestStr?: string
    /**
     * If the loan in question is a draw with `minInterestChargeAmount` configured, then this value will contain the additional interest that
     * must be added on statement date to meet the required minimum.
     */
    minInterestChargedDiff?: number
  }
}

export type InternalForecastInterestVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    accrualStartDate?: string
    accrualEndDate?: string
    /**
     * Certain loans are subject to a cap on interest, after which interest will accrue but is then immediately placed in the foregone interest bucket.
     * If zeroAfterCap=True, any interest after the cap will be zero'ed out in the interest forecast calculation, if False, we return the forecasted
     * interest as if there is no cap.
     */
    zeroAfterCap?: boolean
  }
}

export const internalForecastInterest = makeData<
  InternalForecastInterestResponse,
  InternalForecastInterestKey,
  InternalForecastInterestVariables
>({
  method: 'get',
  name: 'internalForecastInterest',
  summary: 'Get interest forecast',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/forecast-interest',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'forecast-interest',
    query,
  ],
})

export type InternalLoanRatesGetKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'rates',
  unknown,
]

export type InternalLoanRatesGetResponse = ResponseTypes.LoanRatesResponse

export type InternalLoanRatesGetVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const internalLoanRatesGet = makeData<
  InternalLoanRatesGetResponse,
  InternalLoanRatesGetKey,
  InternalLoanRatesGetVariables
>({
  method: 'get',
  name: 'internalLoanRatesGet',
  summary: 'Get loan rates',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/rates',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'rates',
    query,
  ],
})

export type InternalLoanRatesAddKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'rates',
  unknown,
]

export type InternalLoanRatesAddResponse = ResponseTypes.CreateOrUpdateInterestRatesResponse

export type InternalLoanRatesAddVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanRateAdd
  query?: void
}

export const internalLoanRatesAdd = makeData<
  InternalLoanRatesAddResponse,
  InternalLoanRatesAddKey,
  InternalLoanRatesAddVariables
>({
  method: 'post',
  name: 'internalLoanRatesAdd',
  summary: 'Add loan rate',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/rates',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'rates',
    query,
  ],
})

export type InternalGetLoanPeriodsKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'periods',
  unknown,
]

export type InternalGetLoanPeriodsResponse = ResponseTypes.LoanPeriodsResponse

export type InternalGetLoanPeriodsVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const internalGetLoanPeriods = makeData<
  InternalGetLoanPeriodsResponse,
  InternalGetLoanPeriodsKey,
  InternalGetLoanPeriodsVariables
>({
  method: 'get',
  name: 'internalGetLoanPeriods',
  summary: 'Get loan periods',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/periods',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'periods',
    query,
  ],
})

export type InternalUpdateLoanPeriodKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'periods',
  unknown,
]

export type InternalUpdateLoanPeriodResponse = {
  data?: SchemaTypes.LoanPeriod
}

export type InternalUpdateLoanPeriodVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: {
    endDate?: string
    periodLength?: number
  }
  query?: void
}

export const internalUpdateLoanPeriod = makeData<
  InternalUpdateLoanPeriodResponse,
  InternalUpdateLoanPeriodKey,
  InternalUpdateLoanPeriodVariables
>({
  method: 'put',
  name: 'internalUpdateLoanPeriod',
  summary: 'Update loan period',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/periods',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'periods',
    query,
  ],
})

export type InternalCreateNextLoanPeriodKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'periods',
  unknown,
]

export type InternalCreateNextLoanPeriodResponse = ResponseTypes.LoanPeriodsResponse

export type InternalCreateNextLoanPeriodVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: {
    date?: string
  }
  query?: void
}

export const internalCreateNextLoanPeriod = makeData<
  InternalCreateNextLoanPeriodResponse,
  InternalCreateNextLoanPeriodKey,
  InternalCreateNextLoanPeriodVariables
>({
  method: 'post',
  name: 'internalCreateNextLoanPeriod',
  summary: 'Create loan period',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/periods',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'periods',
    query,
  ],
})

export type InternalLoanExpectedPaymentsGetKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'expected-payments',
  unknown,
]

export type InternalLoanExpectedPaymentsGetResponse = ResponseTypes.ExpectedPayments

export type InternalLoanExpectedPaymentsGetVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const internalLoanExpectedPaymentsGet = makeData<
  InternalLoanExpectedPaymentsGetResponse,
  InternalLoanExpectedPaymentsGetKey,
  InternalLoanExpectedPaymentsGetVariables
>({
  method: 'get',
  name: 'internalLoanExpectedPaymentsGet',
  summary: 'Get expected payments',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/expected-payments',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'expected-payments',
    query,
  ],
})

export type InternalLoanChangeDueDatesSchedulePostKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'expected-payments',
  unknown,
]

export type InternalLoanChangeDueDatesSchedulePostResponse = ResponseTypes.ChangeSchedule

export type InternalLoanChangeDueDatesSchedulePostVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.DueDatesPost
  query?: void
}

export const internalLoanChangeDueDatesSchedulePost = makeData<
  InternalLoanChangeDueDatesSchedulePostResponse,
  InternalLoanChangeDueDatesSchedulePostKey,
  InternalLoanChangeDueDatesSchedulePostVariables
>({
  method: 'post',
  name: 'internalLoanChangeDueDatesSchedulePost',
  summary: 'Change due dates schedule',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/expected-payments',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'expected-payments',
    query,
  ],
})

export type InternalLoanExpectedPaymentsGetOneKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'expected-payments',
  string | undefined,
  unknown,
]

export type InternalLoanExpectedPaymentsGetOneResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.ExpectedPayment
}

export type InternalLoanExpectedPaymentsGetOneVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  expectedPaymentId: string | undefined
  body?: void
  query?: void
}

export const internalLoanExpectedPaymentsGetOne = makeData<
  InternalLoanExpectedPaymentsGetOneResponse,
  InternalLoanExpectedPaymentsGetOneKey,
  InternalLoanExpectedPaymentsGetOneVariables
>({
  method: 'get',
  name: 'internalLoanExpectedPaymentsGetOne',
  summary: 'Get expected payment by ID',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/expected-payments/{expectedPaymentId}',
  queryKey: ({ query, companyId, personId, loanId, expectedPaymentId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'expected-payments',
    expectedPaymentId,
    query,
  ],
})

export type InternalLoanAutopayGetKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'autopay',
  unknown,
]

export type InternalLoanAutopayGetResponse = ResponseTypes.Autopay

export type InternalLoanAutopayGetVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     [
     *       "booked",
     *       "modified"
     *     ]
     */
    statuses?: Array<'booked' | 'skipped' | 'canceled' | 'modified' | 'draft' | 'processed'>
    includeZeros?: boolean
  }
}

export const internalLoanAutopayGet = makeData<
  InternalLoanAutopayGetResponse,
  InternalLoanAutopayGetKey,
  InternalLoanAutopayGetVariables
>({
  method: 'get',
  name: 'internalLoanAutopayGet',
  summary: 'Get Autopay plan',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/autopay',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'autopay',
    query,
  ],
})

export type InternalLoanAutopayPutKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'autopay',
  unknown,
]

export type InternalLoanAutopayPutResponse = ResponseTypes.AutopayPut

export type InternalLoanAutopayPutVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.AutopayPut
  query?: void
}

export const internalLoanAutopayPut = makeData<
  InternalLoanAutopayPutResponse,
  InternalLoanAutopayPutKey,
  InternalLoanAutopayPutVariables
>({
  method: 'put',
  name: 'internalLoanAutopayPut',
  summary: 'Update Autopay plan',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/autopay',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'autopay',
    query,
  ],
})

export type InternalLoanAutopayPostKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'autopay',
  unknown,
]

export type InternalLoanAutopayPostResponse = ResponseTypes.AutopayPost

export type InternalLoanAutopayPostVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.AutopayPost
  query?: void
}

export const internalLoanAutopayPost = makeData<
  InternalLoanAutopayPostResponse,
  InternalLoanAutopayPostKey,
  InternalLoanAutopayPostVariables
>({
  method: 'post',
  name: 'internalLoanAutopayPost',
  summary: 'Create Autopay plan',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/autopay',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'autopay',
    query,
  ],
})

export type InternalLoanAutopayDeleteKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'autopay',
  unknown,
]

export type InternalLoanAutopayDeleteResponse = void

export type InternalLoanAutopayDeleteVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     canceledByUser
     */
    cancellationReason?:
      | 'invalidPaymentMethod'
      | 'paymentMethodRemoved'
      | 'tooManyFailedAttempts'
      | 'loanFrozen'
      | 'loanAccelerated'
      | 'loanChargedOff'
      | 'loanPaidOff'
      | 'canceledByUser'
      | 'loanTermsChanged'
  }
}

export const internalLoanAutopayDelete = makeData<
  InternalLoanAutopayDeleteResponse,
  InternalLoanAutopayDeleteKey,
  InternalLoanAutopayDeleteVariables
>({
  method: 'delete',
  name: 'internalLoanAutopayDelete',
  summary: 'Cancel Autopay plan',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/autopay',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'autopay',
    query,
  ],
})

export type InternalLoanAutopayCancelPaymentsKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'autopay',
  'cancel-payments',
  unknown,
]

export type InternalLoanAutopayCancelPaymentsResponse = void

export type InternalLoanAutopayCancelPaymentsVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.AutopayCancelExpectedPaymentsPost
  query?: void
}

export const internalLoanAutopayCancelPayments = makeData<
  InternalLoanAutopayCancelPaymentsResponse,
  InternalLoanAutopayCancelPaymentsKey,
  InternalLoanAutopayCancelPaymentsVariables
>({
  method: 'post',
  name: 'internalLoanAutopayCancelPayments',
  summary: 'Cancel autopay expected payments',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/autopay/cancel-payments',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'autopay',
    'cancel-payments',
    query,
  ],
})

export type InternalLoanAutopayReschedulePaymentsKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'autopay',
  'reschedule-payments',
  unknown,
]

export type InternalLoanAutopayReschedulePaymentsResponse = void

export type InternalLoanAutopayReschedulePaymentsVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.AutopayRescheduleExpectedPaymentsPost
  query?: void
}

export const internalLoanAutopayReschedulePayments = makeData<
  InternalLoanAutopayReschedulePaymentsResponse,
  InternalLoanAutopayReschedulePaymentsKey,
  InternalLoanAutopayReschedulePaymentsVariables
>({
  method: 'post',
  name: 'internalLoanAutopayReschedulePayments',
  summary: 'Cancel autopay expected payments',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/autopay/reschedule-payments',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'autopay',
    'reschedule-payments',
    query,
  ],
})

export type InternalLoanAutopayCreateNextAutopayKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'autopay',
  'create-next-autopay',
  unknown,
]

export type InternalLoanAutopayCreateNextAutopayResponse = void

export type InternalLoanAutopayCreateNextAutopayVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.CreateNextAutopayPost
  query?: void
}

export const internalLoanAutopayCreateNextAutopay = makeData<
  InternalLoanAutopayCreateNextAutopayResponse,
  InternalLoanAutopayCreateNextAutopayKey,
  InternalLoanAutopayCreateNextAutopayVariables
>({
  method: 'post',
  name: 'internalLoanAutopayCreateNextAutopay',
  summary: 'Create the next autopay for LOC',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/autopay/create-next-autopay',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'autopay',
    'create-next-autopay',
    query,
  ],
})

export type InternalLoanAutopayUpdateExpectedPaymentAmountKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'autopay',
  string | undefined,
  'update-amount',
  unknown,
]

export type InternalLoanAutopayUpdateExpectedPaymentAmountResponse = void

export type InternalLoanAutopayUpdateExpectedPaymentAmountVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  autopayExpectedPaymentId: string | undefined
  body?: RequestBodyTypes.UpdateAutopayExpectedPaymentAmountPut
  query?: void
}

export const internalLoanAutopayUpdateExpectedPaymentAmount = makeData<
  InternalLoanAutopayUpdateExpectedPaymentAmountResponse,
  InternalLoanAutopayUpdateExpectedPaymentAmountKey,
  InternalLoanAutopayUpdateExpectedPaymentAmountVariables
>({
  method: 'put',
  name: 'internalLoanAutopayUpdateExpectedPaymentAmount',
  summary: 'Update an autopay expected payment amount',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/autopay/{autopayExpectedPaymentId}/update-amount',
  queryKey: ({ query, companyId, personId, loanId, autopayExpectedPaymentId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'autopay',
    autopayExpectedPaymentId,
    'update-amount',
    query,
  ],
})

export type InternalLoanBalancesGetKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'balances',
  unknown,
]

export type InternalLoanBalancesGetResponse = ResponseTypes.Balances

export type InternalLoanBalancesGetVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    isRounded?: boolean
    skipYtd?: boolean
    fast?: boolean
    toEffectiveDate?: string
    skipInterestAccrual?: boolean
  }
}

export const internalLoanBalancesGet = makeData<
  InternalLoanBalancesGetResponse,
  InternalLoanBalancesGetKey,
  InternalLoanBalancesGetVariables
>({
  method: 'get',
  name: 'internalLoanBalancesGet',
  summary: 'Get loan balances',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/balances',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'balances',
    query,
  ],
})

export type InternalLoanFutureBalancesGetKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'future_balances',
  unknown,
]

export type InternalLoanFutureBalancesGetResponse = ResponseTypes.FutureBalances

export type InternalLoanFutureBalancesGetVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    isRounded?: boolean
  }
}

export const internalLoanFutureBalancesGet = makeData<
  InternalLoanFutureBalancesGetResponse,
  InternalLoanFutureBalancesGetKey,
  InternalLoanFutureBalancesGetVariables
>({
  method: 'get',
  name: 'internalLoanFutureBalancesGet',
  summary: 'Get loan future balances',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/future_balances',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'future_balances',
    query,
  ],
})

export type InternalLoanTypeIndexRateGetAllKey = [
  'companies',
  string | undefined,
  'loan-types',
  string | undefined,
  'index-rates',
  unknown,
]

export type InternalLoanTypeIndexRateGetAllResponse = ResponseTypes.IndexRatePaging

export type InternalLoanTypeIndexRateGetAllVariables = {
  companyId: string | undefined
  loanTypeId: string | undefined
  body?: void
  query?: {
    fromDate?: string
    toDate?: string
  }
}

export const internalLoanTypeIndexRateGetAll = makeData<
  InternalLoanTypeIndexRateGetAllResponse,
  InternalLoanTypeIndexRateGetAllKey,
  InternalLoanTypeIndexRateGetAllVariables
>({
  method: 'get',
  name: 'internalLoanTypeIndexRateGetAll',
  summary: 'Get index interest rates',
  path: '/companies/{companyId}/loan-types/{loanTypeId}/index-rates',
  queryKey: ({ query, companyId, loanTypeId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'index-rates',
    query,
  ],
})

export type InternalLoanTypeIndexRateAddKey = [
  'companies',
  string | undefined,
  'loan-types',
  string | undefined,
  'index-rates',
  unknown,
]

export type InternalLoanTypeIndexRateAddResponse = ResponseTypes.IndexRateResponse

export type InternalLoanTypeIndexRateAddVariables = {
  companyId: string | undefined
  loanTypeId: string | undefined
  body?: RequestBodyTypes.IndexRateAdd
  query?: void
}

export const internalLoanTypeIndexRateAdd = makeData<
  InternalLoanTypeIndexRateAddResponse,
  InternalLoanTypeIndexRateAddKey,
  InternalLoanTypeIndexRateAddVariables
>({
  method: 'post',
  name: 'internalLoanTypeIndexRateAdd',
  summary: 'Add index interest rate',
  path: '/companies/{companyId}/loan-types/{loanTypeId}/index-rates',
  queryKey: ({ query, companyId, loanTypeId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'index-rates',
    query,
  ],
})

export type InternalLoanTypeIndexRateGetKey = [
  'companies',
  string | undefined,
  'loan-types',
  string | undefined,
  'index-rates',
  string | undefined,
  unknown,
]

export type InternalLoanTypeIndexRateGetResponse = ResponseTypes.IndexRateResponse

export type InternalLoanTypeIndexRateGetVariables = {
  companyId: string | undefined
  loanTypeId: string | undefined
  indexRateId: string | undefined
  body?: void
  query?: void
}

export const internalLoanTypeIndexRateGet = makeData<
  InternalLoanTypeIndexRateGetResponse,
  InternalLoanTypeIndexRateGetKey,
  InternalLoanTypeIndexRateGetVariables
>({
  method: 'get',
  name: 'internalLoanTypeIndexRateGet',
  summary: 'Get index interest rate by ID',
  path: '/companies/{companyId}/loan-types/{loanTypeId}/index-rates/{indexRateId}',
  queryKey: ({ query, companyId, loanTypeId, indexRateId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'index-rates',
    indexRateId,
    query,
  ],
})

export type InternalLoanTypeIndexRateUpdateKey = [
  'companies',
  string | undefined,
  'loan-types',
  string | undefined,
  'index-rates',
  string | undefined,
  unknown,
]

export type InternalLoanTypeIndexRateUpdateResponse = ResponseTypes.IndexRateResponse

export type InternalLoanTypeIndexRateUpdateVariables = {
  companyId: string | undefined
  loanTypeId: string | undefined
  indexRateId: string | undefined
  body?: RequestBodyTypes.IndexRateUpdate
  query?: void
}

export const internalLoanTypeIndexRateUpdate = makeData<
  InternalLoanTypeIndexRateUpdateResponse,
  InternalLoanTypeIndexRateUpdateKey,
  InternalLoanTypeIndexRateUpdateVariables
>({
  method: 'put',
  name: 'internalLoanTypeIndexRateUpdate',
  summary: 'Update index interest rate',
  path: '/companies/{companyId}/loan-types/{loanTypeId}/index-rates/{indexRateId}',
  queryKey: ({ query, companyId, loanTypeId, indexRateId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'index-rates',
    indexRateId,
    query,
  ],
})

export type InternalLoanTypeIndexRateDeleteKey = [
  'companies',
  string | undefined,
  'loan-types',
  string | undefined,
  'index-rates',
  string | undefined,
  unknown,
]

export type InternalLoanTypeIndexRateDeleteResponse = void

export type InternalLoanTypeIndexRateDeleteVariables = {
  companyId: string | undefined
  loanTypeId: string | undefined
  indexRateId: string | undefined
  body?: void
  query?: void
}

export const internalLoanTypeIndexRateDelete = makeData<
  InternalLoanTypeIndexRateDeleteResponse,
  InternalLoanTypeIndexRateDeleteKey,
  InternalLoanTypeIndexRateDeleteVariables
>({
  method: 'delete',
  name: 'internalLoanTypeIndexRateDelete',
  summary: 'Cancel index interest rate',
  path: '/companies/{companyId}/loan-types/{loanTypeId}/index-rates/{indexRateId}',
  queryKey: ({ query, companyId, loanTypeId, indexRateId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'index-rates',
    indexRateId,
    query,
  ],
})

export type InternalObligationsGetAllKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'obligations',
  unknown,
]

export type InternalObligationsGetAllResponse = ResponseTypes.Obligations

export type InternalObligationsGetAllVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    periodId?: string
    status?: 'unfulfilled' | 'overdue'
    statuses?: Array<
      | 'all'
      | 'active'
      | 'pending'
      | 'originated'
      | 'declined'
      | 'frozen'
      | 'accelerated'
      | 'chargedOff'
      | 'paidOff'
      | 'canceled'
    >
    returnSeparated?: boolean
  }
}

export const internalObligationsGetAll = makeData<
  InternalObligationsGetAllResponse,
  InternalObligationsGetAllKey,
  InternalObligationsGetAllVariables
>({
  method: 'get',
  name: 'internalObligationsGetAll',
  summary: 'Get obligations',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/obligations',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'obligations',
    query,
  ],
})

export type InternalObligationCreateKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'obligations',
  unknown,
]

export type InternalObligationCreateResponse = ResponseTypes.Obligation

export type InternalObligationCreateVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.ObligationPost
  query?: void
}

export const internalObligationCreate = makeData<
  InternalObligationCreateResponse,
  InternalObligationCreateKey,
  InternalObligationCreateVariables
>({
  method: 'post',
  name: 'internalObligationCreate',
  summary: 'Create obligation',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/obligations',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'obligations',
    query,
  ],
})

export type GetLoanKey = ['companies', string | undefined, 'loans', string | undefined, unknown]

export type GetLoanResponse = ResponseTypes.Loan

export type GetLoanVariables = {
  companyId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    includeAdditionalBorrowers?: boolean
  }
}

export const getLoan = makeData<GetLoanResponse, GetLoanKey, GetLoanVariables>({
  method: 'get',
  name: 'getLoan',
  summary: 'Get loan without person ID',
  path: '/companies/{companyId}/loans/{loanId}',
  queryKey: ({ query, companyId, loanId }) => ['companies', companyId, 'loans', loanId, query],
})

export type GetCompanyLoanObligationsKey = [
  'companies',
  string | undefined,
  'loans',
  string | undefined,
  'obligations',
  unknown,
]

export type GetCompanyLoanObligationsResponse = ResponseTypes.Obligations

export type GetCompanyLoanObligationsVariables = {
  companyId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    periodId?: string
    status?: 'unfulfilled' | 'overdue'
  }
}

export const getCompanyLoanObligations = makeData<
  GetCompanyLoanObligationsResponse,
  GetCompanyLoanObligationsKey,
  GetCompanyLoanObligationsVariables
>({
  method: 'get',
  name: 'getCompanyLoanObligations',
  summary: 'Get obligations without person ID',
  path: '/companies/{companyId}/loans/{loanId}/obligations',
  queryKey: ({ query, companyId, loanId }) => ['companies', companyId, 'loans', loanId, 'obligations', query],
})

export type ObligationsRefreshPreviewForTimestampKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'obligations',
  'refresh-preview',
  unknown,
]

export type ObligationsRefreshPreviewForTimestampResponse = ResponseTypes.Obligations

export type ObligationsRefreshPreviewForTimestampVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    timestamp: string
    transactionId?: string
    autosum?: boolean
  }
}

export const obligationsRefreshPreviewForTimestamp = makeData<
  ObligationsRefreshPreviewForTimestampResponse,
  ObligationsRefreshPreviewForTimestampKey,
  ObligationsRefreshPreviewForTimestampVariables
>({
  method: 'get',
  name: 'obligationsRefreshPreviewForTimestamp',
  summary: 'Refresh obligations preview',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/obligations/refresh-preview',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'obligations',
    'refresh-preview',
    query,
  ],
})

export type InternalObligationsRefreshKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'obligations',
  'refresh',
  unknown,
]

export type InternalObligationsRefreshResponse = ResponseTypes.Obligations

export type InternalObligationsRefreshVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const internalObligationsRefresh = makeData<
  InternalObligationsRefreshResponse,
  InternalObligationsRefreshKey,
  InternalObligationsRefreshVariables
>({
  method: 'post',
  name: 'internalObligationsRefresh',
  summary: 'Refresh obligations',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/obligations/refresh',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'obligations',
    'refresh',
    query,
  ],
})

export type InternalLoanAgentDashboardToolsGetKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'agent-dashboard-tools',
  unknown,
]

export type InternalLoanAgentDashboardToolsGetResponse = ResponseTypes.AgentDashboardTools

export type InternalLoanAgentDashboardToolsGetVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const internalLoanAgentDashboardToolsGet = makeData<
  InternalLoanAgentDashboardToolsGetResponse,
  InternalLoanAgentDashboardToolsGetKey,
  InternalLoanAgentDashboardToolsGetVariables
>({
  method: 'get',
  name: 'internalLoanAgentDashboardToolsGet',
  summary: 'Get agent dashboard tools',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/agent-dashboard-tools',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'agent-dashboard-tools',
    query,
  ],
})

export type InternalLoanCustomPaymentPlanRefreshKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'custom-payment-plans',
  'refresh',
  unknown,
]

export type InternalLoanCustomPaymentPlanRefreshResponse = void

export type InternalLoanCustomPaymentPlanRefreshVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const internalLoanCustomPaymentPlanRefresh = makeData<
  InternalLoanCustomPaymentPlanRefreshResponse,
  InternalLoanCustomPaymentPlanRefreshKey,
  InternalLoanCustomPaymentPlanRefreshVariables
>({
  method: 'post',
  name: 'internalLoanCustomPaymentPlanRefresh',
  summary: 'Refresh custom payment plan',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/custom-payment-plans/refresh',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'custom-payment-plans',
    'refresh',
    query,
  ],
})

export type ObligationsRefreshKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'obligations',
  'refresh',
  unknown,
]

export type ObligationsRefreshResponse = ResponseTypes.Obligations

export type ObligationsRefreshVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const obligationsRefresh = makeData<
  ObligationsRefreshResponse,
  ObligationsRefreshKey,
  ObligationsRefreshVariables
>({
  method: 'post',
  name: 'obligationsRefresh',
  summary: 'Refresh obligations',
  path: '/people/{personId}/loans/{loanId}/obligations/refresh',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'obligations', 'refresh', query],
})

export type InternalLoanTransactionsGetAllKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'transactions',
  unknown,
]

export type InternalLoanTransactionsGetAllResponse = ResponseTypes.TransactionCollection

export type InternalLoanTransactionsGetAllVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    parentTransactionId?: SchemaTypes.AnyId
    status?: Array<SchemaTypes.TransactionStatusReadOnly>
    isExternal?: boolean
    isVirtual?: boolean
    isInFlightPayment?: boolean
    transactionType?: 'payment' | 'serviceCredit'
    serviceCreditTypes?: Array<SchemaTypes.serviceCreditTypes>
    toEffectiveDate?: string
    fromEffectiveDate?: string
    sortBy?: Array<
      | 'appliedAt'
      | '+appliedAt'
      | '-appliedAt'
      | 'createdAt'
      | '+createdAt'
      | '-createdAt'
      | 'effectiveDate'
      | '+effectiveDate'
      | '-effectiveDate'
    >
  }
}

export const internalLoanTransactionsGetAll = makeData<
  InternalLoanTransactionsGetAllResponse,
  InternalLoanTransactionsGetAllKey,
  InternalLoanTransactionsGetAllVariables
>({
  method: 'get',
  name: 'internalLoanTransactionsGetAll',
  summary: 'Get transactions',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    query,
  ],
})

export type InternalTransactionsCreateKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'transactions',
  unknown,
]

export type InternalTransactionsCreateResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.Transaction
}

export type InternalTransactionsCreateVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: SchemaTypes.CreateTransactionInternal
  query?: {
    sync?: boolean
  }
}

export const internalTransactionsCreate = makeData<
  InternalTransactionsCreateResponse,
  InternalTransactionsCreateKey,
  InternalTransactionsCreateVariables
>({
  method: 'post',
  name: 'internalTransactionsCreate',
  summary: 'Create transaction',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    query,
  ],
})

export type InternalTransactionsGetByIdKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'transactions',
  string | undefined,
  unknown,
]

export type InternalTransactionsGetByIdResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.Transaction
}

export type InternalTransactionsGetByIdVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  transactionId: string | undefined
  body?: void
  query?: void
}

export const internalTransactionsGetById = makeData<
  InternalTransactionsGetByIdResponse,
  InternalTransactionsGetByIdKey,
  InternalTransactionsGetByIdVariables
>({
  method: 'get',
  name: 'internalTransactionsGetById',
  summary: 'Get transaction by ID',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions/{transactionId}',
  queryKey: ({ query, companyId, personId, loanId, transactionId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    query,
  ],
})

export type InternalTransactionsUpdateKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'transactions',
  string | undefined,
  unknown,
]

export type InternalTransactionsUpdateResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.Transaction
}

export type InternalTransactionsUpdateVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  transactionId: string | undefined
  body?: SchemaTypes.TransactionUpdateInternal
  query?: {
    sync?: boolean
  }
}

export const internalTransactionsUpdate = makeData<
  InternalTransactionsUpdateResponse,
  InternalTransactionsUpdateKey,
  InternalTransactionsUpdateVariables
>({
  method: 'put',
  name: 'internalTransactionsUpdate',
  summary: 'Update transaction',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions/{transactionId}',
  queryKey: ({ query, companyId, personId, loanId, transactionId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    query,
  ],
})

export type InternalTransactionCancelKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'transactions',
  string | undefined,
  'cancel',
  unknown,
]

export type InternalTransactionCancelResponse = void

export type InternalTransactionCancelVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  transactionId: string | undefined
  body?: void
  query?: {
    cancelReason?: SchemaTypes.TransactionCancelReason
  }
}

export const internalTransactionCancel = makeData<
  InternalTransactionCancelResponse,
  InternalTransactionCancelKey,
  InternalTransactionCancelVariables
>({
  method: 'post',
  name: 'internalTransactionCancel',
  summary: 'Cancel transaction',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions/{transactionId}/cancel',
  queryKey: ({ query, companyId, personId, loanId, transactionId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'cancel',
    query,
  ],
})

export type InternalTransactionsDeleteChildrenKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'transactions',
  string | undefined,
  'delete-children',
  unknown,
]

export type InternalTransactionsDeleteChildrenResponse = void

export type InternalTransactionsDeleteChildrenVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  transactionId: string | undefined
  body?: void
  query?: void
}

export const internalTransactionsDeleteChildren = makeData<
  InternalTransactionsDeleteChildrenResponse,
  InternalTransactionsDeleteChildrenKey,
  InternalTransactionsDeleteChildrenVariables
>({
  method: 'post',
  name: 'internalTransactionsDeleteChildren',
  summary: 'Delete child virtual transactions',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions/{transactionId}/delete-children',
  queryKey: ({ query, companyId, personId, loanId, transactionId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'delete-children',
    query,
  ],
})

export type InternalTransactionsZeroSplitsKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'transactions',
  string | undefined,
  'zero-splits',
  unknown,
]

export type InternalTransactionsZeroSplitsResponse = void

export type InternalTransactionsZeroSplitsVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  transactionId: string | undefined
  body?: void
  query?: void
}

export const internalTransactionsZeroSplits = makeData<
  InternalTransactionsZeroSplitsResponse,
  InternalTransactionsZeroSplitsKey,
  InternalTransactionsZeroSplitsVariables
>({
  method: 'post',
  name: 'internalTransactionsZeroSplits',
  summary: 'Zero out transaction splits',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions/{transactionId}/zero-splits',
  queryKey: ({ query, companyId, personId, loanId, transactionId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'zero-splits',
    query,
  ],
})

export type InternalTransactionReverseKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'transactions',
  string | undefined,
  'reverse',
  unknown,
]

export type InternalTransactionReverseResponse = void

export type InternalTransactionReverseVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  transactionId: string | undefined
  body?: RequestBodyTypes.ReversalPost
  query?: {
    sync?: boolean
  }
}

export const internalTransactionReverse = makeData<
  InternalTransactionReverseResponse,
  InternalTransactionReverseKey,
  InternalTransactionReverseVariables
>({
  method: 'post',
  name: 'internalTransactionReverse',
  summary: 'Reverse transaction',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions/{transactionId}/reverse',
  queryKey: ({ query, companyId, personId, loanId, transactionId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'reverse',
    query,
  ],
})

export type InternalGetChargebacksKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'transactions',
  string | undefined,
  'chargebacks',
  unknown,
]

export type InternalGetChargebacksResponse = ResponseTypes.Chargebacks

export type InternalGetChargebacksVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  transactionId: string | undefined
  body?: void
  query?: void
}

export const internalGetChargebacks = makeData<
  InternalGetChargebacksResponse,
  InternalGetChargebacksKey,
  InternalGetChargebacksVariables
>({
  method: 'get',
  name: 'internalGetChargebacks',
  summary: 'Get transaction chargebacks',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions/{transactionId}/chargebacks',
  queryKey: ({ query, companyId, personId, loanId, transactionId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'chargebacks',
    query,
  ],
})

export type InternalTransactionChargebackKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'transactions',
  string | undefined,
  'chargebacks',
  unknown,
]

export type InternalTransactionChargebackResponse = void

export type InternalTransactionChargebackVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  transactionId: string | undefined
  body?: RequestBodyTypes.ChargebackPost
  query?: {
    sync?: boolean
  }
}

export const internalTransactionChargeback = makeData<
  InternalTransactionChargebackResponse,
  InternalTransactionChargebackKey,
  InternalTransactionChargebackVariables
>({
  method: 'post',
  name: 'internalTransactionChargeback',
  summary: 'Create transaction chargeback',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions/{transactionId}/chargebacks',
  queryKey: ({ query, companyId, personId, loanId, transactionId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'chargebacks',
    query,
  ],
})

export type InternalGetChargebackByIdKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'transactions',
  string | undefined,
  'chargebacks',
  string | undefined,
  unknown,
]

export type InternalGetChargebackByIdResponse = ResponseTypes.Chargeback

export type InternalGetChargebackByIdVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  transactionId: string | undefined
  chargebackId: string | undefined
  body?: void
  query?: void
}

export const internalGetChargebackById = makeData<
  InternalGetChargebackByIdResponse,
  InternalGetChargebackByIdKey,
  InternalGetChargebackByIdVariables
>({
  method: 'get',
  name: 'internalGetChargebackById',
  summary: 'Get chargeback by ID',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions/{transactionId}/chargebacks/{chargebackId}',
  queryKey: ({ query, companyId, personId, loanId, transactionId, chargebackId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'chargebacks',
    chargebackId,
    query,
  ],
})

export type InternalUpdateChargebackKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'transactions',
  string | undefined,
  'chargebacks',
  string | undefined,
  unknown,
]

export type InternalUpdateChargebackResponse = ResponseTypes.Chargeback

export type InternalUpdateChargebackVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  transactionId: string | undefined
  chargebackId: string | undefined
  body?: RequestBodyTypes.ChargebackPut
  query?: {
    sync?: boolean
  }
}

export const internalUpdateChargeback = makeData<
  InternalUpdateChargebackResponse,
  InternalUpdateChargebackKey,
  InternalUpdateChargebackVariables
>({
  method: 'put',
  name: 'internalUpdateChargeback',
  summary: 'Update chargeback',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions/{transactionId}/chargebacks/{chargebackId}',
  queryKey: ({ query, companyId, personId, loanId, transactionId, chargebackId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'chargebacks',
    chargebackId,
    query,
  ],
})

export type LoanTypeDynamicFeeGetAllKey = [
  'companies',
  string | undefined,
  'loan-types',
  string | undefined,
  'dynamic-fee-types',
  unknown,
]

export type LoanTypeDynamicFeeGetAllResponse = ResponseTypes.DynamicFeeTypePaging

export type LoanTypeDynamicFeeGetAllVariables = {
  companyId: string | undefined
  loanTypeId: string | undefined
  body?: void
  query?: {
    feeType?: 'lateFee' | 'modificationFee' | 'serviceFee'
    sortBy?: Array<string>
    offset?: number
    /**
     * @default
     *     25
     */
    limit?: number
  }
}

export const loanTypeDynamicFeeGetAll = makeData<
  LoanTypeDynamicFeeGetAllResponse,
  LoanTypeDynamicFeeGetAllKey,
  LoanTypeDynamicFeeGetAllVariables
>({
  method: 'get',
  name: 'loanTypeDynamicFeeGetAll',
  summary: 'Get dynamic fees by loan type',
  path: '/companies/{companyId}/loan-types/{loanTypeId}/dynamic-fee-types',
  queryKey: ({ query, companyId, loanTypeId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'dynamic-fee-types',
    query,
  ],
})

export type LoanTypeDynamicFeeTypeAddKey = [
  'companies',
  string | undefined,
  'loan-types',
  string | undefined,
  'dynamic-fee-types',
  unknown,
]

export type LoanTypeDynamicFeeTypeAddResponse = ResponseTypes.DynamicFeeType

export type LoanTypeDynamicFeeTypeAddVariables = {
  companyId: string | undefined
  loanTypeId: string | undefined
  body?: RequestBodyTypes.DynamicFeeTypePost
  query?: void
}

export const loanTypeDynamicFeeTypeAdd = makeData<
  LoanTypeDynamicFeeTypeAddResponse,
  LoanTypeDynamicFeeTypeAddKey,
  LoanTypeDynamicFeeTypeAddVariables
>({
  method: 'post',
  name: 'loanTypeDynamicFeeTypeAdd',
  summary: 'Create dynamic fee type',
  path: '/companies/{companyId}/loan-types/{loanTypeId}/dynamic-fee-types',
  queryKey: ({ query, companyId, loanTypeId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'dynamic-fee-types',
    query,
  ],
})

export type InternalLoanChargeOffKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'charge-off',
  unknown,
]

export type InternalLoanChargeOffResponse = ResponseTypes.LoanChargeOff

export type InternalLoanChargeOffVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanChargeOff
  query?: void
}

export const internalLoanChargeOff = makeData<
  InternalLoanChargeOffResponse,
  InternalLoanChargeOffKey,
  InternalLoanChargeOffVariables
>({
  method: 'post',
  name: 'internalLoanChargeOff',
  summary: 'Charge-off loan',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/charge-off',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'charge-off',
    query,
  ],
})

export type InternalLoanDueDateDeferKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'defer-due-date',
  unknown,
]

export type InternalLoanDueDateDeferResponse = ResponseTypes.LoanDeferDueDate

export type InternalLoanDueDateDeferVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanDeferDueDate
  query?: void
}

export const internalLoanDueDateDefer = makeData<
  InternalLoanDueDateDeferResponse,
  InternalLoanDueDateDeferKey,
  InternalLoanDueDateDeferVariables
>({
  method: 'post',
  name: 'internalLoanDueDateDefer',
  summary: 'Defer single due date',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/defer-due-date',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'defer-due-date',
    query,
  ],
})

export type InternalLoanScheduleDeferKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'defer-schedule',
  unknown,
]

export type InternalLoanScheduleDeferResponse = ResponseTypes.LoanDeferSchedule

export type InternalLoanScheduleDeferVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanDeferSchedule
  query?: void
}

export const internalLoanScheduleDefer = makeData<
  InternalLoanScheduleDeferResponse,
  InternalLoanScheduleDeferKey,
  InternalLoanScheduleDeferVariables
>({
  method: 'post',
  name: 'internalLoanScheduleDefer',
  summary: 'Defer schedule',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/defer-schedule',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'defer-schedule',
    query,
  ],
})

export type InternalLoanChangeTermsScraKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'payment-plan-scra',
  unknown,
]

export type InternalLoanChangeTermsScraResponse = ResponseTypes.ChangeScheduleSCRA

export type InternalLoanChangeTermsScraVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanChangeLoanTermsSCRA
  query?: {
    sync?: boolean
  }
}

export const internalLoanChangeTermsScra = makeData<
  InternalLoanChangeTermsScraResponse,
  InternalLoanChangeTermsScraKey,
  InternalLoanChangeTermsScraVariables
>({
  method: 'post',
  name: 'internalLoanChangeTermsScra',
  summary: 'Change loan terms SCRA',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/payment-plan-scra',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'payment-plan-scra',
    query,
  ],
})

export type InternalLoanAccelerateKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'accelerate',
  unknown,
]

export type InternalLoanAccelerateResponse = ResponseTypes.LoanAccelerate

export type InternalLoanAccelerateVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanAccelerate
  query?: void
}

export const internalLoanAccelerate = makeData<
  InternalLoanAccelerateResponse,
  InternalLoanAccelerateKey,
  InternalLoanAccelerateVariables
>({
  method: 'post',
  name: 'internalLoanAccelerate',
  summary: 'Accelerate loan',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/accelerate',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'accelerate',
    query,
  ],
})

export type InternalLoanRefreshKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'refresh',
  unknown,
]

export type InternalLoanRefreshResponse = void

export type InternalLoanRefreshVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    isPostReplay?: boolean
    replayFrom?: string
  }
}

export const internalLoanRefresh = makeData<
  InternalLoanRefreshResponse,
  InternalLoanRefreshKey,
  InternalLoanRefreshVariables
>({
  method: 'post',
  name: 'internalLoanRefresh',
  summary: 'Refresh loan',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/refresh',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'refresh',
    query,
  ],
})

export type InternalLoanScheduleOptionsGetKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'schedule-options',
  unknown,
]

export type InternalLoanScheduleOptionsGetResponse = ResponseTypes.LoanScheduleOptions

export type InternalLoanScheduleOptionsGetVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    isAutopay?: boolean
  }
}

export const internalLoanScheduleOptionsGet = makeData<
  InternalLoanScheduleOptionsGetResponse,
  InternalLoanScheduleOptionsGetKey,
  InternalLoanScheduleOptionsGetVariables
>({
  method: 'get',
  name: 'internalLoanScheduleOptionsGet',
  summary: 'Get loan schedule options',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/schedule-options',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'schedule-options',
    query,
  ],
})

export type LoanTypeDynamicFeeTypeGetKey = [
  'companies',
  string | undefined,
  'loan-types',
  string | undefined,
  'dynamic-fee-types',
  string | undefined,
  unknown,
]

export type LoanTypeDynamicFeeTypeGetResponse = ResponseTypes.DynamicFeeType

export type LoanTypeDynamicFeeTypeGetVariables = {
  companyId: string | undefined
  loanTypeId: string | undefined
  dynamicFeeTypeId: string | undefined
  body?: void
  query?: void
}

export const loanTypeDynamicFeeTypeGet = makeData<
  LoanTypeDynamicFeeTypeGetResponse,
  LoanTypeDynamicFeeTypeGetKey,
  LoanTypeDynamicFeeTypeGetVariables
>({
  method: 'get',
  name: 'loanTypeDynamicFeeTypeGet',
  summary: 'Get dynamic fee type by ID',
  path: '/companies/{companyId}/loan-types/{loanTypeId}/dynamic-fee-types/{dynamicFeeTypeId}',
  queryKey: ({ query, companyId, loanTypeId, dynamicFeeTypeId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'dynamic-fee-types',
    dynamicFeeTypeId,
    query,
  ],
})

export type InternalLoanLateFeeChargeKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'charge-late-fee',
  unknown,
]

export type InternalLoanLateFeeChargeResponse = void

export type InternalLoanLateFeeChargeVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    sync?: boolean
  }
}

export const internalLoanLateFeeCharge = makeData<
  InternalLoanLateFeeChargeResponse,
  InternalLoanLateFeeChargeKey,
  InternalLoanLateFeeChargeVariables
>({
  method: 'post',
  name: 'internalLoanLateFeeCharge',
  summary: 'Charge late fee',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/charge-late-fee',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'charge-late-fee',
    query,
  ],
})

export type InternalLoanServiceFeeChargeKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'charge-service-fee',
  unknown,
]

export type InternalLoanServiceFeeChargeResponse = void

export type InternalLoanServiceFeeChargeVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    sync?: boolean
  }
}

export const internalLoanServiceFeeCharge = makeData<
  InternalLoanServiceFeeChargeResponse,
  InternalLoanServiceFeeChargeKey,
  InternalLoanServiceFeeChargeVariables
>({
  method: 'post',
  name: 'internalLoanServiceFeeCharge',
  summary: 'Charge service fee',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/charge-service-fee',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'charge-service-fee',
    query,
  ],
})

export type InternalLoanNsfFeesChargeKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'charge-nsf-fees',
  unknown,
]

export type InternalLoanNsfFeesChargeResponse = void

export type InternalLoanNsfFeesChargeVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: {
    effectiveAt?: string
  }
  query?: void
}

export const internalLoanNsfFeesCharge = makeData<
  InternalLoanNsfFeesChargeResponse,
  InternalLoanNsfFeesChargeKey,
  InternalLoanNsfFeesChargeVariables
>({
  method: 'post',
  name: 'internalLoanNsfFeesCharge',
  summary: 'Charge service fee',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/charge-nsf-fees',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'charge-nsf-fees',
    query,
  ],
})

export type InternalFeeTypesGetKey = [
  'companies',
  string | undefined,
  'loan-types',
  string | undefined,
  'fee-types',
  unknown,
]

export type InternalFeeTypesGetResponse = UtilityTypes.SingletonResponse & {
  data?: Array<{
    apiName?: SchemaTypes.FeeApiName
    displayName?: SchemaTypes.FeeDisplayName
  }>
}

export type InternalFeeTypesGetVariables = {
  companyId: string | undefined
  loanTypeId: string | undefined
  body?: void
  query?: void
}

export const internalFeeTypesGet = makeData<
  InternalFeeTypesGetResponse,
  InternalFeeTypesGetKey,
  InternalFeeTypesGetVariables
>({
  method: 'get',
  name: 'internalFeeTypesGet',
  summary: 'Get fee types',
  path: '/companies/{companyId}/loan-types/{loanTypeId}/fee-types',
  queryKey: ({ query, companyId, loanTypeId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'fee-types',
    query,
  ],
})

export type InternalDynamicFeeGetKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'fees',
  string | undefined,
  unknown,
]

export type InternalDynamicFeeGetResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.LoanFee
}

export type InternalDynamicFeeGetVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  loanFeeId: string | undefined
  body?: void
  query?: void
}

export const internalDynamicFeeGet = makeData<
  InternalDynamicFeeGetResponse,
  InternalDynamicFeeGetKey,
  InternalDynamicFeeGetVariables
>({
  method: 'get',
  name: 'internalDynamicFeeGet',
  summary: 'Get dynamic fee by ID',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/fees/{loanFeeId}',
  queryKey: ({ query, companyId, personId, loanId, loanFeeId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'fees',
    loanFeeId,
    query,
  ],
})

export type InternalDynamicFeeUpdateKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'fees',
  string | undefined,
  unknown,
]

export type InternalDynamicFeeUpdateResponse = void

export type InternalDynamicFeeUpdateVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  loanFeeId: string | undefined
  body?: {
    customDisplayName?: /**
     * A custom description of the fee. If provided, this value will be displayed in Peach UI/App and Statements
     * (or can be used by lender in their App). If the value is not provided, the Peach UI/App and Statements
     * logic will use displayName attribute. To remind, the displayName is populated from the fee type configuration object.
     */
    | string /**
       * A custom description of the fee. If provided, this value will be displayed in Peach UI/App and Statements
       * (or can be used by lender in their App). If the value is not provided, the Peach UI/App and Statements
       * logic will use displayName attribute. To remind, the displayName is populated from the fee type configuration object.
       */
      | null
  }
  query?: void
}

export const internalDynamicFeeUpdate = makeData<
  InternalDynamicFeeUpdateResponse,
  InternalDynamicFeeUpdateKey,
  InternalDynamicFeeUpdateVariables
>({
  method: 'put',
  name: 'internalDynamicFeeUpdate',
  summary: 'Update dynamic fee',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/fees/{loanFeeId}',
  queryKey: ({ query, companyId, personId, loanId, loanFeeId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'fees',
    loanFeeId,
    query,
  ],
})

export type InternalMarkFeeBookedKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'fees',
  string | undefined,
  'mark-fee-booked',
  unknown,
]

export type InternalMarkFeeBookedResponse = {
  data?: Array<SchemaTypes.LoanFee>
}

export type InternalMarkFeeBookedVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  loanFeeId: string | undefined
  body?: void
  query?: void
}

export const internalMarkFeeBooked = makeData<
  InternalMarkFeeBookedResponse,
  InternalMarkFeeBookedKey,
  InternalMarkFeeBookedVariables
>({
  method: 'post',
  name: 'internalMarkFeeBooked',
  summary: 'Mark fee booked',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/fees/{loanFeeId}/mark-fee-booked',
  queryKey: ({ query, companyId, personId, loanId, loanFeeId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'fees',
    loanFeeId,
    'mark-fee-booked',
    query,
  ],
})

export type InternalFeesGetKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'fees',
  unknown,
]

export type InternalFeesGetResponse = UtilityTypes.NewPaginator & {
  data?: Array<SchemaTypes.LoanFee>
}

export type InternalFeesGetVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     true
     */
    includeDraws?: boolean
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const internalFeesGet = makeData<InternalFeesGetResponse, InternalFeesGetKey, InternalFeesGetVariables>({
  method: 'get',
  name: 'internalFeesGet',
  summary: 'Get loan fees',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/fees',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'fees',
    query,
  ],
})

export type InternalFeesPostKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'fees',
  unknown,
]

export type InternalFeesPostResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.LoanFee
}

export type InternalFeesPostVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.FeesPost
  query?: {
    sync?: boolean
  }
}

export const internalFeesPost = makeData<InternalFeesPostResponse, InternalFeesPostKey, InternalFeesPostVariables>({
  method: 'post',
  name: 'internalFeesPost',
  summary: 'Create dynamic fee',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/fees',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'fees',
    query,
  ],
})

export type InternalFeesCancelOneKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'fees',
  string | undefined,
  'cancel',
  unknown,
]

export type InternalFeesCancelOneResponse = void

export type InternalFeesCancelOneVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  loanFeeId: string | undefined
  body?: {
    /** An identifier for an existing case. */
    caseId?: string
  }
  query?: void
}

export const internalFeesCancelOne = makeData<
  InternalFeesCancelOneResponse,
  InternalFeesCancelOneKey,
  InternalFeesCancelOneVariables
>({
  method: 'post',
  name: 'internalFeesCancelOne',
  summary: 'Cancel dynamic fee',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/fees/{loanFeeId}/cancel',
  queryKey: ({ query, companyId, personId, loanId, loanFeeId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'fees',
    loanFeeId,
    'cancel',
    query,
  ],
})

export type InternalFeesCancelKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'cancel-fees',
  unknown,
]

export type InternalFeesCancelResponse = void

export type InternalFeesCancelVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: {
    loanFeeIds?: Array<string>
    /** An identifier for an existing case. */
    caseId?: string
  }
  query?: void
}

export const internalFeesCancel = makeData<
  InternalFeesCancelResponse,
  InternalFeesCancelKey,
  InternalFeesCancelVariables
>({
  method: 'post',
  name: 'internalFeesCancel',
  summary: 'Cancel dynamic fees',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/cancel-fees',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'cancel-fees',
    query,
  ],
})

export type InternalLoanAccrueKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'accrue',
  unknown,
]

export type InternalLoanAccrueResponse = void

export type InternalLoanAccrueVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanAccrue
  query?: void
}

export const internalLoanAccrue = makeData<
  InternalLoanAccrueResponse,
  InternalLoanAccrueKey,
  InternalLoanAccrueVariables
>({
  method: 'post',
  name: 'internalLoanAccrue',
  summary: 'Accrue daily interest',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/accrue',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'accrue',
    query,
  ],
})

export type LoanPayoffKey = ['people', string | undefined, 'loans', string | undefined, 'payoff', unknown]

export type LoanPayoffResponse = void

export type LoanPayoffVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const loanPayoff = makeData<LoanPayoffResponse, LoanPayoffKey, LoanPayoffVariables>({
  method: 'post',
  name: 'loanPayoff',
  summary: 'Update loan status to paidOff status based on the outstanding balance',
  path: '/people/{personId}/loans/{loanId}/payoff',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'payoff', query],
})

export type InternalLoanPayoffKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'payoff',
  unknown,
]

export type InternalLoanPayoffResponse = void

export type InternalLoanPayoffVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const internalLoanPayoff = makeData<
  InternalLoanPayoffResponse,
  InternalLoanPayoffKey,
  InternalLoanPayoffVariables
>({
  method: 'post',
  name: 'internalLoanPayoff',
  summary: 'Update loan status to paidOff status based on the outstanding balance',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/payoff',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'payoff',
    query,
  ],
})

export type InternalLoanPayoffReverseKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'reverse-payoff',
  unknown,
]

export type InternalLoanPayoffReverseResponse = void

export type InternalLoanPayoffReverseVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const internalLoanPayoffReverse = makeData<
  InternalLoanPayoffReverseResponse,
  InternalLoanPayoffReverseKey,
  InternalLoanPayoffReverseVariables
>({
  method: 'post',
  name: 'internalLoanPayoffReverse',
  summary: 'Update loan status to a non paidOff status based on the outstanding balance',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/reverse-payoff',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'reverse-payoff',
    query,
  ],
})

export type LoanPayoffReverseKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'reverse-payoff',
  unknown,
]

export type LoanPayoffReverseResponse = void

export type LoanPayoffReverseVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const loanPayoffReverse = makeData<LoanPayoffReverseResponse, LoanPayoffReverseKey, LoanPayoffReverseVariables>({
  method: 'post',
  name: 'loanPayoffReverse',
  summary: 'Update loan status to a non paidOff status based on the outstanding balance',
  path: '/people/{personId}/loans/{loanId}/reverse-payoff',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'reverse-payoff', query],
})

export type InternalLoanExpectedPaymentsRefreshKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'expected-payments',
  'refresh',
  unknown,
]

export type InternalLoanExpectedPaymentsRefreshResponse = ResponseTypes.ExpectedPayments

export type InternalLoanExpectedPaymentsRefreshVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    isPostReplay?: boolean
  }
}

export const internalLoanExpectedPaymentsRefresh = makeData<
  InternalLoanExpectedPaymentsRefreshResponse,
  InternalLoanExpectedPaymentsRefreshKey,
  InternalLoanExpectedPaymentsRefreshVariables
>({
  method: 'post',
  name: 'internalLoanExpectedPaymentsRefresh',
  summary: 'Refresh expected payments',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/expected-payments/refresh',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'expected-payments',
    'refresh',
    query,
  ],
})

export type LoanExpectedPaymentsRefreshKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'expected-payments',
  'refresh',
  unknown,
]

export type LoanExpectedPaymentsRefreshResponse = ResponseTypes.ExpectedPayments

export type LoanExpectedPaymentsRefreshVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const loanExpectedPaymentsRefresh = makeData<
  LoanExpectedPaymentsRefreshResponse,
  LoanExpectedPaymentsRefreshKey,
  LoanExpectedPaymentsRefreshVariables
>({
  method: 'post',
  name: 'loanExpectedPaymentsRefresh',
  summary: 'Refresh expected payments',
  path: '/people/{personId}/loans/{loanId}/expected-payments/refresh',
  queryKey: ({ query, personId, loanId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'expected-payments',
    'refresh',
    query,
  ],
})

export type InternalLoanExpectedPaymentOverrideKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'expected-payments',
  'override',
  unknown,
]

export type InternalLoanExpectedPaymentOverrideResponse = void

export type InternalLoanExpectedPaymentOverrideVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: {
    capitalizedAmount?: /** Amount to of the expected payment amount that was capitalized */
    string /** Amount to of the expected payment amount that was capitalized */ | null
    overpaymentAdjustmentAmount?: /** Amount to subtract from the original amount since it got paid by the depletion of the overpayment on the LOC */
    | string /** Amount to subtract from the original amount since it got paid by the depletion of the overpayment on the LOC */
      | null
    /** ID of expected payment that needs updating */
    expectedPaymentId?: string
    isDrawFee?: boolean
  }
  query?: void
}

export const internalLoanExpectedPaymentOverride = makeData<
  InternalLoanExpectedPaymentOverrideResponse,
  InternalLoanExpectedPaymentOverrideKey,
  InternalLoanExpectedPaymentOverrideVariables
>({
  method: 'put',
  name: 'internalLoanExpectedPaymentOverride',
  summary:
    'Update an expected payment. Currently used internally for only a few narrow use cases:   - updating the expected payment in the case of overpayment adjustment   - updating the expected payment to zero in the case of fee capitalization',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/expected-payments/override',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'expected-payments',
    'override',
    query,
  ],
})

export type InternalFeeAdjustForLocKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'adjust-fees',
  unknown,
]

export type InternalFeeAdjustForLocResponse = void

export type InternalFeeAdjustForLocVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const internalFeeAdjustForLoc = makeData<
  InternalFeeAdjustForLocResponse,
  InternalFeeAdjustForLocKey,
  InternalFeeAdjustForLocVariables
>({
  method: 'post',
  name: 'internalFeeAdjustForLoc',
  summary: 'Adjust fees for LOC',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/adjust-fees',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'adjust-fees',
    query,
  ],
})

export type InternalFinalizeMinPaymentsForLocKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'min-payment',
  unknown,
]

export type InternalFinalizeMinPaymentsForLocResponse = void

export type InternalFinalizeMinPaymentsForLocVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    isReplay?: boolean
  }
}

export const internalFinalizeMinPaymentsForLoc = makeData<
  InternalFinalizeMinPaymentsForLocResponse,
  InternalFinalizeMinPaymentsForLocKey,
  InternalFinalizeMinPaymentsForLocVariables
>({
  method: 'post',
  name: 'internalFinalizeMinPaymentsForLoc',
  summary: 'Finalise min payments for LOC',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/min-payment',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'min-payment',
    query,
  ],
})

export type InternalLoanExpectedPaymentsOverLimitKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'expected-payments',
  'over-limit',
  unknown,
]

export type InternalLoanExpectedPaymentsOverLimitResponse = ResponseTypes.OverLimitResponse

export type InternalLoanExpectedPaymentsOverLimitVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.OverLimitPost
  query?: void
}

export const internalLoanExpectedPaymentsOverLimit = makeData<
  InternalLoanExpectedPaymentsOverLimitResponse,
  InternalLoanExpectedPaymentsOverLimitKey,
  InternalLoanExpectedPaymentsOverLimitVariables
>({
  method: 'post',
  name: 'internalLoanExpectedPaymentsOverLimit',
  summary: 'Create OverLimitPayment',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/expected-payments/over-limit',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'expected-payments',
    'over-limit',
    query,
  ],
})

export type EmitScheduledInteractionEventsKey = [
  'rpc',
  'companies',
  string | undefined,
  'emit-scheduled-interaction-events',
  unknown,
]

export type EmitScheduledInteractionEventsResponse = void

export type EmitScheduledInteractionEventsVariables = {
  companyId: string | undefined
  body?: void
  query?: void
}

export const emitScheduledInteractionEvents = makeData<
  EmitScheduledInteractionEventsResponse,
  EmitScheduledInteractionEventsKey,
  EmitScheduledInteractionEventsVariables
>({
  method: 'post',
  name: 'emitScheduledInteractionEvents',
  summary: 'Emit events for scheduled interactions',
  path: '/rpc/companies/{companyId}/emit-scheduled-interaction-events',
  queryKey: ({ query, companyId }) => ['rpc', 'companies', companyId, 'emit-scheduled-interaction-events', query],
})

export type EmitExpiredCaseSnoozeEventsKey = [
  'rpc',
  'companies',
  string | undefined,
  'emit-expired-case-snooze-events',
  unknown,
]

export type EmitExpiredCaseSnoozeEventsResponse = void

export type EmitExpiredCaseSnoozeEventsVariables = {
  companyId: string | undefined
  body?: void
  query?: void
}

export const emitExpiredCaseSnoozeEvents = makeData<
  EmitExpiredCaseSnoozeEventsResponse,
  EmitExpiredCaseSnoozeEventsKey,
  EmitExpiredCaseSnoozeEventsVariables
>({
  method: 'post',
  name: 'emitExpiredCaseSnoozeEvents',
  summary: 'Emit events for snoozed cases',
  path: '/rpc/companies/{companyId}/emit-expired-case-snooze-events',
  queryKey: ({ query, companyId }) => ['rpc', 'companies', companyId, 'emit-expired-case-snooze-events', query],
})

export type EmitExpiredCaseDniEventsKey = [
  'rpc',
  'companies',
  string | undefined,
  'emit-expired-case-dni-events',
  unknown,
]

export type EmitExpiredCaseDniEventsResponse = void

export type EmitExpiredCaseDniEventsVariables = {
  companyId: string | undefined
  body?: void
  query?: void
}

export const emitExpiredCaseDniEvents = makeData<
  EmitExpiredCaseDniEventsResponse,
  EmitExpiredCaseDniEventsKey,
  EmitExpiredCaseDniEventsVariables
>({
  method: 'post',
  name: 'emitExpiredCaseDniEvents',
  summary: 'Emit events for expired DNIs',
  path: '/rpc/companies/{companyId}/emit-expired-case-dni-events',
  queryKey: ({ query, companyId }) => ['rpc', 'companies', companyId, 'emit-expired-case-dni-events', query],
})

export type ServicesGetAllKey = ['companies', string | undefined, 'monitoring', 'services', unknown]

export type ServicesGetAllResponse = ResponseTypes.MonitoringServicesPaging

export type ServicesGetAllVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    serviceTypes?: Array<'bankruptcy' | 'deceased' | 'scra' | 'fema' | 'ofac'>
  }
}

export const servicesGetAll = makeData<ServicesGetAllResponse, ServicesGetAllKey, ServicesGetAllVariables>({
  method: 'get',
  name: 'servicesGetAll',
  summary: 'Get all monitoring services',
  path: '/companies/{companyId}/monitoring/services',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'monitoring', 'services', query],
})

export type ServiceCreateKey = ['companies', string | undefined, 'monitoring', 'services', unknown]

export type ServiceCreateResponse = ResponseTypes.Service

export type ServiceCreateVariables = {
  companyId: string | undefined
  body?: RequestBodyTypes.ServicePost
  query?: void
}

export const serviceCreate = makeData<ServiceCreateResponse, ServiceCreateKey, ServiceCreateVariables>({
  method: 'post',
  name: 'serviceCreate',
  summary: 'Create a monitoring service.',
  path: '/companies/{companyId}/monitoring/services',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'monitoring', 'services', query],
})

export type ServiceCreateBulkKey = ['companies', string | undefined, 'monitoring', 'services', 'bulk', unknown]

export type ServiceCreateBulkResponse = ResponseTypes.ServiceBulkResponse

export type ServiceCreateBulkVariables = {
  companyId: string | undefined
  body?: Array<SchemaTypes.Service>
  query?: void
}

export const serviceCreateBulk = makeData<ServiceCreateBulkResponse, ServiceCreateBulkKey, ServiceCreateBulkVariables>({
  method: 'post',
  name: 'serviceCreateBulk',
  summary: 'Create multiple monitoring services.',
  path: '/companies/{companyId}/monitoring/services/bulk',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'monitoring', 'services', 'bulk', query],
})

export type ServicesGetKey = ['companies', string | undefined, 'monitoring', 'services', string | undefined, unknown]

export type ServicesGetResponse = ResponseTypes.Service

export type ServicesGetVariables = {
  companyId: string | undefined
  serviceId: string | undefined
  body?: void
  query?: void
}

export const servicesGet = makeData<ServicesGetResponse, ServicesGetKey, ServicesGetVariables>({
  method: 'get',
  name: 'servicesGet',
  summary: 'Get a specific monitoring service',
  path: '/companies/{companyId}/monitoring/services/{serviceId}',
  queryKey: ({ query, companyId, serviceId }) => ['companies', companyId, 'monitoring', 'services', serviceId, query],
})

export type DueStatusesKey = [
  'companies',
  string | undefined,
  'monitoring',
  'services',
  string | undefined,
  'due-statuses',
  unknown,
]

export type DueStatusesResponse = ResponseTypes.MonitoringStatusPaging

export type DueStatusesVariables = {
  companyId: string | undefined
  serviceId: string | undefined
  body?: void
  query?: {
    borrowerType?: 'business' | 'person'
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
  }
}

export const dueStatuses = makeData<DueStatusesResponse, DueStatusesKey, DueStatusesVariables>({
  method: 'get',
  name: 'dueStatuses',
  summary: 'Get due monitoring statuses. For internal use only!',
  path: '/companies/{companyId}/monitoring/services/{serviceId}/due-statuses',
  queryKey: ({ query, companyId, serviceId }) => [
    'companies',
    companyId,
    'monitoring',
    'services',
    serviceId,
    'due-statuses',
    query,
  ],
})

export type AllStatusesKey = ['monitoring-status', unknown]

export type AllStatusesResponse = ResponseTypes.MonitoringStatusPaging

export type AllStatusesVariables = {
  body?: void
  query?: {
    monitoringStartedAt?: any
    monitoringEndedAt?: string
    monitoringStoppedAt?: string
    monitoringLastUpdatedAt?: string
    serviceType?: 'bankruptcy' | 'deceased' | 'scra' | 'fema' | 'ofac'
    serviceTypes?: Array<'bankruptcy' | 'deceased' | 'scra' | 'fema' | 'ofac'>
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    companyId?: string
    allStatuses?: boolean | null
    borrowerType?: 'business' | 'person'
  }
}

export const allStatuses = makeData<AllStatusesResponse, AllStatusesKey, AllStatusesVariables>({
  method: 'get',
  name: 'allStatuses',
  summary: 'Get all monitoring statuses irrespective of company or person, for internal use only!',
  path: '/monitoring-status',
  queryKey: ({ query }) => ['monitoring-status', query],
})

export type UpdateStatusesKey = ['monitoring-status', unknown]

export type UpdateStatusesResponse = ResponseTypes.Status

export type UpdateStatusesVariables = {
  body?: void
  query?: void
}

export const updateStatuses = makeData<UpdateStatusesResponse, UpdateStatusesKey, UpdateStatusesVariables>({
  method: 'patch',
  name: 'updateStatuses',
  summary: 'Update multiple monitoring statuses irrespective of company or person, for internal use only!',
  path: '/monitoring-status',
  queryKey: ({ query }) => ['monitoring-status', query],
})

export type InternalSnoozeCaseKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'cases',
  string | undefined,
  'snooze',
  unknown,
]

export type InternalSnoozeCaseResponse = void

export type InternalSnoozeCaseVariables = {
  companyId: string | undefined
  personId: string | undefined
  caseId: string | undefined
  body?: {
    snoozeFor: /** The number of days to snooze for. To cancel snoozing set to `null`. */
    number /** The number of days to snooze for. To cancel snoozing set to `null`. */ | null
  }
  query?: void
}

export const internalSnoozeCase = makeData<
  InternalSnoozeCaseResponse,
  InternalSnoozeCaseKey,
  InternalSnoozeCaseVariables
>({
  method: 'post',
  name: 'internalSnoozeCase',
  summary: 'Snooze case',
  path: '/companies/{companyId}/people/{personId}/cases/{caseId}/snooze',
  queryKey: ({ query, companyId, personId, caseId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'cases',
    caseId,
    'snooze',
    query,
  ],
})

export type GetAddressesInFipsCodeKey = [
  'uscounties',
  string | undefined,
  string | undefined,
  'addressesinCounty',
  unknown,
]

export type GetAddressesInFipsCodeResponse = ContactTypes.ResponseContactInformationPaging

export type GetAddressesInFipsCodeVariables = {
  state: string | undefined
  fipsCode: string | undefined
  body?: void
  query?: {
    companyId?: string
  }
}

export const getAddressesInFipsCode = makeData<
  GetAddressesInFipsCodeResponse,
  GetAddressesInFipsCodeKey,
  GetAddressesInFipsCodeVariables
>({
  method: 'get',
  name: 'getAddressesInFipsCode',
  summary: 'Get all addresses within a given fips code for a given company, for internal use only!',
  path: '/uscounties/{state}/{fipsCode}/addressesinCounty',
  queryKey: ({ query, state, fipsCode }) => ['uscounties', state, fipsCode, 'addressesinCounty', query],
})

export type InternalStatusGetAllKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'monitoring-status',
  unknown,
]

export type InternalStatusGetAllResponse = ResponseTypes.MonitoringStatusPaging

export type InternalStatusGetAllVariables = {
  companyId: string | undefined
  personId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    serviceId?: string
  }
}

export const internalStatusGetAll = makeData<
  InternalStatusGetAllResponse,
  InternalStatusGetAllKey,
  InternalStatusGetAllVariables
>({
  method: 'get',
  name: 'internalStatusGetAll',
  summary: 'Get all monitoring statuses',
  path: '/companies/{companyId}/people/{personId}/monitoring-status',
  queryKey: ({ query, companyId, personId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'monitoring-status',
    query,
  ],
})

export type InternalStatusUpdateKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'monitoring-status',
  string | undefined,
  unknown,
]

export type InternalStatusUpdateResponse = ResponseTypes.Status

export type InternalStatusUpdateVariables = {
  companyId: string | undefined
  personId: string | undefined
  statusId: string | undefined
  body?: RequestBodyTypes.StatusPut
  query?: void
}

export const internalStatusUpdate = makeData<
  InternalStatusUpdateResponse,
  InternalStatusUpdateKey,
  InternalStatusUpdateVariables
>({
  method: 'put',
  name: 'internalStatusUpdate',
  summary: 'Update a specific monitoring status',
  path: '/companies/{companyId}/people/{personId}/monitoring-status/{statusId}',
  queryKey: ({ query, companyId, personId, statusId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'monitoring-status',
    statusId,
    query,
  ],
})

export type StatusGetAllKey = ['people', string | undefined, 'monitoring-status', unknown]

export type StatusGetAllResponse = ResponseTypes.MonitoringStatusPaging

export type StatusGetAllVariables = {
  personId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    serviceId?: string
  }
}

export const statusGetAll = makeData<StatusGetAllResponse, StatusGetAllKey, StatusGetAllVariables>({
  method: 'get',
  name: 'statusGetAll',
  summary: 'Get all monitoring statuses',
  path: '/people/{personId}/monitoring-status',
  queryKey: ({ query, personId }) => ['people', personId, 'monitoring-status', query],
})

export type StatusCreateKey = ['people', string | undefined, 'monitoring-status', unknown]

export type StatusCreateResponse = ResponseTypes.Status

export type StatusCreateVariables = {
  personId: string | undefined
  body?: RequestBodyTypes.StatusPost
  query?: void
}

export const statusCreate = makeData<StatusCreateResponse, StatusCreateKey, StatusCreateVariables>({
  method: 'post',
  name: 'statusCreate',
  summary: 'Create a monitoring status.',
  path: '/people/{personId}/monitoring-status',
  queryKey: ({ query, personId }) => ['people', personId, 'monitoring-status', query],
})

export type StatusGetKey = ['people', string | undefined, 'monitoring-status', string | undefined, unknown]

export type StatusGetResponse = ResponseTypes.Status

export type StatusGetVariables = {
  personId: string | undefined
  statusId: string | undefined
  body?: void
  query?: void
}

export const statusGet = makeData<StatusGetResponse, StatusGetKey, StatusGetVariables>({
  method: 'get',
  name: 'statusGet',
  summary: 'Get a specific monitoring status',
  path: '/people/{personId}/monitoring-status/{statusId}',
  queryKey: ({ query, personId, statusId }) => ['people', personId, 'monitoring-status', statusId, query],
})

export type StatusUpdateKey = ['people', string | undefined, 'monitoring-status', string | undefined, unknown]

export type StatusUpdateResponse = ResponseTypes.Status

export type StatusUpdateVariables = {
  personId: string | undefined
  statusId: string | undefined
  body?: RequestBodyTypes.StatusPut
  query?: void
}

export const statusUpdate = makeData<StatusUpdateResponse, StatusUpdateKey, StatusUpdateVariables>({
  method: 'put',
  name: 'statusUpdate',
  summary: 'Update a specific monitoring status',
  path: '/people/{personId}/monitoring-status/{statusId}',
  queryKey: ({ query, personId, statusId }) => ['people', personId, 'monitoring-status', statusId, query],
})

export type GetAllDisastersKey = ['disasters', unknown]

export type GetAllDisastersResponse = ResponseTypes.DisasterPaging

export type GetAllDisastersVariables = {
  body?: void
  query?: {
    femaId?: string
  }
}

export const getAllDisasters = makeData<GetAllDisastersResponse, GetAllDisastersKey, GetAllDisastersVariables>({
  method: 'get',
  name: 'getAllDisasters',
  summary: 'Get all FEMA Disasters.',
  path: '/disasters',
  queryKey: ({ query }) => ['disasters', query],
})

export type CreateDisasterKey = ['disasters', unknown]

export type CreateDisasterResponse = ResponseTypes.Disaster

export type CreateDisasterVariables = {
  body?: RequestBodyTypes.DisasterPost
  query?: void
}

export const createDisaster = makeData<CreateDisasterResponse, CreateDisasterKey, CreateDisasterVariables>({
  method: 'post',
  name: 'createDisaster',
  summary: 'Create a FEMA Disaster.',
  path: '/disasters',
  queryKey: ({ query }) => ['disasters', query],
})

export type GetDisasterImpactedAreasCsvKey = ['disasters', string | undefined, 'impacted-areas.csv', unknown]

export type GetDisasterImpactedAreasCsvResponse = void

export type GetDisasterImpactedAreasCsvVariables = {
  disasterNumber: string | undefined
  body?: void
  query?: void
}

export const getDisasterImpactedAreasCsv = makeData<
  GetDisasterImpactedAreasCsvResponse,
  GetDisasterImpactedAreasCsvKey,
  GetDisasterImpactedAreasCsvVariables
>({
  method: 'get',
  name: 'getDisasterImpactedAreasCsv',
  summary: 'Get disaster impacted area information in CSV format.',
  path: '/disasters/{disasterNumber}/impacted-areas.csv',
  queryKey: ({ query, disasterNumber }) => ['disasters', disasterNumber, 'impacted-areas.csv', query],
})

export type FindNewUpdatedDisastersKey = ['disasters', 'new-updated', unknown]

export type FindNewUpdatedDisastersResponse = ResponseTypes.DisasterPaging

export type FindNewUpdatedDisastersVariables = {
  body?: RequestBodyTypes.DisasterListPost
  query?: {
    createSupercases?: boolean
  }
}

export const findNewUpdatedDisasters = makeData<
  FindNewUpdatedDisastersResponse,
  FindNewUpdatedDisastersKey,
  FindNewUpdatedDisastersVariables
>({
  method: 'post',
  name: 'findNewUpdatedDisasters',
  summary: 'Update FEMA Disasters and returns new ones that should be created.',
  path: '/disasters/new-updated',
  queryKey: ({ query }) => ['disasters', 'new-updated', query],
})

export type CompanyRoleGetAllKey = ['companies', string | undefined, 'roles', unknown]

export type CompanyRoleGetAllResponse = ResponsesRoleTypes.RolesPaging

export type CompanyRoleGetAllVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const companyRoleGetAll = makeData<CompanyRoleGetAllResponse, CompanyRoleGetAllKey, CompanyRoleGetAllVariables>({
  method: 'get',
  name: 'companyRoleGetAll',
  summary: 'Retrieve all company roles',
  path: '/companies/{companyId}/roles',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'roles', query],
})

export type CompanyRoleAddKey = ['companies', string | undefined, 'roles', unknown]

export type CompanyRoleAddResponse = ResponsesRoleTypes.Role

export type CompanyRoleAddVariables = {
  companyId: string | undefined
  body?: SchemasRoleTypes.RolePost
  query?: void
}

export const companyRoleAdd = makeData<CompanyRoleAddResponse, CompanyRoleAddKey, CompanyRoleAddVariables>({
  method: 'post',
  name: 'companyRoleAdd',
  summary: 'Add a company role',
  path: '/companies/{companyId}/roles',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'roles', query],
})

export type CompanyRoleGetKey = ['companies', string | undefined, 'roles', string | undefined, unknown]

export type CompanyRoleGetResponse = ResponsesRoleTypes.Role

export type CompanyRoleGetVariables = {
  companyId: string | undefined
  roleId: string | undefined
  body?: void
  query?: void
}

export const companyRoleGet = makeData<CompanyRoleGetResponse, CompanyRoleGetKey, CompanyRoleGetVariables>({
  method: 'get',
  name: 'companyRoleGet',
  summary: 'Retrieve one company role',
  path: '/companies/{companyId}/roles/{roleId}',
  queryKey: ({ query, companyId, roleId }) => ['companies', companyId, 'roles', roleId, query],
})

export type CompanyRoleUpdateKey = ['companies', string | undefined, 'roles', string | undefined, unknown]

export type CompanyRoleUpdateResponse = ResponsesRoleTypes.Role

export type CompanyRoleUpdateVariables = {
  companyId: string | undefined
  roleId: string | undefined
  body?: SchemasRoleTypes.RolePut
  query?: void
}

export const companyRoleUpdate = makeData<CompanyRoleUpdateResponse, CompanyRoleUpdateKey, CompanyRoleUpdateVariables>({
  method: 'put',
  name: 'companyRoleUpdate',
  summary: 'Update a company role',
  path: '/companies/{companyId}/roles/{roleId}',
  queryKey: ({ query, companyId, roleId }) => ['companies', companyId, 'roles', roleId, query],
})

export type CompanyRoleDeleteKey = ['companies', string | undefined, 'roles', string | undefined, unknown]

export type CompanyRoleDeleteResponse = void

export type CompanyRoleDeleteVariables = {
  companyId: string | undefined
  roleId: string | undefined
  body?: void
  query?: void
}

export const companyRoleDelete = makeData<CompanyRoleDeleteResponse, CompanyRoleDeleteKey, CompanyRoleDeleteVariables>({
  method: 'delete',
  name: 'companyRoleDelete',
  summary: 'Delete a company role',
  path: '/companies/{companyId}/roles/{roleId}',
  queryKey: ({ query, companyId, roleId }) => ['companies', companyId, 'roles', roleId, query],
})

export type CompanyRolePermissionsElevateKey = [
  'companies',
  string | undefined,
  'roles',
  string | undefined,
  'permissions',
  unknown,
]

export type CompanyRolePermissionsElevateResponse = ResponsesRoleTypes.Role

export type CompanyRolePermissionsElevateVariables = {
  companyId: string | undefined
  roleId: string | undefined
  body?: SchemasRoleTypes.Actions
  query?: void
}

export const companyRolePermissionsElevate = makeData<
  CompanyRolePermissionsElevateResponse,
  CompanyRolePermissionsElevateKey,
  CompanyRolePermissionsElevateVariables
>({
  method: 'post',
  name: 'companyRolePermissionsElevate',
  summary: 'Elevate permissions',
  path: '/companies/{companyId}/roles/{roleId}/permissions',
  queryKey: ({ query, companyId, roleId }) => ['companies', companyId, 'roles', roleId, 'permissions', query],
})

export type CompanyRolePermissionsRevokeKey = [
  'companies',
  string | undefined,
  'roles',
  string | undefined,
  'permissions',
  unknown,
]

export type CompanyRolePermissionsRevokeResponse = ResponsesRoleTypes.Role

export type CompanyRolePermissionsRevokeVariables = {
  companyId: string | undefined
  roleId: string | undefined
  body?: void
  query?: {
    actions: Array<string>
  }
}

export const companyRolePermissionsRevoke = makeData<
  CompanyRolePermissionsRevokeResponse,
  CompanyRolePermissionsRevokeKey,
  CompanyRolePermissionsRevokeVariables
>({
  method: 'delete',
  name: 'companyRolePermissionsRevoke',
  summary: 'Revoke permissions',
  path: '/companies/{companyId}/roles/{roleId}/permissions',
  queryKey: ({ query, companyId, roleId }) => ['companies', companyId, 'roles', roleId, 'permissions', query],
})

export type CompanyRolePermissionsRevokeOneKey = [
  'companies',
  string | undefined,
  'roles',
  string | undefined,
  'permissions',
  string | undefined,
  unknown,
]

export type CompanyRolePermissionsRevokeOneResponse = void

export type CompanyRolePermissionsRevokeOneVariables = {
  companyId: string | undefined
  roleId: string | undefined
  permissionId: string | undefined
  body?: void
  query?: void
}

export const companyRolePermissionsRevokeOne = makeData<
  CompanyRolePermissionsRevokeOneResponse,
  CompanyRolePermissionsRevokeOneKey,
  CompanyRolePermissionsRevokeOneVariables
>({
  method: 'delete',
  name: 'companyRolePermissionsRevokeOne',
  summary: 'Revoke a single permission from a role',
  path: '/companies/{companyId}/roles/{roleId}/permissions/{permissionId}',
  queryKey: ({ query, companyId, roleId, permissionId }) => [
    'companies',
    companyId,
    'roles',
    roleId,
    'permissions',
    permissionId,
    query,
  ],
})

export type CompanyPermissionsGetKey = ['companies', string | undefined, 'permissions', unknown]

export type CompanyPermissionsGetResponse = ResponseTypes.Permissions

export type CompanyPermissionsGetVariables = {
  companyId: string | undefined
  body?: void
  query?: void
}

export const companyPermissionsGet = makeData<
  CompanyPermissionsGetResponse,
  CompanyPermissionsGetKey,
  CompanyPermissionsGetVariables
>({
  method: 'get',
  name: 'companyPermissionsGet',
  summary: "Get a company's available permissions",
  path: '/companies/{companyId}/permissions',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'permissions', query],
})

export type InternalUsersGetKey = ['companies', string | undefined, 'users', unknown]

export type InternalUsersGetResponse = ResponseTypes.UsersPaging

export type InternalUsersGetVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    sortBy?: Array<string>
    userType?: string
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const internalUsersGet = makeData<InternalUsersGetResponse, InternalUsersGetKey, InternalUsersGetVariables>({
  method: 'get',
  name: 'internalUsersGet',
  summary: "Get a company's users",
  path: '/companies/{companyId}/users',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'users', query],
})

export type InternalUserCreateKey = ['companies', string | undefined, 'users', unknown]

export type InternalUserCreateResponse = ResponseTypes.User

export type InternalUserCreateVariables = {
  companyId: string | undefined
  body?: SchemaTypes.UserCreate
  query?: void
}

export const internalUserCreate = makeData<
  InternalUserCreateResponse,
  InternalUserCreateKey,
  InternalUserCreateVariables
>({
  method: 'post',
  name: 'internalUserCreate',
  summary: 'Create company user',
  path: '/companies/{companyId}/users',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'users', query],
})

export type InternalUserGetKey = ['companies', string | undefined, 'users', string | undefined, unknown]

export type InternalUserGetResponse = ResponseTypes.User

export type InternalUserGetVariables = {
  companyId: string | undefined
  userId: string | undefined
  body?: void
  query?: void
}

export const internalUserGet = makeData<InternalUserGetResponse, InternalUserGetKey, InternalUserGetVariables>({
  method: 'get',
  name: 'internalUserGet',
  summary: 'Get company user',
  path: '/companies/{companyId}/users/{userId}',
  queryKey: ({ query, companyId, userId }) => ['companies', companyId, 'users', userId, query],
})

export type InternalUserUpdateKey = ['companies', string | undefined, 'users', string | undefined, unknown]

export type InternalUserUpdateResponse = ResponseTypes.User

export type InternalUserUpdateVariables = {
  companyId: string | undefined
  userId: string | undefined
  body?: SchemaTypes.UserUpdate
  query?: void
}

export const internalUserUpdate = makeData<
  InternalUserUpdateResponse,
  InternalUserUpdateKey,
  InternalUserUpdateVariables
>({
  method: 'put',
  name: 'internalUserUpdate',
  summary: 'Update company user',
  path: '/companies/{companyId}/users/{userId}',
  queryKey: ({ query, companyId, userId }) => ['companies', companyId, 'users', userId, query],
})

export type InternalUserGetPermissionsKey = [
  'companies',
  string | undefined,
  'users',
  string | undefined,
  'permissions',
  unknown,
]

export type InternalUserGetPermissionsResponse = void

export type InternalUserGetPermissionsVariables = {
  companyId: string | undefined
  userId: string | undefined
  body?: void
  query?: void
}

export const internalUserGetPermissions = makeData<
  InternalUserGetPermissionsResponse,
  InternalUserGetPermissionsKey,
  InternalUserGetPermissionsVariables
>({
  method: 'get',
  name: 'internalUserGetPermissions',
  summary: 'Get user permissions',
  path: '/companies/{companyId}/users/{userId}/permissions',
  queryKey: ({ query, companyId, userId }) => ['companies', companyId, 'users', userId, 'permissions', query],
})

export type InternalUserRoleGetKey = ['companies', string | undefined, 'users', string | undefined, 'roles', unknown]

export type InternalUserRoleGetResponse = ResponsesRoleTypes.RolesPaging

export type InternalUserRoleGetVariables = {
  companyId: string | undefined
  userId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const internalUserRoleGet = makeData<
  InternalUserRoleGetResponse,
  InternalUserRoleGetKey,
  InternalUserRoleGetVariables
>({
  method: 'get',
  name: 'internalUserRoleGet',
  summary: 'Get roles by user',
  path: '/companies/{companyId}/users/{userId}/roles',
  queryKey: ({ query, companyId, userId }) => ['companies', companyId, 'users', userId, 'roles', query],
})

export type InternalUserRoleUpdateKey = [
  'companies',
  string | undefined,
  'users',
  string | undefined,
  'roles',
  string | undefined,
  unknown,
]

export type InternalUserRoleUpdateResponse = void

export type InternalUserRoleUpdateVariables = {
  companyId: string | undefined
  userId: string | undefined
  roleId: string | undefined
  body?: void
  query?: void
}

export const internalUserRoleUpdate = makeData<
  InternalUserRoleUpdateResponse,
  InternalUserRoleUpdateKey,
  InternalUserRoleUpdateVariables
>({
  method: 'put',
  name: 'internalUserRoleUpdate',
  summary: 'Add role to user',
  path: '/companies/{companyId}/users/{userId}/roles/{roleId}',
  queryKey: ({ query, companyId, userId, roleId }) => ['companies', companyId, 'users', userId, 'roles', roleId, query],
})

export type InternalUserRoleDeleteKey = [
  'companies',
  string | undefined,
  'users',
  string | undefined,
  'roles',
  string | undefined,
  unknown,
]

export type InternalUserRoleDeleteResponse = void

export type InternalUserRoleDeleteVariables = {
  companyId: string | undefined
  userId: string | undefined
  roleId: string | undefined
  body?: void
  query?: void
}

export const internalUserRoleDelete = makeData<
  InternalUserRoleDeleteResponse,
  InternalUserRoleDeleteKey,
  InternalUserRoleDeleteVariables
>({
  method: 'delete',
  name: 'internalUserRoleDelete',
  summary: 'Remove role from user',
  path: '/companies/{companyId}/users/{userId}/roles/{roleId}',
  queryKey: ({ query, companyId, userId, roleId }) => ['companies', companyId, 'users', userId, 'roles', roleId, query],
})

export type InvestorGetAllKey = ['companies', string | undefined, 'investors', unknown]

export type InvestorGetAllResponse = UtilityTypes.Paging & {
  count: number
  data?: Array<SchemaTypes.Investor>
}

export type InvestorGetAllVariables = {
  companyId: string | undefined
  body?: void
  query?: void
}

export const investorGetAll = makeData<InvestorGetAllResponse, InvestorGetAllKey, InvestorGetAllVariables>({
  method: 'get',
  name: 'investorGetAll',
  summary: 'Get investors',
  path: '/companies/{companyId}/investors',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'investors', query],
})

export type InvestorCreateKey = ['companies', string | undefined, 'investors', unknown]

export type InvestorCreateResponse = SchemaTypes.Investor

export type InvestorCreateVariables = {
  companyId: string | undefined
  body?: SchemaTypes.Investor
  query?: {
    /**
     * @default
     *     true
     */
    validate?: boolean
  }
}

export const investorCreate = makeData<InvestorCreateResponse, InvestorCreateKey, InvestorCreateVariables>({
  method: 'post',
  name: 'investorCreate',
  summary: 'Create investor',
  path: '/companies/{companyId}/investors',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'investors', query],
})

export type InternalLoanInvestorsGetAllKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'investors',
  unknown,
]

export type InternalLoanInvestorsGetAllResponse = SchemaTypes.LoanInvestorsPaging

export type InternalLoanInvestorsGetAllVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const internalLoanInvestorsGetAll = makeData<
  InternalLoanInvestorsGetAllResponse,
  InternalLoanInvestorsGetAllKey,
  InternalLoanInvestorsGetAllVariables
>({
  method: 'get',
  name: 'internalLoanInvestorsGetAll',
  summary: 'Get loan investors',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/investors',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'investors',
    query,
  ],
})

export type InvestorGetKey = ['companies', string | undefined, 'investors', string | undefined, unknown]

export type InvestorGetResponse = SchemaTypes.Investor

export type InvestorGetVariables = {
  companyId: string | undefined
  investorId: string | undefined
  body?: void
  query?: void
}

export const investorGet = makeData<InvestorGetResponse, InvestorGetKey, InvestorGetVariables>({
  method: 'get',
  name: 'investorGet',
  summary: 'Get investor by ID',
  path: '/companies/{companyId}/investors/{investorId}',
  queryKey: ({ query, companyId, investorId }) => ['companies', companyId, 'investors', investorId, query],
})

export type InvestorUpdateKey = ['companies', string | undefined, 'investors', string | undefined, unknown]

export type InvestorUpdateResponse = void

export type InvestorUpdateVariables = {
  companyId: string | undefined
  investorId: string | undefined
  body?: SchemaTypes.InvestorMutable
  query?: {
    /**
     * @default
     *     true
     */
    validate?: boolean
  }
}

export const investorUpdate = makeData<InvestorUpdateResponse, InvestorUpdateKey, InvestorUpdateVariables>({
  method: 'put',
  name: 'investorUpdate',
  summary: 'Update investor',
  path: '/companies/{companyId}/investors/{investorId}',
  queryKey: ({ query, companyId, investorId }) => ['companies', companyId, 'investors', investorId, query],
})

export type SettlementInstrumentGetAllKey = [
  'companies',
  string | undefined,
  'investors',
  string | undefined,
  'settlement-instruments',
  unknown,
]

export type SettlementInstrumentGetAllResponse = UtilityTypes.Paging & {
  count: number
  data?: Array<SchemaTypes.SettlementInstrument>
}

export type SettlementInstrumentGetAllVariables = {
  companyId: string | undefined
  investorId: string | undefined
  body?: void
  query?: void
}

export const settlementInstrumentGetAll = makeData<
  SettlementInstrumentGetAllResponse,
  SettlementInstrumentGetAllKey,
  SettlementInstrumentGetAllVariables
>({
  method: 'get',
  name: 'settlementInstrumentGetAll',
  summary: 'Get settlement instruments',
  path: '/companies/{companyId}/investors/{investorId}/settlement-instruments',
  queryKey: ({ query, companyId, investorId }) => [
    'companies',
    companyId,
    'investors',
    investorId,
    'settlement-instruments',
    query,
  ],
})

export type SettlementInstrumentCreateKey = [
  'companies',
  string | undefined,
  'investors',
  string | undefined,
  'settlement-instruments',
  unknown,
]

export type SettlementInstrumentCreateResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.SettlementInstrument
}

export type SettlementInstrumentCreateVariables = {
  companyId: string | undefined
  investorId: string | undefined
  body?: SchemaTypes.SettlementInstrument
  query?: void
}

export const settlementInstrumentCreate = makeData<
  SettlementInstrumentCreateResponse,
  SettlementInstrumentCreateKey,
  SettlementInstrumentCreateVariables
>({
  method: 'post',
  name: 'settlementInstrumentCreate',
  summary: 'Create settlement instrument',
  path: '/companies/{companyId}/investors/{investorId}/settlement-instruments',
  queryKey: ({ query, companyId, investorId }) => [
    'companies',
    companyId,
    'investors',
    investorId,
    'settlement-instruments',
    query,
  ],
})

export type SettlementInstrumentGetKey = [
  'companies',
  string | undefined,
  'investors',
  string | undefined,
  'settlement-instruments',
  string | undefined,
  unknown,
]

export type SettlementInstrumentGetResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.SettlementInstrument
}

export type SettlementInstrumentGetVariables = {
  companyId: string | undefined
  investorId: string | undefined
  settlementInstrumentId: string | undefined
  body?: void
  query?: void
}

export const settlementInstrumentGet = makeData<
  SettlementInstrumentGetResponse,
  SettlementInstrumentGetKey,
  SettlementInstrumentGetVariables
>({
  method: 'get',
  name: 'settlementInstrumentGet',
  summary: 'Get settlement instrument by ID',
  path: '/companies/{companyId}/investors/{investorId}/settlement-instruments/{settlementInstrumentId}',
  queryKey: ({ query, companyId, investorId, settlementInstrumentId }) => [
    'companies',
    companyId,
    'investors',
    investorId,
    'settlement-instruments',
    settlementInstrumentId,
    query,
  ],
})

export type SettlementInstrumentUpdateKey = [
  'companies',
  string | undefined,
  'investors',
  string | undefined,
  'settlement-instruments',
  string | undefined,
  unknown,
]

export type SettlementInstrumentUpdateResponse = void

export type SettlementInstrumentUpdateVariables = {
  companyId: string | undefined
  investorId: string | undefined
  settlementInstrumentId: string | undefined
  body?: SchemaTypes.SettlementInstrumentMutable
  query?: void
}

export const settlementInstrumentUpdate = makeData<
  SettlementInstrumentUpdateResponse,
  SettlementInstrumentUpdateKey,
  SettlementInstrumentUpdateVariables
>({
  method: 'put',
  name: 'settlementInstrumentUpdate',
  summary: 'Update settlement instrument',
  path: '/companies/{companyId}/investors/{investorId}/settlement-instruments/{settlementInstrumentId}',
  queryKey: ({ query, companyId, investorId, settlementInstrumentId }) => [
    'companies',
    companyId,
    'investors',
    investorId,
    'settlement-instruments',
    settlementInstrumentId,
    query,
  ],
})

export type FundingInstrumentGetAllKey = [
  'companies',
  string | undefined,
  'payment-processors',
  string | undefined,
  'funding-instruments',
  unknown,
]

export type FundingInstrumentGetAllResponse = UtilityTypes.Paging & {
  count: number
  data?: Array<SchemaTypes.FundingInstrument>
}

export type FundingInstrumentGetAllVariables = {
  companyId: string | undefined
  paymentProcessorId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const fundingInstrumentGetAll = makeData<
  FundingInstrumentGetAllResponse,
  FundingInstrumentGetAllKey,
  FundingInstrumentGetAllVariables
>({
  method: 'get',
  name: 'fundingInstrumentGetAll',
  summary: 'Get funding instruments',
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}/funding-instruments',
  queryKey: ({ query, companyId, paymentProcessorId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'funding-instruments',
    query,
  ],
})

export type FundingInstrumentCreateKey = [
  'companies',
  string | undefined,
  'payment-processors',
  string | undefined,
  'funding-instruments',
  unknown,
]

export type FundingInstrumentCreateResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.FundingInstrument
}

export type FundingInstrumentCreateVariables = {
  companyId: string | undefined
  paymentProcessorId: string | undefined
  body?: SchemaTypes.FundingInstrument
  query?: void
}

export const fundingInstrumentCreate = makeData<
  FundingInstrumentCreateResponse,
  FundingInstrumentCreateKey,
  FundingInstrumentCreateVariables
>({
  method: 'post',
  name: 'fundingInstrumentCreate',
  summary: 'Create funding instrument',
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}/funding-instruments',
  queryKey: ({ query, companyId, paymentProcessorId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'funding-instruments',
    query,
  ],
})

export type FundingInstrumentGetKey = [
  'companies',
  string | undefined,
  'payment-processors',
  string | undefined,
  'funding-instruments',
  string | undefined,
  unknown,
]

export type FundingInstrumentGetResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.FundingInstrument
}

export type FundingInstrumentGetVariables = {
  companyId: string | undefined
  paymentProcessorId: string | undefined
  fundingInstrumentId: string | undefined
  body?: void
  query?: void
}

export const fundingInstrumentGet = makeData<
  FundingInstrumentGetResponse,
  FundingInstrumentGetKey,
  FundingInstrumentGetVariables
>({
  method: 'get',
  name: 'fundingInstrumentGet',
  summary: 'Get funding instrument by ID',
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}/funding-instruments/{fundingInstrumentId}',
  queryKey: ({ query, companyId, paymentProcessorId, fundingInstrumentId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'funding-instruments',
    fundingInstrumentId,
    query,
  ],
})

export type FundingInstrumentUpdateKey = [
  'companies',
  string | undefined,
  'payment-processors',
  string | undefined,
  'funding-instruments',
  string | undefined,
  unknown,
]

export type FundingInstrumentUpdateResponse = void

export type FundingInstrumentUpdateVariables = {
  companyId: string | undefined
  paymentProcessorId: string | undefined
  fundingInstrumentId: string | undefined
  body?: SchemaTypes.FundingInstrumentMutable
  query?: void
}

export const fundingInstrumentUpdate = makeData<
  FundingInstrumentUpdateResponse,
  FundingInstrumentUpdateKey,
  FundingInstrumentUpdateVariables
>({
  method: 'put',
  name: 'fundingInstrumentUpdate',
  summary: 'Update funding instrument',
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}/funding-instruments/{fundingInstrumentId}',
  queryKey: ({ query, companyId, paymentProcessorId, fundingInstrumentId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'funding-instruments',
    fundingInstrumentId,
    query,
  ],
})

export type GetRecentFundingAccountBalancesKey = [
  'companies',
  string | undefined,
  'payment-processors',
  string | undefined,
  'funding-instruments',
  string | undefined,
  'balance',
  unknown,
]

export type GetRecentFundingAccountBalancesResponse = UtilityTypes.NewPaginator & {
  data?: Array<SchemaTypes.FundingAccountBalance>
}

export type GetRecentFundingAccountBalancesVariables = {
  companyId: string | undefined
  paymentProcessorId: string | undefined
  fundingInstrumentId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
  }
}

export const getRecentFundingAccountBalances = makeData<
  GetRecentFundingAccountBalancesResponse,
  GetRecentFundingAccountBalancesKey,
  GetRecentFundingAccountBalancesVariables
>({
  method: 'get',
  name: 'getRecentFundingAccountBalances',
  summary: 'Fetch balances for the funding instrument',
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}/funding-instruments/{fundingInstrumentId}/balance',
  queryKey: ({ query, companyId, paymentProcessorId, fundingInstrumentId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'funding-instruments',
    fundingInstrumentId,
    'balance',
    query,
  ],
})

export type FundingInstrumentCreateBalanceKey = [
  'companies',
  string | undefined,
  'payment-processors',
  string | undefined,
  'funding-instruments',
  string | undefined,
  'balance',
  unknown,
]

export type FundingInstrumentCreateBalanceResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.FundingAccountBalance
}

export type FundingInstrumentCreateBalanceVariables = {
  companyId: string | undefined
  paymentProcessorId: string | undefined
  fundingInstrumentId: string | undefined
  body?: SchemaTypes.FundingAccountBalance
  query?: void
}

export const fundingInstrumentCreateBalance = makeData<
  FundingInstrumentCreateBalanceResponse,
  FundingInstrumentCreateBalanceKey,
  FundingInstrumentCreateBalanceVariables
>({
  method: 'post',
  name: 'fundingInstrumentCreateBalance',
  summary: 'Add a new balance to the funding instrument',
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}/funding-instruments/{fundingInstrumentId}/balance',
  queryKey: ({ query, companyId, paymentProcessorId, fundingInstrumentId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'funding-instruments',
    fundingInstrumentId,
    'balance',
    query,
  ],
})

export type GetAllTransferSettlementsForAllCompaniesKey = ['settlements', unknown]

export type GetAllTransferSettlementsForAllCompaniesResponse = UtilityTypes.NewPaginator & {
  data?: Array<SchemaTypes.TransferSettlement>
}

export type GetAllTransferSettlementsForAllCompaniesVariables = {
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    statuses?: Array<'readyForFunding' | 'funded' | 'processing' | 'failedFunding'>
  }
}

export const getAllTransferSettlementsForAllCompanies = makeData<
  GetAllTransferSettlementsForAllCompaniesResponse,
  GetAllTransferSettlementsForAllCompaniesKey,
  GetAllTransferSettlementsForAllCompaniesVariables
>({
  method: 'get',
  name: 'getAllTransferSettlementsForAllCompanies',
  summary: 'Get all the transfer settlements for all companies',
  path: '/settlements',
  queryKey: ({ query }) => ['settlements', query],
})

export type GetAllTransferSettlementsKey = ['companies', string | undefined, 'settlements', unknown]

export type GetAllTransferSettlementsResponse = {
  count?: number
  total?: number
  data?: Array<SchemaTypes.TransferSettlement>
}

export type GetAllTransferSettlementsVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    statuses?: Array<'readyForFunding' | 'funded' | 'processing' | 'failedFunding'>
  }
}

export const getAllTransferSettlements = makeData<
  GetAllTransferSettlementsResponse,
  GetAllTransferSettlementsKey,
  GetAllTransferSettlementsVariables
>({
  method: 'get',
  name: 'getAllTransferSettlements',
  summary: 'Get all the transfer settlements',
  path: '/companies/{companyId}/settlements',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'settlements', query],
})

export type CreateTransferSettlementsKey = ['companies', string | undefined, 'settlements', unknown]

export type CreateTransferSettlementsResponse = {
  data?: Array<SchemaTypes.TransferSettlement>
}

export type CreateTransferSettlementsVariables = {
  companyId: string | undefined
  body?: void
  query?: void
}

export const createTransferSettlements = makeData<
  CreateTransferSettlementsResponse,
  CreateTransferSettlementsKey,
  CreateTransferSettlementsVariables
>({
  method: 'post',
  name: 'createTransferSettlements',
  summary: 'Create a new transfer settlement',
  path: '/companies/{companyId}/settlements',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'settlements', query],
})

export type GetTransferSettlementKey = ['companies', string | undefined, 'settlements', string | undefined, unknown]

export type GetTransferSettlementResponse = UtilityTypes.SingletonResponse & SchemaTypes.TransferSettlement

export type GetTransferSettlementVariables = {
  companyId: string | undefined
  transferSettlementId: string | undefined
  body?: void
  query?: void
}

export const getTransferSettlement = makeData<
  GetTransferSettlementResponse,
  GetTransferSettlementKey,
  GetTransferSettlementVariables
>({
  method: 'get',
  name: 'getTransferSettlement',
  summary: 'Get a transfer settlement by ID',
  path: '/companies/{companyId}/settlements/{transferSettlementId}',
  queryKey: ({ query, companyId, transferSettlementId }) => [
    'companies',
    companyId,
    'settlements',
    transferSettlementId,
    query,
  ],
})

export type UpdateTransferSettlementKey = ['companies', string | undefined, 'settlements', string | undefined, unknown]

export type UpdateTransferSettlementResponse = void

export type UpdateTransferSettlementVariables = {
  companyId: string | undefined
  transferSettlementId: string | undefined
  body?: void
  query?: void
}

export const updateTransferSettlement = makeData<
  UpdateTransferSettlementResponse,
  UpdateTransferSettlementKey,
  UpdateTransferSettlementVariables
>({
  method: 'put',
  name: 'updateTransferSettlement',
  summary: 'Update a transfer settlement by ID',
  path: '/companies/{companyId}/settlements/{transferSettlementId}',
  queryKey: ({ query, companyId, transferSettlementId }) => [
    'companies',
    companyId,
    'settlements',
    transferSettlementId,
    query,
  ],
})

export type UpdateTransfersExpectingFundingKey = [
  'companies',
  string | undefined,
  'loans',
  string | undefined,
  'transfers',
  unknown,
]

export type UpdateTransfersExpectingFundingResponse = void

export type UpdateTransfersExpectingFundingVariables = {
  companyId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const updateTransfersExpectingFunding = makeData<
  UpdateTransfersExpectingFundingResponse,
  UpdateTransfersExpectingFundingKey,
  UpdateTransfersExpectingFundingVariables
>({
  method: 'put',
  name: 'updateTransfersExpectingFunding',
  summary: 'Update the transfers expecting funding',
  path: '/companies/{companyId}/loans/{loanId}/transfers',
  queryKey: ({ query, companyId, loanId }) => ['companies', companyId, 'loans', loanId, 'transfers', query],
})

export type EndSessionKey = ['auth', 'endsession', unknown]

export type EndSessionResponse = void

export type EndSessionVariables = {
  body?: {
    /** The field name to validate against. */
    field?: 'email' | 'username' | 'phone' | 'samlSubjectNameId'
    value?: /** The value to match. */ string /** The value to match. */ | null
  }
  query?: {
    companyId?: string
    userType?: string
    debug?: boolean
  }
}

export const endSession = makeData<EndSessionResponse, EndSessionKey, EndSessionVariables>({
  method: 'post',
  name: 'endSession',
  summary: "End a user's session",
  path: '/auth/endsession',
  queryKey: ({ query }) => ['auth', 'endsession', query],
})

export type RenewJwtTokenKey = ['auth', 'token', unknown]

export type RenewJwtTokenResponse = ResponsesComplianceTypes.JWT

export type RenewJwtTokenVariables = {
  body?: void
  query?: void
}

export const renewJwtToken = makeData<RenewJwtTokenResponse, RenewJwtTokenKey, RenewJwtTokenVariables>({
  method: 'put',
  name: 'renewJwtToken',
  summary: 'Exchange current token for a new one',
  path: '/auth/token',
  queryKey: ({ query }) => ['auth', 'token', query],
})

export type ValidateAndSendTokenKey = ['auth', 'token', unknown]

export type ValidateAndSendTokenResponse = ResponsesComplianceTypes.JWT

export type ValidateAndSendTokenVariables = {
  body?: RequestBodyTypes.AuthPost
  query?: {
    companyId?: string
    domain?: string
    userType?: string
    authType?: SchemaTypes.AuthType
    authValueType?: SchemaTypes.AuthValueType
  }
}

export const validateAndSendToken = makeData<
  ValidateAndSendTokenResponse,
  ValidateAndSendTokenKey,
  ValidateAndSendTokenVariables
>({
  method: 'post',
  name: 'validateAndSendToken',
  summary: 'Exchange username and password for JWT token',
  path: '/auth/token',
  queryKey: ({ query }) => ['auth', 'token', query],
})

export type LogoutKey = ['auth', 'token', unknown]

export type LogoutResponse = ResponseTypes.SingletonResponse

export type LogoutVariables = {
  body?: void
  query?: void
}

export const logout = makeData<LogoutResponse, LogoutKey, LogoutVariables>({
  method: 'delete',
  name: 'logout',
  summary: 'End login session, invalidating current login token.',
  path: '/auth/token',
  queryKey: ({ query }) => ['auth', 'token', query],
})

export type ShortLivedTokenKey = ['auth', 'token', 'short-lived', unknown]

export type ShortLivedTokenResponse = ResponsesComplianceTypes.JWT

export type ShortLivedTokenVariables = {
  body?: {
    /** List of permissions that the short-lived token can have. These can only be permissions that are already given to the calling user. */
    permissions?: Array<string>
  }
  query?: void
}

export const shortLivedToken = makeData<ShortLivedTokenResponse, ShortLivedTokenKey, ShortLivedTokenVariables>({
  method: 'post',
  name: 'shortLivedToken',
  summary: 'Create a short-lived token',
  path: '/auth/token/short-lived',
  queryKey: ({ query }) => ['auth', 'token', 'short-lived', query],
})

export type PasswordPostKey = ['auth', 'password', unknown]

export type PasswordPostResponse = ResponseTypes.SingletonResponse

export type PasswordPostVariables = {
  body?: RequestBodyTypes.AuthPost
  query?: void
}

export const passwordPost = makeData<PasswordPostResponse, PasswordPostKey, PasswordPostVariables>({
  method: 'post',
  name: 'passwordPost',
  summary: 'Change password',
  path: '/auth/password',
  queryKey: ({ query }) => ['auth', 'password', query],
})

export type UserPermissions_getKey = ['auth', 'permissions', unknown]

export type UserPermissions_getResponse = ResponsesRoleTypes.UserPermissions

export type UserPermissions_getVariables = {
  body?: void
  query?: void
}

export const userPermissions_get = makeData<
  UserPermissions_getResponse,
  UserPermissions_getKey,
  UserPermissions_getVariables
>({
  method: 'get',
  name: 'userPermissions_get',
  summary: 'Get all permissions for the requesting user',
  path: '/auth/permissions',
  queryKey: ({ query }) => ['auth', 'permissions', query],
})

export type IdpInitiatedKey = ['companies', string | undefined, 'auth', 'saml', 'callback', unknown]

export type IdpInitiatedResponse = void

export type IdpInitiatedVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    userType?: string
    appDomainType?: SchemaTypes.AppDomainType
  }
}

export const idpInitiated = makeData<IdpInitiatedResponse, IdpInitiatedKey, IdpInitiatedVariables>({
  method: 'post',
  name: 'idpInitiated',
  summary: 'SAML callback endpoint',
  path: '/companies/{companyId}/auth/saml/callback',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'auth', 'saml', 'callback', query],
})

export type SpInitiatedKey = ['companies', string | undefined, 'auth', 'saml', 'login', unknown]

export type SpInitiatedResponse = void

export type SpInitiatedVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    userType?: string
    appDomainType?: SchemaTypes.AppDomainType
    returnUrl?: boolean
  }
}

export const spInitiated = makeData<SpInitiatedResponse, SpInitiatedKey, SpInitiatedVariables>({
  method: 'get',
  name: 'spInitiated',
  summary: 'Redirect to the SAML login endpoint',
  path: '/companies/{companyId}/auth/saml/login',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'auth', 'saml', 'login', query],
})

export type SpMetadataXmlKey = ['companies', string | undefined, 'auth', 'saml', 'metadata.xml', unknown]

export type SpMetadataXmlResponse = void

export type SpMetadataXmlVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    userType?: string
    appDomainType?: SchemaTypes.AppDomainType
  }
}

export const spMetadataXml = makeData<SpMetadataXmlResponse, SpMetadataXmlKey, SpMetadataXmlVariables>({
  method: 'get',
  name: 'spMetadataXml',
  summary: 'Display Peach SP Metadata',
  path: '/companies/{companyId}/auth/saml/metadata.xml',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'auth', 'saml', 'metadata.xml', query],
})

export type IdpSetupHelpKey = ['companies', string | undefined, 'auth', 'saml', 'help', unknown]

export type IdpSetupHelpResponse = void

export type IdpSetupHelpVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    userType?: string
    appDomainType?: SchemaTypes.AppDomainType
  }
}

export const idpSetupHelp = makeData<IdpSetupHelpResponse, IdpSetupHelpKey, IdpSetupHelpVariables>({
  method: 'get',
  name: 'idpSetupHelp',
  summary: 'Display IdP setup help',
  path: '/companies/{companyId}/auth/saml/help',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'auth', 'saml', 'help', query],
})

export type UserInfoGetKey = ['auth', 'user', unknown]

export type UserInfoGetResponse = ResponseTypes.UserInfo

export type UserInfoGetVariables = {
  body?: void
  query?: void
}

export const userInfoGet = makeData<UserInfoGetResponse, UserInfoGetKey, UserInfoGetVariables>({
  method: 'get',
  name: 'userInfoGet',
  summary: "Get logged-in user's info.",
  path: '/auth/user',
  queryKey: ({ query }) => ['auth', 'user', query],
})

export type PlaidAuthLinkTokenKey = ['plaid', 'auth', 'link-token', unknown]

/** See Plaid documentation for [`/link/token/create`](https://plaid.com/docs/api/link/#linktokencreate) for detailed info. */
export type PlaidAuthLinkTokenResponse = {
  link_token?: string
  expiration?: string
  request_id?: string
}

export type PlaidAuthLinkTokenVariables = {
  body?: {
    /** [`link_customization_name`](https://plaid.com/docs/api/link/#link-token-create-request-link-customization-name) parameter as defined by the Plaid API. */
    linkCustomizationName?: string
  }
  query?: void
}

export const plaidAuthLinkToken = makeData<
  PlaidAuthLinkTokenResponse,
  PlaidAuthLinkTokenKey,
  PlaidAuthLinkTokenVariables
>({
  method: 'post',
  name: 'plaidAuthLinkToken',
  summary: 'Create a Plaid link token',
  path: '/plaid/auth/link-token',
  queryKey: ({ query }) => ['plaid', 'auth', 'link-token', query],
})

export type CodeGetAllKey = ['code', unknown]

export type CodeGetAllResponse = ResponsesComplianceTypes.CodeCollection

export type CodeGetAllVariables = {
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    derivedFromTypeId?: SchemaTypes.AnyId
  }
}

export const codeGetAll = makeData<CodeGetAllResponse, CodeGetAllKey, CodeGetAllVariables>({
  method: 'get',
  name: 'codeGetAll',
  summary: 'Get codes',
  path: '/code',
  queryKey: ({ query }) => ['code', query],
})

export type CodeCreateKey = ['code', unknown]

export type CodeCreateResponse = ResponsesComplianceTypes.GetCode

export type CodeCreateVariables = {
  body?: RequestBodiesComplianceTypes.CodePost
  query?: void
}

export const codeCreate = makeData<CodeCreateResponse, CodeCreateKey, CodeCreateVariables>({
  method: 'post',
  name: 'codeCreate',
  summary: 'Create code',
  path: '/code',
  queryKey: ({ query }) => ['code', query],
})

export type ExportCodesKey = ['code', 'export', unknown]

export type ExportCodesResponse = {}

export type ExportCodesVariables = {
  body?: void
  query?: {
    companyId?: number
  }
}

export const exportCodes = makeData<ExportCodesResponse, ExportCodesKey, ExportCodesVariables>({
  method: 'get',
  name: 'exportCodes',
  summary: 'Download all rule codes in JSON format',
  path: '/code/export',
  queryKey: ({ query }) => ['code', 'export', query],
})

export type ImportCodesKey = ['code', 'import', unknown]

export type ImportCodesResponse = void

export type ImportCodesVariables = {
  body?: {}
  query?: {
    companyId?: number
  }
}

export const importCodes = makeData<ImportCodesResponse, ImportCodesKey, ImportCodesVariables>({
  method: 'post',
  name: 'importCodes',
  summary: 'Update codes from exported JSON file',
  path: '/code/import',
  queryKey: ({ query }) => ['code', 'import', query],
})

export type InternalImportCodesKey = ['companies', string | undefined, 'compliance', 'code', 'import', unknown]

export type InternalImportCodesResponse = void

export type InternalImportCodesVariables = {
  companyId: string | undefined
  body?: {}
  query?: void
}

export const internalImportCodes = makeData<
  InternalImportCodesResponse,
  InternalImportCodesKey,
  InternalImportCodesVariables
>({
  method: 'post',
  name: 'internalImportCodes',
  summary: 'Update codes from exported JSON file',
  path: '/companies/{companyId}/compliance/code/import',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'compliance', 'code', 'import', query],
})

export type InternalUpdateComplianceRuleKey = [
  'companies',
  string | undefined,
  'compliancerule',
  string | undefined,
  unknown,
]

export type InternalUpdateComplianceRuleResponse = ResponsesComplianceTypes.GetRule

export type InternalUpdateComplianceRuleVariables = {
  companyId: string | undefined
  complianceRuleId: string | undefined
  body?: RequestBodiesComplianceTypes.RuleUpdate
  query?: void
}

export const internalUpdateComplianceRule = makeData<
  InternalUpdateComplianceRuleResponse,
  InternalUpdateComplianceRuleKey,
  InternalUpdateComplianceRuleVariables
>({
  method: 'put',
  name: 'internalUpdateComplianceRule',
  summary: 'Update a compliance rule.',
  path: '/companies/{companyId}/compliancerule/{complianceRuleId}',
  queryKey: ({ query, companyId, complianceRuleId }) => [
    'companies',
    companyId,
    'compliancerule',
    complianceRuleId,
    query,
  ],
})

export type CodeGetKey = ['code', string | undefined, unknown]

export type CodeGetResponse = ResponsesComplianceTypes.GetCode

export type CodeGetVariables = {
  codeId: string | undefined
  body?: void
  query?: {
    version?: number
    fields?: string
  }
}

export const codeGet = makeData<CodeGetResponse, CodeGetKey, CodeGetVariables>({
  method: 'get',
  name: 'codeGet',
  summary: 'Get code by ID',
  path: '/code/{codeId}',
  queryKey: ({ query, codeId }) => ['code', codeId, query],
})

export type CodeUpdateKey = ['code', string | undefined, unknown]

export type CodeUpdateResponse = ResponsesComplianceTypes.GetCode

export type CodeUpdateVariables = {
  codeId: string | undefined
  body?: RequestBodiesComplianceTypes.CodeUpdate
  query?: {
    version?: number
  }
}

export const codeUpdate = makeData<CodeUpdateResponse, CodeUpdateKey, CodeUpdateVariables>({
  method: 'put',
  name: 'codeUpdate',
  summary: 'Update code',
  path: '/code/{codeId}',
  queryKey: ({ query, codeId }) => ['code', codeId, query],
})

export type CodeExecuteKey = ['code', string | undefined, unknown]

export type CodeExecuteResponse = ResponsesComplianceTypes.CodeExecution

export type CodeExecuteVariables = {
  codeId: string | undefined
  body?: RequestBodiesComplianceTypes.CodeExecution
  query?: {
    version?: number
  }
}

export const codeExecute = makeData<CodeExecuteResponse, CodeExecuteKey, CodeExecuteVariables>({
  method: 'post',
  name: 'codeExecute',
  summary: 'Execute code',
  path: '/code/{codeId}',
  queryKey: ({ query, codeId }) => ['code', codeId, query],
})

export type TransactionsGetInternalKey = ['transactions', unknown]

export type TransactionsGetInternalResponse = ResponseTypes.TransactionCollection

export type TransactionsGetInternalVariables = {
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    status?: SchemaTypes.TransactionStatus
    statuses?: Array<SchemaTypes.TransactionStatus>
    scheduledBefore?: string
    paymentType?: 'ach' | 'debitCard' | 'creditCard'
    paymentTypes?: Array<'ach' | 'debitCard' | 'creditCard'>
    process?: 'scheduledMicrodeposits' | 'pendingReversals' | 'checkConfirmation' | 'paymentDueToday' | 'raw'
    autopayExpectedPaymentIds?: Array<string>
    isExternal?: boolean
  }
}

export const transactionsGetInternal = makeData<
  TransactionsGetInternalResponse,
  TransactionsGetInternalKey,
  TransactionsGetInternalVariables
>({
  method: 'get',
  name: 'transactionsGetInternal',
  summary: 'Get all transactions',
  path: '/transactions',
  queryKey: ({ query }) => ['transactions', query],
})

export type TransactionsUpdateInternalKey = ['transactions', unknown]

export type TransactionsUpdateInternalResponse = void

export type TransactionsUpdateInternalVariables = {
  body?:
    | Array<any>
    | {
        entries?: Array<{
          type?: 'transaction' | 'microdeposit' | 'microdepositReversal'
          id?: string
          amount?: number
          processingfeeAmount?: number
          processingFeeType?: 'gross' | 'net'
          status?: SchemaTypes.TransactionStatus
          failureReason?: SchemaTypes.TransactionFailureReason
        }>
      }
  query?: {
    process?: 'scheduled' | 'pending' | 'pendingReversals' | 'achConfirmation' | 'chargeback' | 'autopay' | 'service'
  }
}

export const transactionsUpdateInternal = makeData<
  TransactionsUpdateInternalResponse,
  TransactionsUpdateInternalKey,
  TransactionsUpdateInternalVariables
>({
  method: 'post',
  name: 'transactionsUpdateInternal',
  summary: 'Update processed transactions',
  path: '/transactions',
  queryKey: ({ query }) => ['transactions', query],
})

export type TransactionsGetInitiatedAchInternalKey = ['transactions', 'initiated', unknown]

export type TransactionsGetInitiatedAchInternalResponse = ResponseTypes.TransactionCollection

export type TransactionsGetInitiatedAchInternalVariables = {
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    paymentProcessorId: SchemaTypes.AnyId
  }
}

export const transactionsGetInitiatedAchInternal = makeData<
  TransactionsGetInitiatedAchInternalResponse,
  TransactionsGetInitiatedAchInternalKey,
  TransactionsGetInitiatedAchInternalVariables
>({
  method: 'get',
  name: 'transactionsGetInitiatedAchInternal',
  summary: 'Get initiated ACH transactions.',
  path: '/transactions/initiated',
  queryKey: ({ query }) => ['transactions', 'initiated', query],
})

export type TransactionsUpdateInitiatedAchInternalKey = ['transactions', 'initiated', unknown]

export type TransactionsUpdateInitiatedAchInternalResponse = void

export type TransactionsUpdateInitiatedAchInternalVariables = {
  body?: {
    /** The card number. Must provide at least first 4 digits of the card number. */
    cardNumber?: string
  }
  query?: void
}

export const transactionsUpdateInitiatedAchInternal = makeData<
  TransactionsUpdateInitiatedAchInternalResponse,
  TransactionsUpdateInitiatedAchInternalKey,
  TransactionsUpdateInitiatedAchInternalVariables
>({
  method: 'post',
  name: 'transactionsUpdateInitiatedAchInternal',
  summary: 'Update processed ACH transactions.',
  path: '/transactions/initiated',
  queryKey: ({ query }) => ['transactions', 'initiated', query],
})

export type TransactionsCreateRefundServiceCreditInternalKey = ['transactions', 'refund', unknown]

export type TransactionsCreateRefundServiceCreditInternalResponse = void

export type TransactionsCreateRefundServiceCreditInternalVariables = {
  body?: {
    amount: number
    drawId: string
  }
  query?: void
}

export const transactionsCreateRefundServiceCreditInternal = makeData<
  TransactionsCreateRefundServiceCreditInternalResponse,
  TransactionsCreateRefundServiceCreditInternalKey,
  TransactionsCreateRefundServiceCreditInternalVariables
>({
  method: 'post',
  name: 'transactionsCreateRefundServiceCreditInternal',
  summary: 'Create service credit of type refund for LOC purchase',
  path: '/transactions/refund',
  queryKey: ({ query }) => ['transactions', 'refund', query],
})

export type TransactionsCreateRoundingServiceCreditInternalKey = ['transactions', 'rounding', unknown]

export type TransactionsCreateRoundingServiceCreditInternalResponse = void

export type TransactionsCreateRoundingServiceCreditInternalVariables = {
  body?: {
    loanId: string
    amount: number
  }
  query?: void
}

export const transactionsCreateRoundingServiceCreditInternal = makeData<
  TransactionsCreateRoundingServiceCreditInternalResponse,
  TransactionsCreateRoundingServiceCreditInternalKey,
  TransactionsCreateRoundingServiceCreditInternalVariables
>({
  method: 'post',
  name: 'transactionsCreateRoundingServiceCreditInternal',
  summary: 'Create service credit of type `rounding`',
  path: '/transactions/rounding',
  queryKey: ({ query }) => ['transactions', 'rounding', query],
})

export type MicrodepositsGetScheduledInternalKey = ['transactions', 'microdeposits', unknown]

export type MicrodepositsGetScheduledInternalResponse = ResponseTypes.MicrodepositCollection

export type MicrodepositsGetScheduledInternalVariables = {
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    paymentProcessorId: SchemaTypes.AnyId
  }
}

export const microdepositsGetScheduledInternal = makeData<
  MicrodepositsGetScheduledInternalResponse,
  MicrodepositsGetScheduledInternalKey,
  MicrodepositsGetScheduledInternalVariables
>({
  method: 'get',
  name: 'microdepositsGetScheduledInternal',
  summary: 'Get scheduled microdeposits',
  path: '/transactions/microdeposits',
  queryKey: ({ query }) => ['transactions', 'microdeposits', query],
})

export type TransactionsUpdateMicrodepositsInternalKey = ['transactions', 'microdeposits', unknown]

export type TransactionsUpdateMicrodepositsInternalResponse = void

export type TransactionsUpdateMicrodepositsInternalVariables = {
  body?: Array<{}>
  query?: void
}

export const transactionsUpdateMicrodepositsInternal = makeData<
  TransactionsUpdateMicrodepositsInternalResponse,
  TransactionsUpdateMicrodepositsInternalKey,
  TransactionsUpdateMicrodepositsInternalVariables
>({
  method: 'post',
  name: 'transactionsUpdateMicrodepositsInternal',
  summary: 'Update processed microdeposits.',
  path: '/transactions/microdeposits',
  queryKey: ({ query }) => ['transactions', 'microdeposits', query],
})

export type MicrodepositsSendRemindersKey = ['transactions', 'microdeposits', 'reminders', unknown]

export type MicrodepositsSendRemindersResponse = void

export type MicrodepositsSendRemindersVariables = {
  body?: {}
  query?: void
}

export const microdepositsSendReminders = makeData<
  MicrodepositsSendRemindersResponse,
  MicrodepositsSendRemindersKey,
  MicrodepositsSendRemindersVariables
>({
  method: 'post',
  name: 'microdepositsSendReminders',
  summary: 'Send microdeposit reminders.',
  path: '/transactions/microdeposits/reminders',
  queryKey: ({ query }) => ['transactions', 'microdeposits', 'reminders', query],
})

export type InternalTransactionsGetLoanIdsKey = ['transactions', 'loan-ids', unknown]

export type InternalTransactionsGetLoanIdsResponse = {}

export type InternalTransactionsGetLoanIdsVariables = {
  body?: Array<string | number>
  query?: void
}

export const internalTransactionsGetLoanIds = makeData<
  InternalTransactionsGetLoanIdsResponse,
  InternalTransactionsGetLoanIdsKey,
  InternalTransactionsGetLoanIdsVariables
>({
  method: 'post',
  name: 'internalTransactionsGetLoanIds',
  summary:
    'Get the loans associated with a list of transaction. Returns a map from transaction public ID to loan public ID. Null values represent transactions with no associated loan.',
  path: '/transactions/loan-ids',
  queryKey: ({ query }) => ['transactions', 'loan-ids', query],
})

export type TransactionsCancelScheduledInternalKey = [
  'companies',
  string | undefined,
  'cancel-scheduled-transactions',
  unknown,
]

export type TransactionsCancelScheduledInternalResponse = void

export type TransactionsCancelScheduledInternalVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    scheduledBefore?: string
    paymentType?: 'ach' | 'debitCard' | 'creditCard'
    paymentTypes?: Array<'ach' | 'debitCard' | 'creditCard'>
  }
}

export const transactionsCancelScheduledInternal = makeData<
  TransactionsCancelScheduledInternalResponse,
  TransactionsCancelScheduledInternalKey,
  TransactionsCancelScheduledInternalVariables
>({
  method: 'post',
  name: 'transactionsCancelScheduledInternal',
  summary: 'Cancel scheduled transactions',
  path: '/companies/{companyId}/cancel-scheduled-transactions',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'cancel-scheduled-transactions', query],
})

export type TransactionsGetLoansWithScheduledReversalsKey = [
  'companies',
  string | undefined,
  'loan-types',
  string | undefined,
  'scheduled-reversals',
  unknown,
]

export type TransactionsGetLoansWithScheduledReversalsResponse = {
  data?: Array<string>
}

export type TransactionsGetLoansWithScheduledReversalsVariables = {
  companyId: string | undefined
  loanTypeId: string | undefined
  body?: void
  query?: {
    scheduledBefore?: string
  }
}

export const transactionsGetLoansWithScheduledReversals = makeData<
  TransactionsGetLoansWithScheduledReversalsResponse,
  TransactionsGetLoansWithScheduledReversalsKey,
  TransactionsGetLoansWithScheduledReversalsVariables
>({
  method: 'get',
  name: 'transactionsGetLoansWithScheduledReversals',
  summary: 'Get all scheduled transactions',
  path: '/companies/{companyId}/loan-types/{loanTypeId}/scheduled-reversals',
  queryKey: ({ query, companyId, loanTypeId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'scheduled-reversals',
    query,
  ],
})

export type ProcessScheduledReversalsForLoanKey = [
  'companies',
  string | undefined,
  'loans',
  string | undefined,
  'scheduled-reversals',
  unknown,
]

export type ProcessScheduledReversalsForLoanResponse = void

export type ProcessScheduledReversalsForLoanVariables = {
  companyId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    scheduledBefore?: string
  }
}

export const processScheduledReversalsForLoan = makeData<
  ProcessScheduledReversalsForLoanResponse,
  ProcessScheduledReversalsForLoanKey,
  ProcessScheduledReversalsForLoanVariables
>({
  method: 'post',
  name: 'processScheduledReversalsForLoan',
  summary: 'Process scheduled reversals for loan',
  path: '/companies/{companyId}/loans/{loanId}/scheduled-reversals',
  queryKey: ({ query, companyId, loanId }) => ['companies', companyId, 'loans', loanId, 'scheduled-reversals', query],
})

export type TransactionsGetRawInternalHandlerKey = [
  'companies',
  string | undefined,
  'loan-types',
  string | undefined,
  'transactions',
  unknown,
]

export type TransactionsGetRawInternalHandlerResponse = ResponseTypes.TransactionCollection

export type TransactionsGetRawInternalHandlerVariables = {
  companyId: string | undefined
  loanTypeId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    status?: SchemaTypes.TransactionStatus
    statuses?: Array<SchemaTypes.TransactionStatus>
    scheduledBefore?: string
    paymentType?: 'ach' | 'debitCard' | 'creditCard'
    paymentTypes?: Array<'ach' | 'debitCard' | 'creditCard'>
    autopayExpectedPaymentIds?: Array<string>
    isExternal?: boolean
  }
}

export const transactionsGetRawInternalHandler = makeData<
  TransactionsGetRawInternalHandlerResponse,
  TransactionsGetRawInternalHandlerKey,
  TransactionsGetRawInternalHandlerVariables
>({
  method: 'get',
  name: 'transactionsGetRawInternalHandler',
  summary: 'Get all scheduled transactions',
  path: '/companies/{companyId}/loan-types/{loanTypeId}/transactions',
  queryKey: ({ query, companyId, loanTypeId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'transactions',
    query,
  ],
})

export type GetReconciliationTransactionsByCompanyKey = ['companies', string | undefined, 'transactions', unknown]

export type GetReconciliationTransactionsByCompanyResponse = ResponseTypes.ReconciliationTransactionCollection

export type GetReconciliationTransactionsByCompanyVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    status?: Array<SchemaTypes.TransactionStatusReadOnly>
    toEffectiveDate?: string
    fromEffectiveDate?: string
    investorId?: SchemaTypes.AnyId
    transferSettlementId?: SchemaTypes.AnyId
    transactionType?: 'reconciliation'
  }
}

export const getReconciliationTransactionsByCompany = makeData<
  GetReconciliationTransactionsByCompanyResponse,
  GetReconciliationTransactionsByCompanyKey,
  GetReconciliationTransactionsByCompanyVariables
>({
  method: 'get',
  name: 'getReconciliationTransactionsByCompany',
  summary:
    'Generic endpoint for getting transactions related to a company. Currently used for getting reconciliation transactions.',
  path: '/companies/{companyId}/transactions',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'transactions', query],
})

export type CreateReconciliationKey = ['companies', string | undefined, 'transactions', unknown]

export type CreateReconciliationResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.Transaction
}

export type CreateReconciliationVariables = {
  companyId: string | undefined
  body?: SchemaTypes.ReconciliationTransaction
  query?: {
    investorId: SchemaTypes.AnyId
  }
}

export const createReconciliation = makeData<
  CreateReconciliationResponse,
  CreateReconciliationKey,
  CreateReconciliationVariables
>({
  method: 'post',
  name: 'createReconciliation',
  summary: 'Create transactions related to settlements and investors, such as a reconciliation transaction.',
  path: '/companies/{companyId}/transactions',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'transactions', query],
})

export type CompanyTransactionsGetTransitionalKey = [
  'companies',
  string | undefined,
  'transactions',
  'auto-transition',
  unknown,
]

export type CompanyTransactionsGetTransitionalResponse = ResponseTypes.TransactionCollection

export type CompanyTransactionsGetTransitionalVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    paymentType: 'ach' | 'debitCard' | 'creditCard' | 'check' | 'cash' | 'payroll' | 'paymentNetwork'
  }
}

export const companyTransactionsGetTransitional = makeData<
  CompanyTransactionsGetTransitionalResponse,
  CompanyTransactionsGetTransitionalKey,
  CompanyTransactionsGetTransitionalVariables
>({
  method: 'get',
  name: 'companyTransactionsGetTransitional',
  summary: 'Transactions that may be due for automatic transitioning from pending',
  path: '/companies/{companyId}/transactions/auto-transition',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'transactions', 'auto-transition', query],
})

export type TransactionsGetAllExpiredHoldDaysKey = ['transactions', 'expired-hold-days', unknown]

export type TransactionsGetAllExpiredHoldDaysResponse = ResponseTypes.TransactionCollection

export type TransactionsGetAllExpiredHoldDaysVariables = {
  body?: void
  query?: {
    checkDate: string
    catchUp?: boolean
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const transactionsGetAllExpiredHoldDays = makeData<
  TransactionsGetAllExpiredHoldDaysResponse,
  TransactionsGetAllExpiredHoldDaysKey,
  TransactionsGetAllExpiredHoldDaysVariables
>({
  method: 'get',
  name: 'transactionsGetAllExpiredHoldDays',
  summary: 'Transactions that have an expired hold_days_end_date and are either initiated or pending status.',
  path: '/transactions/expired-hold-days',
  queryKey: ({ query }) => ['transactions', 'expired-hold-days', query],
})

export type GetRoutingNumberKey = ['routing-numbers', string | undefined, unknown]

export type GetRoutingNumberResponse = UtilityTypes.SingletonResponse & {
  data?: {
    /** The financial institution name. */
    bankName?: string
    /**
     * The routing number. If the institution has received a new routing number from The Fed,
     * this may differ from the input routing number. Otherwise, it will match to the input routing number.
     */
    routingNumber?: string
    /**
     * Peach system receives a list of valid routing numbers published by the Federal Reserve Bank once or twice a month.
     * This is the date when Peach received the latest update.
     */
    lastUpdatedDate?: string
  }
}

export type GetRoutingNumberVariables = {
  routingNumber: string | undefined
  body?: void
  query?: void
}

export const getRoutingNumber = makeData<GetRoutingNumberResponse, GetRoutingNumberKey, GetRoutingNumberVariables>({
  method: 'get',
  name: 'getRoutingNumber',
  summary: 'Get routing number details',
  path: '/routing-numbers/{routingNumber}',
  queryKey: ({ query, routingNumber }) => ['routing-numbers', routingNumber, query],
})

export type GetBinNumberKey = ['bin-numbers', unknown]

export type GetBinNumberResponse = UtilityTypes.SingletonResponse & {
  data?: {
    /** The card issuer institution name. */
    issuer?: string
    cardNetwork?:
      | /** The card network. */
      ('visa' | 'mastercard' | 'discover' | 'americanexpress' | null) /** The card network. */
      | null
    /** The card type. */
    cardType?: 'credit' | 'debit'
    /**
     * Peach system receives a BIN list published by First Data/Fiserv a few times per month.
     * This is the date when Peach received the latest update.
     */
    lastUpdatedDate?: string
  }
}

export type GetBinNumberVariables = {
  body?: {
    /** The card number. Must provide at least first 4 digits of the card number. */
    cardNumber?: string
  }
  query?: void
}

export const getBinNumber = makeData<GetBinNumberResponse, GetBinNumberKey, GetBinNumberVariables>({
  method: 'post',
  name: 'getBinNumber',
  summary: 'Get BIN details from card number',
  path: '/bin-numbers',
  queryKey: ({ query }) => ['bin-numbers', query],
})

export type AutopayGetAllInternalCompanyKey = ['companies', string | undefined, 'autopays', unknown]

export type AutopayGetAllInternalCompanyResponse = UtilityTypes.NewPaginator & {
  data?: Array<{}>
}

export type AutopayGetAllInternalCompanyVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
  }
}

export const autopayGetAllInternalCompany = makeData<
  AutopayGetAllInternalCompanyResponse,
  AutopayGetAllInternalCompanyKey,
  AutopayGetAllInternalCompanyVariables
>({
  method: 'get',
  name: 'autopayGetAllInternalCompany',
  summary: 'Get all active autopays',
  path: '/companies/{companyId}/autopays',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'autopays', query],
})

export type AutopayGetAllInternalKey = ['autopays', unknown]

export type AutopayGetAllInternalResponse = UtilityTypes.NewPaginator & {
  data?: Array<{}>
}

export type AutopayGetAllInternalVariables = {
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    companyId?: string
  }
}

export const autopayGetAllInternal = makeData<
  AutopayGetAllInternalResponse,
  AutopayGetAllInternalKey,
  AutopayGetAllInternalVariables
>({
  method: 'get',
  name: 'autopayGetAllInternal',
  summary: 'Get all active autopays',
  path: '/autopays',
  queryKey: ({ query }) => ['autopays', query],
})

export type GetRepaymentEngineInfoKey = [
  'companies',
  string | undefined,
  'loans',
  string | undefined,
  'repayment-engine-info',
  unknown,
]

export type GetRepaymentEngineInfoResponse = {}

export type GetRepaymentEngineInfoVariables = {
  companyId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const getRepaymentEngineInfo = makeData<
  GetRepaymentEngineInfoResponse,
  GetRepaymentEngineInfoKey,
  GetRepaymentEngineInfoVariables
>({
  method: 'get',
  name: 'getRepaymentEngineInfo',
  summary: 'Get all RPE fields for a given loan',
  path: '/companies/{companyId}/loans/{loanId}/repayment-engine-info',
  queryKey: ({ query, companyId, loanId }) => ['companies', companyId, 'loans', loanId, 'repayment-engine-info', query],
})

export type HasherKey = ['internal', 'hasher', unknown]

export type HasherResponse = void

export type HasherVariables = {
  body?: {
    token?: string
    text?: string
  } | null
  query?: void
}

export const hasher = makeData<HasherResponse, HasherKey, HasherVariables>({
  method: 'post',
  name: 'hasher',
  summary: 'The hasher',
  path: '/internal/hasher',
  queryKey: ({ query }) => ['internal', 'hasher', query],
})

export type GetPeriodsKey = ['loan-periods', unknown]

export type GetPeriodsResponse = SchemaTypes.Investor

export type GetPeriodsVariables = {
  body?: void
  query?: {
    companyId?: string
    onlyNeedingStatements?: boolean
    forStatementDate?: string
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const getPeriods = makeData<GetPeriodsResponse, GetPeriodsKey, GetPeriodsVariables>({
  method: 'get',
  name: 'getPeriods',
  summary: 'Get loan periods for all companies',
  path: '/loan-periods',
  queryKey: ({ query }) => ['loan-periods', query],
})

export type CreateStatementKey = ['loan-periods', string | undefined, 'statement', unknown]

export type CreateStatementResponse = ResponseTypes.Statement

export type CreateStatementVariables = {
  periodId: string | undefined
  body?: {
    /**
     * @default
     *     all
     */
    create?: 'all' | 'data' | 'pdf'
    sendNotice?: boolean
    statementLogicVersionOverride?: /** If provided, will override the statement logic version for this statement. Useful for testing. */
    number /** If provided, will override the statement logic version for this statement. Useful for testing. */ | null
  } | null
  query?: void
}

export const createStatement = makeData<CreateStatementResponse, CreateStatementKey, CreateStatementVariables>({
  method: 'post',
  name: 'createStatement',
  summary: 'Create a statement',
  path: '/loan-periods/{periodId}/statement',
  queryKey: ({ query, periodId }) => ['loan-periods', periodId, 'statement', query],
})

export type InternalSaveAutopayKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'statements',
  string | undefined,
  'save-autopay',
  unknown,
]

export type InternalSaveAutopayResponse = ResponseTypes.Statement

export type InternalSaveAutopayVariables = {
  personId: string | undefined
  loanId: string | undefined
  statementId: string | undefined
  body?: void
  query?: void
}

export const internalSaveAutopay = makeData<
  InternalSaveAutopayResponse,
  InternalSaveAutopayKey,
  InternalSaveAutopayVariables
>({
  method: 'post',
  name: 'internalSaveAutopay',
  summary: 'Save statement autopay',
  path: '/people/{personId}/loans/{loanId}/statements/{statementId}/save-autopay',
  queryKey: ({ query, personId, loanId, statementId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'statements',
    statementId,
    'save-autopay',
    query,
  ],
})

export type InternalGenerateStatementKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'periods',
  string | undefined,
  'generate-statement',
  unknown,
]

export type InternalGenerateStatementResponse = SchemaTypes.ProcessingCompleteGeneric &
  UtilityTypes.SingletonResponse & {
    data?: SchemaTypes.Statement
  }

export type InternalGenerateStatementVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  periodId: string | undefined
  body?: void
  query?: {
    sync?: boolean
    sendNotice?: boolean
  }
}

export const internalGenerateStatement = makeData<
  InternalGenerateStatementResponse,
  InternalGenerateStatementKey,
  InternalGenerateStatementVariables
>({
  method: 'post',
  name: 'internalGenerateStatement',
  summary: 'Generate statement',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/periods/{periodId}/generate-statement',
  queryKey: ({ query, companyId, personId, loanId, periodId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'periods',
    periodId,
    'generate-statement',
    query,
  ],
})

export type RevokeOrReinstateGraceKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'revoke-or-reinstate-grace',
  unknown,
]

export type RevokeOrReinstateGraceResponse = UtilityTypes.SingletonResponse & {
  data?: {}
}

export type RevokeOrReinstateGraceVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: {
    duePeriodId?: string
    isReplay?: boolean
  }
  query?: void
}

export const revokeOrReinstateGrace = makeData<
  RevokeOrReinstateGraceResponse,
  RevokeOrReinstateGraceKey,
  RevokeOrReinstateGraceVariables
>({
  method: 'post',
  name: 'revokeOrReinstateGrace',
  summary: 'Revoke or reinstate grace period',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/revoke-or-reinstate-grace',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'revoke-or-reinstate-grace',
    query,
  ],
})

export type InternalComputeBalancesForGraceKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'compute-balances-for-grace',
  unknown,
]

export type InternalComputeBalancesForGraceResponse = void

export type InternalComputeBalancesForGraceVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const internalComputeBalancesForGrace = makeData<
  InternalComputeBalancesForGraceResponse,
  InternalComputeBalancesForGraceKey,
  InternalComputeBalancesForGraceVariables
>({
  method: 'post',
  name: 'internalComputeBalancesForGrace',
  summary: 'Revoke or reinstate grace period',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/compute-balances-for-grace',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'compute-balances-for-grace',
    query,
  ],
})

export type InternalConsentGetKey = ['companies', string | undefined, 'people', string | undefined, 'consents', unknown]

export type InternalConsentGetResponse = ResponseTypes.Consent

export type InternalConsentGetVariables = {
  companyId: string | undefined
  personId: string | undefined
  body?: void
  query?: void
}

export const internalConsentGet = makeData<
  InternalConsentGetResponse,
  InternalConsentGetKey,
  InternalConsentGetVariables
>({
  method: 'get',
  name: 'internalConsentGet',
  summary: 'Get consents',
  path: '/companies/{companyId}/people/{personId}/consents',
  queryKey: ({ query, companyId, personId }) => ['companies', companyId, 'people', personId, 'consents', query],
})

export type InternalConsentUpdateKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'consents',
  unknown,
]

export type InternalConsentUpdateResponse = ResponseTypes.Consent

export type InternalConsentUpdateVariables = {
  companyId: string | undefined
  personId: string | undefined
  body?: RequestBodyTypes.UpdateConsent
  query?: {
    caseId?: SchemaTypes.AnyId
  }
}

export const internalConsentUpdate = makeData<
  InternalConsentUpdateResponse,
  InternalConsentUpdateKey,
  InternalConsentUpdateVariables
>({
  method: 'put',
  name: 'internalConsentUpdate',
  summary: 'Update consents',
  path: '/companies/{companyId}/people/{personId}/consents',
  queryKey: ({ query, companyId, personId }) => ['companies', companyId, 'people', personId, 'consents', query],
})

export type TransactionsCreateVirtualKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'virtual-transactions',
  unknown,
]

export type TransactionsCreateVirtualResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.Transaction
}

export type TransactionsCreateVirtualVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: SchemaTypes.CreateVirtualTransaction
  query?: void
}

export const transactionsCreateVirtual = makeData<
  TransactionsCreateVirtualResponse,
  TransactionsCreateVirtualKey,
  TransactionsCreateVirtualVariables
>({
  method: 'post',
  name: 'transactionsCreateVirtual',
  summary: 'Create virtual transaction',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/virtual-transactions',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'virtual-transactions',
    query,
  ],
})

export type InternalLoanRefundsGetKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'refunds',
  unknown,
]

export type InternalLoanRefundsGetResponse = ResponseTypes.LoanRefundV2Collection

export type InternalLoanRefundsGetVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const internalLoanRefundsGet = makeData<
  InternalLoanRefundsGetResponse,
  InternalLoanRefundsGetKey,
  InternalLoanRefundsGetVariables
>({
  method: 'get',
  name: 'internalLoanRefundsGet',
  summary: 'Get refunds',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/refunds',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'refunds',
    query,
  ],
})

export type InternalLoanRefundsPostKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'refunds',
  unknown,
]

export type InternalLoanRefundsPostResponse = ResponseTypes.LoanRefundPost

export type InternalLoanRefundsPostVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanRefund
  query?: void
}

export const internalLoanRefundsPost = makeData<
  InternalLoanRefundsPostResponse,
  InternalLoanRefundsPostKey,
  InternalLoanRefundsPostVariables
>({
  method: 'post',
  name: 'internalLoanRefundsPost',
  summary: 'Create refund',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/refunds',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'refunds',
    query,
  ],
})

export type InternalLoanRefundsGetOneKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'refunds',
  string | undefined,
  unknown,
]

export type InternalLoanRefundsGetOneResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.LoanRefundV2
}

export type InternalLoanRefundsGetOneVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  loanRefundId: string | undefined
  body?: void
  query?: void
}

export const internalLoanRefundsGetOne = makeData<
  InternalLoanRefundsGetOneResponse,
  InternalLoanRefundsGetOneKey,
  InternalLoanRefundsGetOneVariables
>({
  method: 'get',
  name: 'internalLoanRefundsGetOne',
  summary: 'Get refund by id',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/refunds/{loanRefundId}',
  queryKey: ({ query, companyId, personId, loanId, loanRefundId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'refunds',
    loanRefundId,
    query,
  ],
})

export type InternalInstallmentRefundUpdateKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'refunds',
  string | undefined,
  unknown,
]

export type InternalInstallmentRefundUpdateResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.LoanRefundV2
}

export type InternalInstallmentRefundUpdateVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  loanRefundId: string | undefined
  body?: RequestBodyTypes.RefundUpdateInternal
  query?: void
}

export const internalInstallmentRefundUpdate = makeData<
  InternalInstallmentRefundUpdateResponse,
  InternalInstallmentRefundUpdateKey,
  InternalInstallmentRefundUpdateVariables
>({
  method: 'put',
  name: 'internalInstallmentRefundUpdate',
  summary: 'Update refund',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/refunds/{loanRefundId}',
  queryKey: ({ query, companyId, personId, loanId, loanRefundId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'refunds',
    loanRefundId,
    query,
  ],
})

export type InternalLoanRefundsCancelKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'refunds',
  string | undefined,
  'cancel',
  unknown,
]

export type InternalLoanRefundsCancelResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.LoanRefundV2
}

export type InternalLoanRefundsCancelVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  loanRefundId: string | undefined
  body?: RequestBodyTypes.LoanRefundCancel
  query?: void
}

export const internalLoanRefundsCancel = makeData<
  InternalLoanRefundsCancelResponse,
  InternalLoanRefundsCancelKey,
  InternalLoanRefundsCancelVariables
>({
  method: 'post',
  name: 'internalLoanRefundsCancel',
  summary: 'Cancel refund',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/refunds/{loanRefundId}/cancel',
  queryKey: ({ query, companyId, personId, loanId, loanRefundId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'refunds',
    loanRefundId,
    'cancel',
    query,
  ],
})

export type InternalLoanRefundsV2PostKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'refunds-v2',
  unknown,
]

export type InternalLoanRefundsV2PostResponse = ResponseTypes.LoanRefundV2Post

export type InternalLoanRefundsV2PostVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanRefundV2
  query?: void
}

export const internalLoanRefundsV2Post = makeData<
  InternalLoanRefundsV2PostResponse,
  InternalLoanRefundsV2PostKey,
  InternalLoanRefundsV2PostVariables
>({
  method: 'post',
  name: 'internalLoanRefundsV2Post',
  summary: 'Create refund v2',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/refunds-v2',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'refunds-v2',
    query,
  ],
})

export type InternalLoanActivateKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'activate',
  unknown,
]

export type InternalLoanActivateResponse = ResponseTypes.LoanActivate

export type InternalLoanActivateVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanActivate
  query?: {
    sync?: boolean
  }
}

export const internalLoanActivate = makeData<
  InternalLoanActivateResponse,
  InternalLoanActivateKey,
  InternalLoanActivateVariables
>({
  method: 'post',
  name: 'internalLoanActivate',
  summary: 'Activate loan',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/activate',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'activate',
    query,
  ],
})

export type LoanUpdateMigrationStatusKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'migration-status',
  unknown,
]

export type LoanUpdateMigrationStatusResponse = void

export type LoanUpdateMigrationStatusVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: {
    migrationStatus: 'completed' | 'failed'
    eventId?: string
  }
  query?: void
}

export const loanUpdateMigrationStatus = makeData<
  LoanUpdateMigrationStatusResponse,
  LoanUpdateMigrationStatusKey,
  LoanUpdateMigrationStatusVariables
>({
  method: 'put',
  name: 'loanUpdateMigrationStatus',
  summary: 'Update migration status',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/migration-status',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'migration-status',
    query,
  ],
})

export type ExpandUrlKey = ['expand-url', string | undefined, unknown]

export type ExpandUrlResponse = void

export type ExpandUrlVariables = {
  shortId: string | undefined
  body?: void
  query?: void
}

export const expandUrl = makeData<ExpandUrlResponse, ExpandUrlKey, ExpandUrlVariables>({
  method: 'get',
  name: 'expandUrl',
  summary: 'Short URL expander',
  path: '/expand-url/{shortId}',
  queryKey: ({ query, shortId }) => ['expand-url', shortId, query],
})

export type InternalGetStatementsKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'statements',
  unknown,
]

export type InternalGetStatementsResponse = ResponseTypes.StatementCollection

export type InternalGetStatementsVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    sortBy?: Array<string>
    year?: string
    includeAllVersions?: boolean
    documentDescriptorIds?: Array<SchemaTypes.AnyId>
  }
}

export const internalGetStatements = makeData<
  InternalGetStatementsResponse,
  InternalGetStatementsKey,
  InternalGetStatementsVariables
>({
  method: 'get',
  name: 'internalGetStatements',
  summary: 'Get statements',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/statements',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'statements',
    query,
  ],
})

export type DeleteStatementKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'statements',
  string | undefined,
  unknown,
]

export type DeleteStatementResponse = void

export type DeleteStatementVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  statementId: string | undefined
  body?: void
  query?: void
}

export const deleteStatement = makeData<DeleteStatementResponse, DeleteStatementKey, DeleteStatementVariables>({
  method: 'delete',
  name: 'deleteStatement',
  summary: 'Delete statement',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/statements/{statementId}',
  queryKey: ({ query, companyId, personId, loanId, statementId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'statements',
    statementId,
    query,
  ],
})

export type InternalGetCompanyStatementsWithoutPdfKey = [
  'companies',
  string | undefined,
  'statements',
  'without-pdf',
  unknown,
]

export type InternalGetCompanyStatementsWithoutPdfResponse = ResponseTypes.StatementWithoutPDFCollection

export type InternalGetCompanyStatementsWithoutPdfVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    statementDate?: string
    /**
     * @default
     *     25
     */
    limit?: number
    offset?: number
    startingAfter?: string
    endingBefore?: string
    sortBy?: Array<string>
  }
}

export const internalGetCompanyStatementsWithoutPdf = makeData<
  InternalGetCompanyStatementsWithoutPdfResponse,
  InternalGetCompanyStatementsWithoutPdfKey,
  InternalGetCompanyStatementsWithoutPdfVariables
>({
  method: 'get',
  name: 'internalGetCompanyStatementsWithoutPdf',
  summary: 'Get company statements for a given date without a PDF generated.',
  path: '/companies/{companyId}/statements/without-pdf',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'statements', 'without-pdf', query],
})

export type InternalLoanDrawLocIdGetKey = ['loans', 'draws', string | undefined, 'line-of-credit-id', unknown]

export type InternalLoanDrawLocIdGetResponse = UtilityTypes.SingletonResponse & {
  data?: any
}

export type InternalLoanDrawLocIdGetVariables = {
  drawId: string | undefined
  body?: void
  query?: void
}

export const internalLoanDrawLocIdGet = makeData<
  InternalLoanDrawLocIdGetResponse,
  InternalLoanDrawLocIdGetKey,
  InternalLoanDrawLocIdGetVariables
>({
  method: 'get',
  name: 'internalLoanDrawLocIdGet',
  summary: 'Get Draw Line of Credit ID',
  path: '/loans/draws/{drawId}/line-of-credit-id',
  queryKey: ({ query, drawId }) => ['loans', 'draws', drawId, 'line-of-credit-id', query],
})

export type InternalLoanDrawsGetKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  unknown,
]

export type InternalLoanDrawsGetResponse = ResponseTypes.DrawsPaging

export type InternalLoanDrawsGetVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    statuses?: Array<
      | 'all'
      | 'active'
      | 'pending'
      | 'originated'
      | 'declined'
      | 'frozen'
      | 'accelerated'
      | 'chargedOff'
      | 'paidOff'
      | 'canceled'
    >
    isClosed?: boolean
    isAmortized?: boolean
    /**
     * @default
     *     true
     */
    includeStaticDraw?: boolean
    startedBefore?: string
    idsOnly?: boolean
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const internalLoanDrawsGet = makeData<
  InternalLoanDrawsGetResponse,
  InternalLoanDrawsGetKey,
  InternalLoanDrawsGetVariables
>({
  method: 'get',
  name: 'internalLoanDrawsGet',
  summary: 'Get draws',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/draws',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    query,
  ],
})

export type InternalLoanDrawsPostKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  unknown,
]

export type InternalLoanDrawsPostResponse = ResponseTypes.Draw

export type InternalLoanDrawsPostVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.DrawsPost
  query?: void
}

export const internalLoanDrawsPost = makeData<
  InternalLoanDrawsPostResponse,
  InternalLoanDrawsPostKey,
  InternalLoanDrawsPostVariables
>({
  method: 'post',
  name: 'internalLoanDrawsPost',
  summary: 'Create draw',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/draws',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    query,
  ],
})

export type InternalDrawActivateKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'activate',
  unknown,
]

export type InternalDrawActivateResponse = ResponseTypes.DrawActivate

export type InternalDrawActivateVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: void
  query?: {
    sync?: boolean
  }
}

export const internalDrawActivate = makeData<
  InternalDrawActivateResponse,
  InternalDrawActivateKey,
  InternalDrawActivateVariables
>({
  method: 'post',
  name: 'internalDrawActivate',
  summary: 'Activate draw',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/draws/{drawId}/activate',
  queryKey: ({ query, companyId, personId, loanId, drawId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'activate',
    query,
  ],
})

export type InternalLoanDrawsGetWithoutBorrowerKey = [
  'companies',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  unknown,
]

export type InternalLoanDrawsGetWithoutBorrowerResponse = ResponseTypes.DrawsPaging

export type InternalLoanDrawsGetWithoutBorrowerVariables = {
  companyId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const internalLoanDrawsGetWithoutBorrower = makeData<
  InternalLoanDrawsGetWithoutBorrowerResponse,
  InternalLoanDrawsGetWithoutBorrowerKey,
  InternalLoanDrawsGetWithoutBorrowerVariables
>({
  method: 'get',
  name: 'internalLoanDrawsGetWithoutBorrower',
  summary: 'Get draws',
  path: '/companies/{companyId}/loans/{loanId}/draws',
  queryKey: ({ query, companyId, loanId }) => ['companies', companyId, 'loans', loanId, 'draws', query],
})

export type InternalCloseLocIfInactiveKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'close-inactive-line',
  unknown,
]

export type InternalCloseLocIfInactiveResponse = void

export type InternalCloseLocIfInactiveVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const internalCloseLocIfInactive = makeData<
  InternalCloseLocIfInactiveResponse,
  InternalCloseLocIfInactiveKey,
  InternalCloseLocIfInactiveVariables
>({
  method: 'post',
  name: 'internalCloseLocIfInactive',
  summary: 'Close inactive line',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/close-inactive-line',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'close-inactive-line',
    query,
  ],
})

export type InternalDrawChargeVariableDrawFeeKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'charge-variable-draw-fee',
  unknown,
]

export type InternalDrawChargeVariableDrawFeeResponse = void

export type InternalDrawChargeVariableDrawFeeVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: void
  query?: void
}

export const internalDrawChargeVariableDrawFee = makeData<
  InternalDrawChargeVariableDrawFeeResponse,
  InternalDrawChargeVariableDrawFeeKey,
  InternalDrawChargeVariableDrawFeeVariables
>({
  method: 'post',
  name: 'internalDrawChargeVariableDrawFee',
  summary: 'Charge variable draw fee',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/draws/{drawId}/charge-variable-draw-fee',
  queryKey: ({ query, companyId, personId, loanId, drawId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'charge-variable-draw-fee',
    query,
  ],
})

export type InternalClearMinPaymentsKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'clear-min-payments',
  unknown,
]

export type InternalClearMinPaymentsResponse = void

export type InternalClearMinPaymentsVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const internalClearMinPayments = makeData<
  InternalClearMinPaymentsResponse,
  InternalClearMinPaymentsKey,
  InternalClearMinPaymentsVariables
>({
  method: 'post',
  name: 'internalClearMinPayments',
  summary: 'Refresh draw min payments during a replay',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/clear-min-payments',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'clear-min-payments',
    query,
  ],
})

export type RefreshAmortizedDrawKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'refresh',
  unknown,
]

export type RefreshAmortizedDrawResponse = void

export type RefreshAmortizedDrawVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: {
    purchaseMade?:
      | /** Indicates whether the refresh is due to a purchase being made */
      ('regular' | 'refund' | 'cashBack' | null) /** Indicates whether the refresh is due to a purchase being made */
      | null
  }
  query?: void
}

export const refreshAmortizedDraw = makeData<
  RefreshAmortizedDrawResponse,
  RefreshAmortizedDrawKey,
  RefreshAmortizedDrawVariables
>({
  method: 'post',
  name: 'refreshAmortizedDraw',
  summary: 'Refresh payments after purchase',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/draws/{drawId}/refresh',
  queryKey: ({ query, companyId, personId, loanId, drawId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'refresh',
    query,
  ],
})

export type InternalDrawPurchasesGetKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'purchases',
  unknown,
]

export type InternalDrawPurchasesGetResponse = UtilityTypes.SingletonResponse

export type InternalDrawPurchasesGetVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: void
  query?: {
    types?: Array<'regular' | 'refund' | 'cashback'>
    statuses?: Array<'authorized' | 'pending' | 'settled' | 'canceled' | 'declined' | 'disputed'>
    fromEffectiveAt?: string
    toEffectiveAt?: string
    fromCreatedAt?: string
    toCreatedAt?: string
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const internalDrawPurchasesGet = makeData<
  InternalDrawPurchasesGetResponse,
  InternalDrawPurchasesGetKey,
  InternalDrawPurchasesGetVariables
>({
  method: 'get',
  name: 'internalDrawPurchasesGet',
  summary: 'Get purchases',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/draws/{drawId}/purchases',
  queryKey: ({ query, companyId, personId, loanId, drawId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
    query,
  ],
})

export type InternalDrawPurchasesCreateKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'purchases',
  unknown,
]

export type InternalDrawPurchasesCreateResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.Purchase
}

export type InternalDrawPurchasesCreateVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: SchemaTypes.CreatePurchase & SchemaTypes.PurchaseExtraInput
  query?: {
    force?: boolean
    sync?: boolean
  }
}

export const internalDrawPurchasesCreate = makeData<
  InternalDrawPurchasesCreateResponse,
  InternalDrawPurchasesCreateKey,
  InternalDrawPurchasesCreateVariables
>({
  method: 'post',
  name: 'internalDrawPurchasesCreate',
  summary: 'Create purchase',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/draws/{drawId}/purchases',
  queryKey: ({ query, companyId, personId, loanId, drawId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
    query,
  ],
})

export type InternalDrawPurchaseGetOneKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'purchases',
  string | undefined,
  unknown,
]

export type InternalDrawPurchaseGetOneResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.Purchase
}

export type InternalDrawPurchaseGetOneVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  purchaseId: string | undefined
  body?: void
  query?: void
}

export const internalDrawPurchaseGetOne = makeData<
  InternalDrawPurchaseGetOneResponse,
  InternalDrawPurchaseGetOneKey,
  InternalDrawPurchaseGetOneVariables
>({
  method: 'get',
  name: 'internalDrawPurchaseGetOne',
  summary: 'Get purchase by ID',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/draws/{drawId}/purchases/{purchaseId}',
  queryKey: ({ query, companyId, personId, loanId, drawId, purchaseId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
    purchaseId,
    query,
  ],
})

export type InternalDrawPurchaseUpdateKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'purchases',
  string | undefined,
  unknown,
]

export type InternalDrawPurchaseUpdateResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.Purchase
}

export type InternalDrawPurchaseUpdateVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  purchaseId: string | undefined
  body?: RequestBodyTypes.PurchaseUpdateInternal
  query?: {
    force?: boolean
    sync?: boolean
  }
}

export const internalDrawPurchaseUpdate = makeData<
  InternalDrawPurchaseUpdateResponse,
  InternalDrawPurchaseUpdateKey,
  InternalDrawPurchaseUpdateVariables
>({
  method: 'put',
  name: 'internalDrawPurchaseUpdate',
  summary: 'Update purchase',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/draws/{drawId}/purchases/{purchaseId}',
  queryKey: ({ query, companyId, personId, loanId, drawId, purchaseId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
    purchaseId,
    query,
  ],
})

export type LocPurchaseGetOneKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'purchases',
  string | undefined,
  unknown,
]

export type LocPurchaseGetOneResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.Purchase
}

export type LocPurchaseGetOneVariables = {
  personId: string | undefined
  loanId: string | undefined
  purchaseId: string | undefined
  body?: void
  query?: void
}

export const locPurchaseGetOne = makeData<LocPurchaseGetOneResponse, LocPurchaseGetOneKey, LocPurchaseGetOneVariables>({
  method: 'get',
  name: 'locPurchaseGetOne',
  summary: 'Get purchase by ID without draw ID',
  path: '/people/{personId}/loans/{loanId}/purchases/{purchaseId}',
  queryKey: ({ query, personId, loanId, purchaseId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'purchases',
    purchaseId,
    query,
  ],
})

export type InternalDrawPurchaseChargeFeesKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'purchases',
  string | undefined,
  'charge-fees',
  unknown,
]

export type InternalDrawPurchaseChargeFeesResponse = {}

export type InternalDrawPurchaseChargeFeesVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  purchaseId: string | undefined
  body?: {
    [key: string]: any
  }
  query?: void
}

export const internalDrawPurchaseChargeFees = makeData<
  InternalDrawPurchaseChargeFeesResponse,
  InternalDrawPurchaseChargeFeesKey,
  InternalDrawPurchaseChargeFeesVariables
>({
  method: 'post',
  name: 'internalDrawPurchaseChargeFees',
  summary: 'Charge fees if necessary',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/draws/{drawId}/purchases/{purchaseId}/charge-fees',
  queryKey: ({ query, companyId, personId, loanId, drawId, purchaseId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
    purchaseId,
    'charge-fees',
    query,
  ],
})

export type LoanStateGetKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'state',
  unknown,
]

export type LoanStateGetResponse = void

export type LoanStateGetVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const loanStateGet = makeData<LoanStateGetResponse, LoanStateGetKey, LoanStateGetVariables>({
  method: 'get',
  name: 'loanStateGet',
  summary: 'Get loan state',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/state',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'state',
    query,
  ],
})

export type LoanStatePostKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'state',
  unknown,
]

export type LoanStatePostResponse = void

export type LoanStatePostVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const loanStatePost = makeData<LoanStatePostResponse, LoanStatePostKey, LoanStatePostVariables>({
  method: 'post',
  name: 'loanStatePost',
  summary: 'Update loan state',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/state',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'state',
    query,
  ],
})

export type CompanyRotateTokenizationKeyKey = ['companies', string | undefined, 'rotate-tokenization-key', unknown]

export type CompanyRotateTokenizationKeyResponse = void

export type CompanyRotateTokenizationKeyVariables = {
  companyId: string | undefined
  body?: void
  query?: void
}

export const companyRotateTokenizationKey = makeData<
  CompanyRotateTokenizationKeyResponse,
  CompanyRotateTokenizationKeyKey,
  CompanyRotateTokenizationKeyVariables
>({
  method: 'post',
  name: 'companyRotateTokenizationKey',
  summary: "Rotate a company's encryption keys",
  path: '/companies/{companyId}/rotate-tokenization-key',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'rotate-tokenization-key', query],
})

export type CleanPaymentInstrumentsKey = ['companies', string | undefined, 'payment-instruments', 'clean', unknown]

export type CleanPaymentInstrumentsResponse = void

export type CleanPaymentInstrumentsVariables = {
  companyId: string | undefined
  body?: void
  query?: void
}

export const cleanPaymentInstruments = makeData<
  CleanPaymentInstrumentsResponse,
  CleanPaymentInstrumentsKey,
  CleanPaymentInstrumentsVariables
>({
  method: 'post',
  name: 'cleanPaymentInstruments',
  summary: 'Clean out tokens of card based payment instruments that have not been used in the past 2 years',
  path: '/companies/{companyId}/payment-instruments/clean',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'payment-instruments', 'clean', query],
})

export type TestSpawnTaskKey = ['internal', 'operations', 'test', unknown]

export type TestSpawnTaskResponse = UtilityTypes.SingletonResponse

export type TestSpawnTaskVariables = {
  body?: void
  query?: void
}

export const testSpawnTask = makeData<TestSpawnTaskResponse, TestSpawnTaskKey, TestSpawnTaskVariables>({
  method: 'get',
  name: 'testSpawnTask',
  summary: 'Test operation',
  path: '/internal/operations/test',
  queryKey: ({ query }) => ['internal', 'operations', 'test', query],
})

export type ChargebackReportPostKey = ['sendgrid', 'webhooks', 'chargeback-report', unknown]

export type ChargebackReportPostResponse = void

export type ChargebackReportPostVariables = {
  body?: FormData
  query?: void
}

export const chargebackReportPost = makeData<
  ChargebackReportPostResponse,
  ChargebackReportPostKey,
  ChargebackReportPostVariables
>({
  method: 'post',
  name: 'chargebackReportPost',
  summary: 'Process chargeback reports',
  path: '/sendgrid/webhooks/chargeback-report',
  queryKey: ({ query }) => ['sendgrid', 'webhooks', 'chargeback-report', query],
})

export type InternalOperationStatusGetKey = ['internal', 'operations', string | undefined, unknown]

export type InternalOperationStatusGetResponse = UtilityTypes.SingletonResponse

export type InternalOperationStatusGetVariables = {
  operationId: string | undefined
  body?: void
  query?: void
}

export const internalOperationStatusGet = makeData<
  InternalOperationStatusGetResponse,
  InternalOperationStatusGetKey,
  InternalOperationStatusGetVariables
>({
  method: 'get',
  name: 'internalOperationStatusGet',
  summary: 'Get operation status',
  path: '/internal/operations/{operationId}',
  queryKey: ({ query, operationId }) => ['internal', 'operations', operationId, query],
})

export type GetMissingSnapshotsKey = ['companies', string | undefined, 'snapshots', 'missing', unknown]

export type GetMissingSnapshotsResponse = ResponseTypes.MissingSnapshotsResponse

export type GetMissingSnapshotsVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    fromDate: string
    toDate?: string
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    /**
     * @default
     *     true
     */
    onlyMissing?: boolean
    loanIds?: Array<SchemaTypes.AnyId>
    backfillBeforeMigratedAt?: boolean | null
  }
}

export const getMissingSnapshots = makeData<
  GetMissingSnapshotsResponse,
  GetMissingSnapshotsKey,
  GetMissingSnapshotsVariables
>({
  method: 'get',
  name: 'getMissingSnapshots',
  summary: 'Get missing snapshots',
  path: '/companies/{companyId}/snapshots/missing',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'snapshots', 'missing', query],
})

export type InternalGetPaymentProcessorsKey = ['companies', string | undefined, 'payment-processors', unknown]

export type InternalGetPaymentProcessorsResponse = UtilityTypes.SingletonResponse & {
  data?: Array<SchemaTypes.PaymentProcessor>
}

export type InternalGetPaymentProcessorsVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    sortBy?: Array<string>
  }
}

export const internalGetPaymentProcessors = makeData<
  InternalGetPaymentProcessorsResponse,
  InternalGetPaymentProcessorsKey,
  InternalGetPaymentProcessorsVariables
>({
  method: 'get',
  name: 'internalGetPaymentProcessors',
  summary: 'Get payment processors',
  path: '/companies/{companyId}/payment-processors',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'payment-processors', query],
})

export type InternalCreatePaymentProcessorKey = ['companies', string | undefined, 'payment-processors', unknown]

export type InternalCreatePaymentProcessorResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.PaymentProcessor
}

export type InternalCreatePaymentProcessorVariables = {
  companyId: string | undefined
  body?: SchemaTypes.PaymentProcessor
  query?: void
}

export const internalCreatePaymentProcessor = makeData<
  InternalCreatePaymentProcessorResponse,
  InternalCreatePaymentProcessorKey,
  InternalCreatePaymentProcessorVariables
>({
  method: 'post',
  name: 'internalCreatePaymentProcessor',
  summary: 'Create payment processor',
  path: '/companies/{companyId}/payment-processors',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'payment-processors', query],
})

export type InternalGetOnePaymentProcessorKey = [
  'companies',
  string | undefined,
  'payment-processors',
  string | undefined,
  unknown,
]

export type InternalGetOnePaymentProcessorResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.PaymentProcessor
}

export type InternalGetOnePaymentProcessorVariables = {
  companyId: string | undefined
  paymentProcessorId: string | undefined
  body?: void
  query?: void
}

export const internalGetOnePaymentProcessor = makeData<
  InternalGetOnePaymentProcessorResponse,
  InternalGetOnePaymentProcessorKey,
  InternalGetOnePaymentProcessorVariables
>({
  method: 'get',
  name: 'internalGetOnePaymentProcessor',
  summary: 'Get payment processor',
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}',
  queryKey: ({ query, companyId, paymentProcessorId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    query,
  ],
})

export type InternalUpdatePaymentProcessorKey = [
  'companies',
  string | undefined,
  'payment-processors',
  string | undefined,
  unknown,
]

export type InternalUpdatePaymentProcessorResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.PaymentProcessor
}

export type InternalUpdatePaymentProcessorVariables = {
  companyId: string | undefined
  paymentProcessorId: string | undefined
  body?: SchemaTypes.PaymentProcessorUpdateRequest
  query?: void
}

export const internalUpdatePaymentProcessor = makeData<
  InternalUpdatePaymentProcessorResponse,
  InternalUpdatePaymentProcessorKey,
  InternalUpdatePaymentProcessorVariables
>({
  method: 'put',
  name: 'internalUpdatePaymentProcessor',
  summary: 'Update payment processor',
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}',
  queryKey: ({ query, companyId, paymentProcessorId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    query,
  ],
})

export type InternalDeletePaymentProcessorKey = [
  'companies',
  string | undefined,
  'payment-processors',
  string | undefined,
  unknown,
]

export type InternalDeletePaymentProcessorResponse = void

export type InternalDeletePaymentProcessorVariables = {
  companyId: string | undefined
  paymentProcessorId: string | undefined
  body?: void
  query?: void
}

export const internalDeletePaymentProcessor = makeData<
  InternalDeletePaymentProcessorResponse,
  InternalDeletePaymentProcessorKey,
  InternalDeletePaymentProcessorVariables
>({
  method: 'delete',
  name: 'internalDeletePaymentProcessor',
  summary: 'Delete payment processor',
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}',
  queryKey: ({ query, companyId, paymentProcessorId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    query,
  ],
})

export type InternalGetAchFilesKey = [
  'companies',
  string | undefined,
  'payment-processors',
  string | undefined,
  'ach-files',
  unknown,
]

export type InternalGetAchFilesResponse = UtilityTypes.SingletonResponse & {
  data?: Array<SchemaTypes.AchFile>
}

export type InternalGetAchFilesVariables = {
  companyId: string | undefined
  paymentProcessorId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    offset?: number
    startingAfter?: string
    endingBefore?: string
    sortBy?: Array<string>
    sftpUploadStarted?: boolean
    sftpUploadSucceeded?: boolean
    sftpUploadFailed?: boolean
    gcpUploadStarted?: boolean
    gcpUploadSucceeded?: boolean
    gcpUploadFailed?: boolean
    isSettlement?: boolean
  }
}

export const internalGetAchFiles = makeData<
  InternalGetAchFilesResponse,
  InternalGetAchFilesKey,
  InternalGetAchFilesVariables
>({
  method: 'get',
  name: 'internalGetAchFiles',
  summary: 'Get ACH file monitoring records',
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}/ach-files',
  queryKey: ({ query, companyId, paymentProcessorId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'ach-files',
    query,
  ],
})

export type InternalCreateAchFileKey = [
  'companies',
  string | undefined,
  'payment-processors',
  string | undefined,
  'ach-files',
  unknown,
]

export type InternalCreateAchFileResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.AchFile
}

export type InternalCreateAchFileVariables = {
  companyId: string | undefined
  paymentProcessorId: string | undefined
  body?: SchemaTypes.AchFile
  query?: void
}

export const internalCreateAchFile = makeData<
  InternalCreateAchFileResponse,
  InternalCreateAchFileKey,
  InternalCreateAchFileVariables
>({
  method: 'post',
  name: 'internalCreateAchFile',
  summary: 'Create ACH file monitoring record',
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}/ach-files',
  queryKey: ({ query, companyId, paymentProcessorId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'ach-files',
    query,
  ],
})

export type InternalGetOneAchFileKey = [
  'companies',
  string | undefined,
  'payment-processors',
  string | undefined,
  'ach-files',
  string | undefined,
  unknown,
]

export type InternalGetOneAchFileResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.AchFile
}

export type InternalGetOneAchFileVariables = {
  companyId: string | undefined
  paymentProcessorId: string | undefined
  achFileId: string | undefined
  body?: void
  query?: void
}

export const internalGetOneAchFile = makeData<
  InternalGetOneAchFileResponse,
  InternalGetOneAchFileKey,
  InternalGetOneAchFileVariables
>({
  method: 'get',
  name: 'internalGetOneAchFile',
  summary: 'Get ACH file upload monitoring record',
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}/ach-files/{achFileId}',
  queryKey: ({ query, companyId, paymentProcessorId, achFileId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'ach-files',
    achFileId,
    query,
  ],
})

export type InternalUpdateAchFileKey = [
  'companies',
  string | undefined,
  'payment-processors',
  string | undefined,
  'ach-files',
  string | undefined,
  unknown,
]

export type InternalUpdateAchFileResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.AchFile
}

export type InternalUpdateAchFileVariables = {
  companyId: string | undefined
  paymentProcessorId: string | undefined
  achFileId: string | undefined
  body?: SchemaTypes.AchFileMutableFields
  query?: void
}

export const internalUpdateAchFile = makeData<
  InternalUpdateAchFileResponse,
  InternalUpdateAchFileKey,
  InternalUpdateAchFileVariables
>({
  method: 'put',
  name: 'internalUpdateAchFile',
  summary: 'Update ACH file upload monitoring record',
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}/ach-files/{achFileId}',
  queryKey: ({ query, companyId, paymentProcessorId, achFileId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'ach-files',
    achFileId,
    query,
  ],
})

export type GetLoanDataKey = ['companies', string | undefined, 'loans', string | undefined, 'loan-ingest', unknown]

export type GetLoanDataResponse = UtilityTypes.SingletonResponse

export type GetLoanDataVariables = {
  companyId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    borrowerId?: string
  }
}

export const getLoanData = makeData<GetLoanDataResponse, GetLoanDataKey, GetLoanDataVariables>({
  method: 'get',
  name: 'getLoanData',
  summary: 'Get data needed for loan ingest processor.',
  path: '/companies/{companyId}/loans/{loanId}/loan-ingest',
  queryKey: ({ query, companyId, loanId }) => ['companies', companyId, 'loans', loanId, 'loan-ingest', query],
})

export type GetSsoTokenKey = ['zendesk', 'sso-token', unknown]

export type GetSsoTokenResponse = string

export type GetSsoTokenVariables = {
  body?: void
  query?: void
}

export const getSsoToken = makeData<GetSsoTokenResponse, GetSsoTokenKey, GetSsoTokenVariables>({
  method: 'get',
  name: 'getSsoToken',
  summary: 'Generate Zendesk SSO JWT',
  path: '/zendesk/sso-token',
  queryKey: ({ query }) => ['zendesk', 'sso-token', query],
})

export type SetSsoSharedSecretKey = ['companies', string | undefined, 'zendesk', 'sso-shared-secret', unknown]

export type SetSsoSharedSecretResponse = string

export type SetSsoSharedSecretVariables = {
  companyId: string | undefined
  body?: void
  query?: void
}

export const setSsoSharedSecret = makeData<
  SetSsoSharedSecretResponse,
  SetSsoSharedSecretKey,
  SetSsoSharedSecretVariables
>({
  method: 'post',
  name: 'setSsoSharedSecret',
  summary: 'Set the Zendesk SSO shared secret for this company.',
  path: '/companies/{companyId}/zendesk/sso-shared-secret',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'zendesk', 'sso-shared-secret', query],
})

export type PublishMessageKey = ['publish-message', unknown]

export type PublishMessageResponse = ResponseTypes.Status

export type PublishMessageVariables = {
  body?: SchemaTypes.Message
  query?: void
}

export const publishMessage = makeData<PublishMessageResponse, PublishMessageKey, PublishMessageVariables>({
  method: 'post',
  name: 'publishMessage',
  summary: 'Publish a message to a pubsub queue, for internal use only!',
  path: '/publish-message',
  queryKey: ({ query }) => ['publish-message', query],
})

export type PopulateBusinessDaysKey = ['business-days', unknown]

export type PopulateBusinessDaysResponse = void

export type PopulateBusinessDaysVariables = {
  body?: {
    /**
     * The date to populate business days up to.
     * Defaults to one year from today.
     */
    toDate?: string
    /**
     * The date to start populating business days from.
     * Defaults to today.
     */
    fromDate?: string
    /**
     * @default
     *     US
     */
    countryCode?: string
  }
  query?: void
}

export const populateBusinessDays = makeData<
  PopulateBusinessDaysResponse,
  PopulateBusinessDaysKey,
  PopulateBusinessDaysVariables
>({
  method: 'post',
  name: 'populateBusinessDays',
  summary: 'Populate the business_days table, for business day calculations in redash queries.',
  path: '/business-days',
  queryKey: ({ query }) => ['business-days', query],
})
